import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import React, { useState } from 'react';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import RHFSwitch from 'src/components/hook-form/rhf-switch';
import Iconify from 'src/components/iconify';
import DeleteDialog from 'src/sections/dialogs/delete/DeleteDialog';
import Question from './Question';
import { useAddQuestionsContext } from './context/questions-context';
import { useLocales } from 'src/locales';
import AddQuestionsFromTheBankDialog from 'src/sections/dialogs/add/add-questions-from-the-bank-dialog';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import { handleDebouncedSearch } from 'src/utils/helper';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet-async';

const AddNewQuestions = () => {
  const {
    isLoadingExam,
    isLoadingQuestion,
    isLoadingQuestions,
    methods,
    onSubmit,
    isInExamPage,
    isEditingQuestion,
    mutoonOpts,
    examTitle,
    currentTabValue,
    handleChangeTab,
    langsWithErrors,
    handleDeleteQuestion,
    handleSwitch,
    handleAddNewQuestion,
    confirm,
    mutate,
    dataToSend,
    isAdding,
    deleteQuestion,
    questionDeleteId,
    confirmDeleteQuestion,
    isDeleting,
    questionsBankDialog,
    questionsData,
    LIMIT,
    handleUpdateAndAddQuestions,
    isUpdating,
    search,
    setSearch,
  } = useAddQuestionsContext();

  const { currentLang, t } = useLocales();

  return (
    <Container>
      <Helmet>
        <title>{t('titles.addQuestion')}</title>
      </Helmet>

      <AppLoadingAndErrorWrapper
        isLoading={isLoadingExam || isLoadingQuestions || isLoadingQuestion}
        errorMessage={null}
        sx={{ height: 'auto' }}
      >
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          {/* <AppTableToolbar tableName="hello" reset={methods.reset} setSearch={setSearch} /> */}
          <Grid container spacing={3} justifyContent="center">
            {!isInExamPage && !isEditingQuestion && (
              <Grid item xs={12}>
                <Block label={t('dialogs.addNewHearingCouncil.matn')}>
                  <RHFAutocomplete
                    name="matnName"
                    valueName="matnId"
                    options={mutoonOpts}
                    groupBy={(option) => option.category.name!?.[currentLang.value]}
                  />
                </Block>
              </Grid>
            )}

            {(methods.watch().matnId || isEditingQuestion) && (
              <Grid item xs={12}>
                <TextField
                  defaultValue={search}
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleDebouncedSearch(() => setSearch && setSearch(event.target.value));
                  }}
                  // onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: '#c6ddb4' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}

            {examTitle!?.[currentLang.value] && (
              <Grid item xs={12}>
                <Chip
                  label={`${examTitle!?.[currentLang.value]}`}
                  variant="filled"
                  sx={{ width: '100%', fontSize: 20 }}
                />
              </Grid>
            )}

            {(methods.watch().matnId || isEditingQuestion) && (
              <React.Fragment>
                <Grid item xs={8}>
                  <TranslationTabs
                    currentTabValue={currentTabValue}
                    handleChangeTab={handleChangeTab}
                    errors={langsWithErrors}
                  />
                </Grid>
                <Grid item container spacing={3} justifyContent="center">
                  {methods.watch().questions.map((question, index) => {
                    const isChecked = question.type === 'choice';
                    return (
                      <Grid item xs={8} key={question.questionId}>
                        <Card
                          sx={{
                            p: 3,
                          }}
                          key={question.questionId}
                        >
                          <Grid
                            container
                            key={index}
                            rowSpacing={2}
                            columnSpacing={{
                              xs: 0,
                              md: 1,
                            }}
                          >
                            <Grid item xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Stack direction="row">
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleDeleteQuestion(index, question.questionId!)
                                    }
                                  >
                                    <Iconify width={24} icon="material-symbols:delete-outline" />
                                  </IconButton>
                                </Stack>

                                <Stack direction="row" alignItems="center" spacing={2}>
                                  {!isEditingQuestion && (
                                    <RHFSwitch
                                      name={`questions.${index}.type`}
                                      label={t('dialogs.addNewQuestion.choice')}
                                      checked={isChecked}
                                      onChange={(e) => handleSwitch(e, index)}
                                    />
                                  )}
                                  <Chip
                                    label={(methods.watch().pageValue! - 1) * LIMIT + index + 1}
                                  />
                                </Stack>
                              </Stack>
                            </Grid>

                            <Question index={index} question={question} />
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })}
                  {questionsData?.questions_aggregate.aggregate?.count! > 10 && (
                    <Grid item xs={8} display="flex" justifyContent="center">
                      <Pagination
                        shape="circular"
                        count={Math.ceil(questionsData?.questions_aggregate.aggregate?.count! / 10)}
                        variant="outlined"
                        page={methods.watch().pageValue}
                        onChange={(_, page) => {
                          methods.setValue('pageValue', page);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={8}>
                    <AppButton
                      label={t('dialogs.addNewQuestion.addAnotherQuestion')}
                      size="small"
                      onClick={handleAddNewQuestion}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  {isInExamPage && (
                    <Grid item xs={8}>
                      <AppButton
                        label={t('dialogs.addNewQuestion.addQuestionsFromTheBank')}
                        size="small"
                        onClick={questionsBankDialog.onTrue}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <AppLoadingButton
                    label={isEditingQuestion ? t('buttons.edit') : t('buttons.submit')}
                    isLoading={isAdding || isUpdating}
                    fullWidth
                  />
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditingQuestion
            ? t('buttons.edit').toLowerCase()
            : t('buttons.submit').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding}
              onClick={handleUpdateAndAddQuestions}
            />
          </>
        }
      />
      <DeleteDialog
        mutate={deleteQuestion}
        id={questionDeleteId!}
        idWord="questionId"
        open={confirmDeleteQuestion.value}
        onClose={confirmDeleteQuestion.onFalse}
        isDeleting={isDeleting}
      />
      <AddQuestionsFromTheBankDialog
        open={questionsBankDialog.value}
        onClose={questionsBankDialog.onFalse}
      />
    </Container>
  );
};

export default AddNewQuestions;
