import { IRoleActionsDto } from 'src/types/roles';

// TODO improve this file

interface Permission {
  id: string;
  name: string;
  systemCriteria: string;
}

interface PermissionsGroup {
  systemCriteria: string;
  permissions: Permission[];
}

interface CheckPermission extends Permission {
  isExist: boolean;
}

interface CheckPermissionsGroup {
  systemCriteria: string;
  permissions: CheckPermission[];
}

export const arrAssembly = (permissions: Permission[]): PermissionsGroup[] => {
  return permissions.reduce((arr: PermissionsGroup[], obj: Permission) => {
    const systemCriteria = obj.systemCriteria;
    const existingSystemCriteria = arr.find((item) => item.systemCriteria === systemCriteria);

    if (existingSystemCriteria) {
      existingSystemCriteria.permissions.push({
        id: obj.id,
        name: obj.name,
        systemCriteria: obj.systemCriteria,
      });
    } else {
      arr.push({
        systemCriteria,
        permissions: [{ id: obj.id, name: obj.name, systemCriteria: obj.systemCriteria }],
      });
    }

    return arr;
  }, []);
};

export const arrAssemblyWithCheck = (permissions: CheckPermission[]): CheckPermissionsGroup[] => {
  return permissions.reduce((arr: CheckPermissionsGroup[], obj: CheckPermission) => {
    const systemCriteria = obj.systemCriteria;
    const existingSystemCriteria = arr.find((item) => item.systemCriteria === systemCriteria);

    if (existingSystemCriteria) {
      existingSystemCriteria.permissions.push({
        id: obj.id,
        name: obj.name,
        systemCriteria: obj.systemCriteria,
        isExist: obj.isExist,
      });
    } else {
      arr.push({
        systemCriteria,
        permissions: [
          { id: obj.id, name: obj.name, systemCriteria: obj.systemCriteria, isExist: obj.isExist },
        ],
      });
    }

    return arr;
  }, []);
};

export const arrCompared = (array1: IRoleActionsDto[], array2: IRoleActionsDto[]) => {
  const array2Map = new Map<string, IRoleActionsDto[]>();

  // Populate array2Map
  array2.forEach((item) => {
    const systemCriteria = item.systemCriteria;
    if (!array2Map.has(systemCriteria)) {
      array2Map.set(systemCriteria, []);
    }
    array2Map.get(systemCriteria)?.push(item);
  });

  // Combine arrays based on systemCriteria and add boolean key
  const resultArray = array1.map((item) => {
    const systemCriteria = item.systemCriteria;
    const matchingItems = array2Map.get(systemCriteria) || [];
    const isExist = matchingItems.some((array2Item) => array2Item.id === item.id);
    return { ...item, isExist };
  });

  return resultArray;
};
