export const DATE_FILTER_OPERATORS = (t: any) => [
  {
    value: '_lt',
    label: t('constants.dateOperators.lessThan'),
  },
  {
    value: '_gt',
    label: t('constants.dateOperators.greaterThan'),
  },
];
