import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import translationEn from './langs/en';
import translationAr from './langs/ar';
import englishJson from './langs/en';

// ----------------------------------------------------------------------

const lngFromLocalStorage = localStorageGetItem('i18nextLng');
const lng = localStorageGetItem('i18nextLng', lngFromLocalStorage || 'en');

declare module 'i18next' {
  // Extend CustomTypeOptions
  interface CustomTypeOptions {
    // custom namespace type, if you changed it
    defaultNS: 'ns1';
    // custom resources type
    resources: {
      ns1: typeof englishJson;
    };
    // other
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      ar: { translations: translationAr },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
