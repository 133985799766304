import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs, { BreadcrumbsLinkProps } from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';

interface IAppContainerProps {
  pageTitle: string;
  routeLinks?: BreadcrumbsLinkProps[];
  children: ReactNode;
}

const AppContainer: React.FC<IAppContainerProps> = (props) => {
  const { pageTitle, routeLinks, children } = props;
  const settings = useSettingsContext();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs links={routeLinks ?? []} />

        <Box sx={{ width: 1, p: 3, bgcolor: 'background.paper', borderRadius: 1 }}>{children}</Box>
      </Container>
    </>
  );
};

export default AppContainer;
