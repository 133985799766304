const AppPermissions = {
  // Statistics
  VIEW_DASHBOARD: 'Dashboard.View',
  CREATE_DASHBOARD: 'Dashboard.Create',
  EDIT_DASHBOARD: 'Dashboard.Edit',
  DELETE_DASHBOARD: 'Dashboard.Delete',

  // Admins
  VIEW_ADMINS: 'Admins.View',
  CREATE_ADMINS: 'Admins.Create',
  EDIT_ADMINS: 'Admins.Edit',
  DELETE_ADMINS: 'Admins.Delete',

  // Roles Management
  VIEW_ROLE_MANAGEMENT: 'Roles.View',
  CREATE_ROLE_MANAGEMENT: 'Roles.Create',
  EDIT_ROLE_MANAGEMENT: 'Roles.Edit',
  DELETE_ROLE_MANAGEMENT: 'Roles.Delete',

  // Content
  CONTENT_CREATE: 'Content.Create',
  CONTENT_VIEW: 'Content.View',
  CONTENT_EDIT: 'Content.Edit',
  CONTENT_DELETE: 'Content.Delete',

  // User
  USER_CREATE: 'User.Create',
  USER_VIEW: 'User.View',
  USER_EDIT: 'User.Edit',
  USER_DELETE: 'User.Delete',

  // Exams
  EXAMS_CREATE: 'Exams.Create',
  EXAMS_VIEW: 'Exams.View',
  EXAMS_EDIT: 'Exams.Edit',
  EXAMS_DELETE: 'Exams.Delete',

  // Questions
  VIEW_QUESTIONS: 'Exams.View',
  CREATE_QUESTIONS: 'Exams.Create',
  EDIT_QUESTIONS: 'Exams.Edit',
  DELETE_QUESTIONS: 'Exams.Delete',

  // Reference books
  BOOKS_CREATE: 'ReferenceBooks.Create',
  BOOKS_VIEW: 'ReferenceBooks.View',
  BOOKS_EDIT: 'ReferenceBooks.Edit',
  BOOKS_DELETE: 'ReferenceBooks.Delete',

  // Sessions
  SESSIONS_CREATE: 'Sessions.Create',
  SESSIONS_VIEW: 'Sessions.View',
  SESSIONS_EDIT: 'Sessions.Edit',
  SESSIONS_DELETE: 'Sessions.Delete',

  // Hearing Councils
  HEARING_COUNCILS_CREATE: 'HearingCouncils.Create',
  HEARING_COUNCILS_VIEW: 'HearingCouncils.View',
  HEARING_COUNCILS_EDIT: 'HearingCouncils.Edit',
  HEARING_COUNCILS_DELETE: 'HearingCouncils.Delete',

  // User Record
  USER_RECORD_CREATE: 'UserRecord.Create',
  USER_RECORD_VIEW: 'UserRecord.View',
  USER_RECORD_EDIT: 'UserRecord.Edit',
  USER_RECORD_DELETE: 'UserRecord.Delete',

  // Knowledge bases
  KNOWLEDGE_BASES_CREATE: 'KnowledgeBases.Create',
  KNOWLEDGE_BASES_VIEW: 'KnowledgeBases.View',
  KNOWLEDGE_BASES_EDIT: 'KnowledgeBases.Edit',
  KNOWLEDGE_BASES_DELETE: 'KnowledgeBases.Delete',

  // Certificates
  CERTIFICATES_CREATE: 'Certificates.Create',
  CERTIFICATES_VIEW: 'Certificates.View',
  CERTIFICATES_EDIT: 'Certificates.Edit',
  CERTIFICATES_DELETE: 'Certificates.Delete',

  // Contact us
  CONTACT_US_CREATE: 'ContactUs.Create',
  CONTACT_US_VIEW: 'ContactUs.View',
  CONTACT_US_EDIT: 'ContactUs.Edit',
  CONTACT_US_DELETE: 'ContactUs.Delete',

  // Badges
  BADGES_CREATE: 'Badges.Create',
  BADGES_VIEW: 'Badges.View',
  BADGES_EDIT: 'Badges.Edit',
  BADGES_DELETE: 'Badges.Delete',

  // Schools
  SCHOOLS_CREATE: 'Schools.Create',
  SCHOOLS_VIEW: 'Schools.View',
  SCHOOLS_EDIT: 'Schools.Edit',
  SCHOOLS_DELETE: 'Schools.Delete',
};

export default AppPermissions;
