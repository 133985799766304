import { useTable } from 'src/components/table';
import {
  Order_By,
  useAuthorizedUsersQuery,
  useSchoolUsersQuery,
  useUserRecordsQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

const useSchoolsRequest = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const addDialog = useBoolean();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
  }>({
    sortDirection: Order_By.Desc,
  });

  // #region Services
  const { data, isLoading } = useSchoolUsersQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _ilike: `%${query.search}%` } : {},
    order_by: orderBy(methods.watch().sortBy || 'createdAt', methods.watch().sortDirection),
  });
  // #endregion Services

  return {
    t,
    methods,
    query,
    setQuery,
    isLoading,
    table,
    data,
    addDialog,
    handleSearch,
  };
};

export default useSchoolsRequest;
