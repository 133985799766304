// @mui
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation, useParams } from 'react-router-dom';
// components
import moment from 'moment';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { usePathname } from 'src/routes/hooks';
import AddNewCategoryDialog from 'src/sections/dialogs/add/AddNewCategoryDialog';
import { MutoonCategoryDto } from 'src/types/categories';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import AppPermissions from 'src/constants/app-permissions';

// ----------------------------------------------------------------------

type Props = {
  row: MutoonCategoryDto;
  deleteHandler: (categoryId: string) => void;
};

export default function CategoriesRow({ row, deleteHandler }: Props) {
  const { t, currentLang } = useLocales();
  const { state } = useLocation();
  const { '*': splat } = useParams();
  const addDialog = useBoolean();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';
  const currentUrl = usePathname();

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Iconify icon="ion:folder-outline" />
            <Link
              to={`${currentUrl}/${row.categoryId}/`}
              state={[state!, row].flat().filter((state) => state)}
              style={{
                color: isLight ? 'black' : 'white',
              }}
            >
              {row.name[currentLang.value]}
            </Link>
          </Stack>
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {t('tables.categories.type')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          {moment(row.updatedAt).format('YYYY-MM-DD')}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.CONTENT_EDIT]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton onClick={addDialog.onTrue}>
                <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.CONTENT_DELETE]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton
                LinkComponent={RouterLink}
                onClick={() => deleteHandler(row.categoryId)}
                // href={`${paths.dashboard.gifts.customize.replace(':id', row.programId)}`}
              >
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
      <AddNewCategoryDialog open={addDialog.value} onClose={addDialog.onFalse} category={row} />
    </>
  );
}
