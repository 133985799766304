import { Card, Container, Grid, Table, TableBody, TableContainer } from '@mui/material';
import { useState } from 'react';
import AppContainer from 'src/components/common/AppContainer';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import { useRolesQuery } from 'src/graphql';
import { paths } from 'src/routes/paths';
import RolesTableRow from 'src/sections/roles/RolesTableRow';
import AppPermissions from 'src/constants/app-permissions';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import { ROLES_TABLE_HEAD } from 'src/constants/tables-heads';
import { useLocales } from 'src/locales';
import UseSearch from 'src/hooks/use-search';
import { orderBy } from 'src/utils/order-by';
import { Helmet } from 'react-helmet-async';

export default function RolesPage() {
  const table = useTable();
  const { t } = useLocales();
  const { query, handleSearch, setQuery, methods } = UseSearch();

  const { data, isLoading } = useRolesQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    ...(query.search ? { name: { _ilike: `%${query.search}%` } } : {}),
  });

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.roles')}</title>
      </Helmet>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AppTableToolbar
              tableName=""
              buttons={[
                {
                  path: paths.dashboard.roles.addRole,
                  permissionName: AppPermissions['CREATE_ROLE_MANAGEMENT'],
                },
              ]}
              // Search
              handleSearch={handleSearch}
              search={query.search!}
            />
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Scrollbar>
                <Table>
                  <TableHeadCustom
                    setQuery={setQuery}
                    methods={methods}
                    headLabel={ROLES_TABLE_HEAD(t)}
                  />
                  <TableBody>
                    {isLoading
                      ? [...Array(table.rowsPerPage)].map((i, index) => (
                          <TableSkeleton key={index} sx={{ height: 80 }} />
                        ))
                      : data?.roles?.map((row) => <RolesTableRow key={row.id} row={row} />)}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            {data && (
              <TablePaginationCustom
                count={data?.roles_aggregate?.aggregate?.count ?? 0}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}
