import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, MenuItem, Stack } from '@mui/material';
import React from 'react';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import useAddNewUser from './use-add-new-user';

const AddNewUser = () => {
  const {
    confirm,
    handleSubmit,
    isAdding,
    isEditing,
    isLoadingAdmin,
    isUpdating,
    methods,
    mutate,
    onSubmit,
    roles,
    t,
  } = useAddNewUser();

  return (
    <Container>
      <AppLoadingAndErrorWrapper
        isLoading={isLoadingAdmin}
        errorMessage={null}
        sx={{ height: 'auto' }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <Block label={t('dialogs.addNewAdmin.userName')}>
              <RHFTextField name="username" />
            </Block>
            <Block label={t('dialogs.addNewAdmin.email')}>
              <RHFTextField name="email" type="email" />
            </Block>
            <Block label={t('dialogs.addNewAdmin.role')}>
              <RHFSelect name="roleId">
                {roles?.roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            {!isEditing && (
              <>
                <Block label={t('dialogs.addNewAdmin.password')}>
                  <RHFTextField name="password" type="password" />
                </Block>
                <Block label={t('dialogs.addNewAdmin.confirmPassword')}>
                  <RHFTextField name="confirmPassword" type="password" />
                </Block>
              </>
            )}
            <AppLoadingButton
              label={isEditing ? t('buttons.edit') : t('buttons.add')}
              isLoading={false}
              fullWidth
            />
          </Stack>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </Container>
  );
};

export default AddNewUser;
