import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  useAllAdminsQuery,
  useAllCertificatesQuery,
  useCreateHearingCouncilMutation,
  useMutoonCategoriesQuery,
  useOneHearingCouncilQuery,
  useUpdateHearingCouncilFormMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import {
  AddHearingCouncilMutationVariablesType,
  HearingCouncilDto,
} from 'src/types/hearing-council';

const useAddNewHearingCouncil = () => {
  // #region States
  const confirm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const { hearingCouncilSchema } = useSchemas();
  const router = useNavigate();
  const { hearingCouncilId } = useParams();
  const { t, allLangs, currentLang } = useLocales();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { state }: { state: HearingCouncilDto | undefined } = useLocation();
  const isEditing = !!state;
  const [dataToSend, setDataToSend] = useState<AddHearingCouncilMutationVariablesType>();
  // #endregion States

  let defaultValues: AddHearingCouncilMutationVariablesType = {
    sessionName: state ? state?.sessionName : {},
    sheikhName: state ? state?.sheikhName! : '',
    description: state ? state.description : {},
    startDate: state ? state?.startDate : new Date(),
    expiryDate: state ? state?.expiryDate : moment().add(2, 'hours').toDate(),
    price: state ? state?.price : 50,
    appointmentLimit: state ? state?.appointmentLimit : 10,
    mutoonMatnId: state ? state?.mutoon!?.id! : '',
    mutoonMatnName: state ? state?.mutoon?.name!?.[currentLang.value] : '',
    prerequisiteIds: state?.prerequisites
      ? state?.prerequisites.map((prerequisite) => prerequisite.prerequisiteId)
      : [],
    enable_conversation: state?.enable_conversation || true,
    enable_raise_hand: state?.enable_raise_hand || true,
    enable_recording: state?.enable_recording || true,
    enable_upload_files: state?.enable_upload_files || true,
    enable_video: state?.enable_video || true,
    max_user_count: state?.max_user_count || 10,
    days: state?.days || ['Sun'],
    duration: state?.duration || 1,
    autoCertificate: state?.autoCertificate || false,
    certificateHearingCouncilId: state?.certificateHearingCouncilId || '',
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state; i++) {
    defaultValues.description[allLangs[i].value] = '';
    defaultValues.sessionName[allLangs[i].value] = '';
  }

  const methods = useForm<AddHearingCouncilMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(hearingCouncilSchema),
  });

  console.log(methods.formState.errors);

  const isTheOwnerTheCreator =
    JSON.parse(localStorage.getItem('user')!)!?.id === methods.watch().sheikhName;

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddHearingCouncilMutationVariablesType) => {
      const newData = { ...data };
      newData.startDate = moment(newData.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      newData.expiryDate = moment(newData.expiryDate).format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
      if (isTheOwnerTheCreator) delete (newData as any).sheikhName;
      delete (newData as any).mutoonMatnName;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  // Create hearing council
  const { mutate: createHearingCouncil, isLoading: isAdding } = useCreateHearingCouncilMutation({
    onSuccess: () => {
      confirm.onFalse();
      enqueueSnackbar({
        variant: 'success',
        message: 'Hearing council has been created successfully',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      router(paths.dashboard.contentManagement.hearingCouncils);
    },
  });

  // Update hearing council
  const { mutate: update, isLoading: isUpdating } = useUpdateHearingCouncilFormMutation({
    onSuccess: () => router(paths.dashboard.contentManagement.hearingCouncils),
  });

  const mutate = isEditing
    ? () =>
        update({
          hearingCouncilId,
          appointmentLimit: dataToSend?.appointmentLimit,
          description: dataToSend?.description,
          expiryDate: dataToSend?.expiryDate,
          mutoonMatnId: dataToSend?.mutoonMatnId,
          sessionName: dataToSend?.sessionName,
          sheikhName: dataToSend?.sheikhName,
          startDate: dataToSend?.startDate,
        })
    : () => createHearingCouncil(dataToSend!);

  // Get mutoon
  const { data: mutoonOptions, isSuccess: hasGotMtns } = useMutoonCategoriesQuery({
    categoryCond: {},
  });

  // Get current hearing council data
  const {} = useOneHearingCouncilQuery(
    {
      hearingCouncilId: hearingCouncilId!,
    },
    {
      enabled: !!hearingCouncilId,
      onSuccess: (data) => {
        methods.reset({ ...data.hearing_councils_by_pk! } as any);
        methods.setValue('mutoonMatnId', data.hearing_councils_by_pk?.mutoon?.id!);
        methods.setValue(
          'mutoonMatnName',
          data.hearing_councils_by_pk?.mutoon?.name!?.[currentLang.value]!
        );
        !data.hearing_councils_by_pk?.sheikhName &&
          methods.setValue('sheikhName', JSON.parse(localStorage.getItem('user')!)!?.id);
      },
    }
  );

  // Get admins
  const { data: admins } = useAllAdminsQuery();

  // Get certificates
  const { data: certificates, isLoading: isLoadingCertificates } = useAllCertificatesQuery();
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `sessionName.${currentTabValue}`,
      methods.watch().sessionName[currentTabValue] || ''
    );
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffect

  return {
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    t,
    currentLang,
    hasGotMtns,
    mutoonOptions,
    isEditing,
    isAdding,
    dataToSend,
    confirm,
    mutate,
    admins,
    certificates,
    isLoadingCertificates,
    isUpdating,
  };
};

export default useAddNewHearingCouncil;
