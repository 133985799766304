import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useJoinSessionMutation, useSubscribeSessionMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { SessionDtoType } from 'src/types/sessions';
import { sessionButtonLabel } from 'src/utils/sessions/session-button-label';

const useSessionCard = ({ session }: { session: SessionDtoType }) => {
  // #region States and hooks
  const router = useNavigate();
  const deleteDialog = useBoolean();
  const queryClient = useQueryClient();
  const { currentLang, t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const chipColor: 'success' | 'error' | 'warning' =
    session.status?.toLowerCase() === 'live'
      ? 'success'
      : session.status?.toLowerCase() === 'expired'
      ? 'error'
      : 'warning';

  const adminId = JSON.parse(localStorage.getItem('user')!)!?.id!;
  const isSubscribedToSession = !!session.user_sessions.filter(
    (session) => session.admin?.id === adminId
  ).length;

  const buttonLabel = t(sessionButtonLabel(session.status! as 'Pending') as any);

  const hasSessionStarted = moment(session.startDate).isBefore(moment());

  const isSessionExpired = moment(session.expiryDate).isAfter(moment());
  // #endregion States and hooks

  // #region Services
  const { mutate: join, isLoading: isJoining } = useJoinSessionMutation({
    onSuccess: (data) => router(session.id, { state: data.joinSession }),
  });

  const { mutate: subscribe, isLoading: isSubscribing } = useSubscribeSessionMutation({
    onSuccess: () => {
      enqueueSnackbar({
        variant: 'success',
        message: 'Subscribed successfully',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({ queryKey: ['Sessions'] });
    },
  });
  // #endregion Services

  return {
    currentLang,
    deleteDialog,
    buttonLabel,
    isSessionExpired,
    isSubscribedToSession,
    isSubscribing,
    join,
    hasSessionStarted,
    isJoining,
    subscribe,
    t,
    chipColor,
  };
};

export default useSessionCard;
