import { Container } from '@mui/material';
import { useParams } from 'react-router';
import { useHearingCouncilAnalysisQuery } from 'src/graphql';
import HearingCouncilsCharts from '../charts/hearing-councils-charts';
import FinishedHearingCouncilsUsers from '../table/finished-hearing-councils-users/finished-hearing-councils-users';
import TableTabs from 'src/components/table/table-tabs';
import { useLocales } from 'src/locales';
import { useState } from 'react';
import FinishedHearingCouncilsSchoolsUsers from '../table/finished-hearing-councils-schools-users/finished-hearing-councils-schools-users';

const SCHOOLS = 'schools';
const USERS = 'users';

const HearingCouncilsAnalysis = () => {
  const { hearingCouncilId } = useParams();
  const [tabValue, setTabValue] = useState<typeof SCHOOLS | typeof USERS>(USERS);
  const { t } = useLocales();
  const { data, isLoading } = useHearingCouncilAnalysisQuery({
    hearingCouncilId: hearingCouncilId!,
  });

  return (
    <Container>
      <HearingCouncilsCharts data={data!} isLoading={isLoading} />
      <TableTabs
        tabs={{
          currentTabValue: USERS,
          tabs: [
            { label: t('tables.finishedHearingCouncilsAnalysis.tabs.users'), value: USERS },
            { label: t('tables.finishedHearingCouncilsAnalysis.tabs.schools'), value: SCHOOLS },
          ],
        }}
        handleChangeTab={(_, value) => setTabValue(value! as typeof SCHOOLS)}
      />
      {tabValue === 'users' && <FinishedHearingCouncilsUsers />}
      {tabValue === 'schools' && <FinishedHearingCouncilsSchoolsUsers />}
    </Container>
  );
};

export default HearingCouncilsAnalysis;
