import { useQueryClient } from '@tanstack/react-query';
import { useTable } from 'src/components/table';
import { Order_By, useAdminsQuery, useDeleteAdminMutation } from 'src/graphql';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

type variablesType = {
  sortBy: string;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

const useDashboardUsers = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const { methods, query, handleSearch, setQuery } = UseSearch<variablesType>();

  // #region Services
  const { data, isLoading } = useAdminsQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _ilike: `%${query.search}%` } : undefined,
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
  });

  const { mutate, isLoading: isDeleting } = useDeleteAdminMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Admins'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteAdmin = (id: string) => {
    mutate({ id });
  };
  // #endregion Handlers

  return {
    t,
    methods,
    handleSearch,
    query,
    setQuery,
    isLoading,
    isDeleting,
    handleDeleteAdmin,
    data,
    table,
  };
};

export default useDashboardUsers;
