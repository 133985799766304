import { Order_By } from 'src/graphql';

export const SORT_DIRECTION = (t: any) => [
  {
    label: t('tables.sort.ascending'),
    value: Order_By.Asc,
  },
  {
    label: t('tables.sort.descending'),
    value: Order_By.Desc,
  },
];
