import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import { handleDebouncedSearch } from 'src/utils/helper';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  debounce?: boolean;
};

export default function RHFTextField({ name, helperText, debounce, type, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          onChange={(event) => {
            if (!debounce) {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
                other.onChange && other.onChange(event);
              } else {
                field.onChange(event.target.value);
                other.onChange && other.onChange(event);
              }
            } else {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
                handleDebouncedSearch(() => other.onChange && other.onChange(event));
              } else {
                console.log('debounced');
                field.onChange(event.target.value);
                handleDebouncedSearch(() => other.onChange && other.onChange(event));
              }
            }
          }}
          label={field.value ? '' : other.label}
        />
      )}
    />
  );
}
