// @mui
import { Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { UseFormReturn } from 'react-hook-form';
import { SetQuery } from 'use-query-params';
import { TableProps } from './types';
import { Order_By } from 'src/graphql';
// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

export type TableHeadPropsType<TRow> = {
  headLabel: {
    label: any;
    id: any;
    orderName?: string;
    align?: string | undefined;
    width?: string;
    minWidth?: string;
  }[];
  rowCount?: number;
  numSelected?: number;
  onSelectAllRows?: (checked: boolean, table: TableProps, rows: TRow[]) => void;
  data?: TRow[];
  table?: TableProps;
  sx?: SxProps<Theme>;
  methods?: UseFormReturn<any, any, undefined>;
  setQuery?: SetQuery<any>;
};

export default function TableHeadCustom<T>({
  headLabel,
  sx,
  methods,
  onSelectAllRows,
  numSelected,
  rowCount,
  setQuery,
  table,
  data,
}: TableHeadPropsType<T>) {
  const orderBy = methods?.watch().sortBy;
  const order = methods?.watch().sortDirection;

  return (
    <TableHead
      sx={{
        '& .MuiTableCell-head': {
          color: 'black',
          backgroundColor: '#EEEEEE',
        },
        ...sx,
      }}
    >
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={!!numSelected && numSelected < rowCount!}
              checked={!!rowCount && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked, table!, data!)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => {
          const showOrderOption = headCell.orderName;
          return (
            <TableCell
              key={headCell.id}
              align={(headCell.align as 'left') || 'center'}
              sortDirection={order}
              sx={{ width: headCell.width, minWidth: headCell.minWidth }}
            >
              {methods && showOrderOption ? (
                <TableSortLabel
                  active={orderBy === headCell.orderName}
                  direction={orderBy === headCell.orderName ? order : 'asc'}
                  onClick={() => {
                    methods.setValue('sortBy', headCell.orderName);
                    methods.setValue(
                      'sortDirection',
                      order === Order_By.Asc ? Order_By.Desc : Order_By.Asc
                    );
                    setQuery &&
                      setQuery({
                        sortBy: headCell.orderName,
                        sortDirection: order === Order_By.Asc ? Order_By.Desc : Order_By.Asc,
                      });
                  }}
                >
                  {headCell.label}

                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
