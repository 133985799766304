import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAddFaqMutation, useEditFaqMutation, useOneFaqQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddFAQVariablesType, FaqsDto } from 'src/types/faqs';

const useAddNewFAQ = () => {
  // #region States
  const confirm = useBoolean();
  const { AddFaqSchema } = useSchemas();
  const { faqId } = useParams();
  const queryClient = useQueryClient();
  const { allLangs } = useLocales();
  const router = useNavigate();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const [file, setFile] = useState<File | string | null>(null);
  const { state }: { state: { faq: FaqsDto | undefined } | undefined } = useLocation();
  const isEditing = !!faqId;
  const [dataToSend, setDataToSend] = useState<AddFAQVariablesType>();
  // #endregion States

  let defaultValues: AddFAQVariablesType = {
    answer: state?.faq?.answer || {},
    question: state?.faq?.question || {},
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state?.faq; i++) {
    defaultValues.answer[allLangs[i].value] = '';
    defaultValues.question[allLangs[i].value] = '';
  }

  const methods = useForm<AddFAQVariablesType>({
    defaultValues,
    resolver: yupResolver(AddFaqSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddFAQVariablesType) => {
      const newData = { ...data };
      if (!isEditing) delete (newData as any).coverUrl;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  const { isFetching: isLoadingFAQ } = useOneFaqQuery(
    {
      id: faqId!,
    },
    {
      enabled: !state?.faq && !!faqId,
      onSuccess: (data) => {
        {
          methods.setValue('question', data.faqs_by_pk!?.question);
          methods.setValue('answer', data.faqs_by_pk?.answer);
        }
      },
    }
  );

  const {
    mutate: addFaq,
    isLoading: isAdding,
  } = useAddFaqMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      router(paths.dashboard.helpCenter.faqs);
    },
  });

  const { mutate: editFaq, isLoading: isUpdating } = useEditFaqMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['FAQs'] });
      router(paths.dashboard.helpCenter.faqs);
    },
  });

  const mutate = isEditing
    ? () =>
        editFaq({
          ...dataToSend,
          id: faqId,
        })
    : () =>
        addFaq({
          ...dataToSend,
        });
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`answer.${currentTabValue}`, methods.watch().answer!?.[currentTabValue] || '');
    methods.setValue(
      `question.${currentTabValue}`,
      methods.watch().question!?.[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffect

  return {
    isLoadingFAQ,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    file,
    setFile,
    mutate,
    faqId,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    handleChangeTab,
    langsWithErrors,
  };
};

export default useAddNewFAQ;
