import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router';
import { useTable } from 'src/components/table';
import {
  Order_By,
  useDeleteCategoryMutation,
  useDeleteMatnMutation,
  useMutoonCategoriesQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { MutoonCategoryDto } from 'src/types/categories';
import { findParentId } from 'src/utils/mutoon-categories/find-last-id';
import { orderBy } from 'src/utils/order-by';
import { useQueryParam } from 'use-query-params';

const useCategoriesMutoon = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const addCtgDialog = useBoolean();
  const openFilters = useBoolean();
  const addMtnDialog = useBoolean();
  const { state }: { state: MutoonCategoryDto[] } = useLocation();
  const queryClient = useQueryClient();
  const { '*': splat } = useParams();
  const { t, currentLang } = useLocales();

  const [onlyMutoons] = useQueryParam('onlyMutoons');
  console.log(onlyMutoons);

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc;
    onlyMutoons: boolean;
  }>({
    onlyMutoons: onlyMutoons === 'true' || false,
  });

  // #region Services
  const { data, isLoading } = useMutoonCategoriesQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _cast: { String: { _ilike: `%${query.search}%` } } } : undefined,
    categoryCond: query.search ? {} : findParentId(splat),
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    exclude_ctgs: { _is_null: methods.watch().onlyMutoons },
  });

  const { mutate: deleteCategory, isLoading: isDeletingCtg } = useDeleteCategoryMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] }),
  });

  const { mutate: deleteMatn, isLoading: isDeletingMatn } = useDeleteMatnMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteCategory = (categoryId: string) => {
    deleteCategory({ categoryId });
  };

  const handleDeleteMatn = (mutoonId: string) => {
    deleteMatn({ mutoonId });
  };
  // #endregion Handlers
  return {
    methods,
    openFilters,
    setQuery,
    handleSearch,
    query,
    addCtgDialog,
    addMtnDialog,
    isLoading,
    isDeletingCtg,
    isDeletingMatn,
    data,
    handleDeleteCategory,
    handleDeleteMatn,
    table,
    t
  };
};

export default useCategoriesMutoon;
