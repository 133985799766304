import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { LoadingScreen } from '../loading-screen';
import { SxProps } from '@mui/material';

interface IAppLoadingAndErrorWrapper {
  isLoading: boolean;
  errorMessage?: AxiosError | null;
  children: ReactNode;
  sx?: SxProps;
}

const AppLoadingAndErrorWrapper: React.FC<IAppLoadingAndErrorWrapper> = (props) => {
  const { isLoading, errorMessage, children, sx } = props;

  if (isLoading) {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: { xs: 'inherit', md: '75vh' }, ...sx }}
      >
        <LoadingScreen />
      </Grid>
    );
  }

  if (errorMessage) {
    return (
      <Grid container alignItems="center" justifyContent="center" sx={{ height: { md: '75vh' } }}>
        <Typography color="error" variant="h4">
          {errorMessage.message}
        </Typography>
      </Grid>
    );
  }

  return <>{children}</>;
};

export default AppLoadingAndErrorWrapper;
