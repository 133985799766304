import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { CATEGORIES_AND_MUTOON_TABLE_HEAD } from 'src/constants/tables-heads';
import AddNewCategoryDialog from 'src/sections/dialogs/add/AddNewCategoryDialog';
import AddNewMatnDialog from 'src/sections/dialogs/add/AddNewMatnDialog';
import CategoriesMutoonRow from './categories-mutoon-row';
import CategoriesRow from './categories-row';
import useCategoriesMutoon from './use-categories-mutoon';

const MutoonsCategories = () => {
  const {
    addCtgDialog,
    addMtnDialog,
    data,
    handleDeleteCategory,
    handleDeleteMatn,
    handleSearch,
    isDeletingCtg,
    isDeletingMatn,
    isLoading,
    methods,
    openFilters,
    query,
    setQuery,
    table,
    t,
  } = useCategoriesMutoon();

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.mutoon.categories')}</title>
      </Helmet>

      <Card>
        <FormProvider methods={methods}>
          <AppTableToolbar
            openFilters={openFilters}
            tableName=""
            setQuery={setQuery}
            handleSearch={handleSearch}
            search={query.search!}
            reset={() => methods.reset()}
            buttons={[
              {
                onClick: addCtgDialog.onTrue,
                label: t('tables.categories.addNew'),
                permissionName: AppPermissions.CONTENT_CREATE,
              },
              {
                onClick: addMtnDialog.onTrue,
                label: t('tables.mutoon.addNew'),
                permissionName: AppPermissions.CONTENT_CREATE,
              },
            ]}
            filters={[
              {
                name: 'onlyMutoons',
                title: t('tables.categories.filters.onlyMutoon'),
                type: 'boolean',
              },
            ]}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  setQuery={setQuery}
                  headLabel={CATEGORIES_AND_MUTOON_TABLE_HEAD(t)}
                  methods={methods}
                />
                <TableBody>
                  {isLoading || isDeletingCtg || isDeletingMatn ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      <>
                        {data?.categories?.map((row) => (
                          <CategoriesRow
                            key={row.categoryId}
                            row={row}
                            deleteHandler={handleDeleteCategory}
                          />
                        ))}
                      </>
                      <>
                        {data?.mutoon_categories?.map((row) => (
                          <CategoriesMutoonRow
                            key={row.categoryId}
                            row={row}
                            deleteHandler={handleDeleteMatn}
                          />
                        ))}
                      </>
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.categories_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
        <AddNewCategoryDialog open={addCtgDialog.value} onClose={addCtgDialog.onFalse} />
        <AddNewMatnDialog open={addMtnDialog.value} onClose={addMtnDialog.onFalse} />
      </Card>
    </Container>
  );
};

export default MutoonsCategories;
