import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router';
import Iconify from 'src/components/iconify';
import { OneUserQuery, UsersQuery } from 'src/graphql';
import { useLocales } from 'src/locales';

const AboutUser = () => {
  // #region States and hooks
  const { state }: { state: UsersQuery['users'][0] | undefined } = useLocation();
  const { t } = useLocales();
  const { userId } = useParams();
  const userData: OneUserQuery = useQueryClient().getQueryData(['OneUser', { id: userId }])!;

  const user = state || userData.users_by_pk;
  // #endregion States and hooks

  const INFO = [
    {
      label: t('userDetails.aboutUser.name'),
      value: user!?.firstName + ' ' + user!?.lastName,
      icon: 'fluent-mdl2:contact',
    },
    {
      label: t('userDetails.aboutUser.email'),
      value: user!?.email,
      icon: 'ic:baseline-email',
    },
    {
      label: t('userDetails.aboutUser.phone'),
      value: user!?.phoneNumber,
      icon: 'ph:phone',
    },
  ];

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Typography variant="h4">{t('userDetails.aboutUser.registerationInfo')}</Typography>
        <Divider />
        <Grid container spacing={3}>
          {INFO.map((info) => (
            <Grid item xs={12} md={6}>
              <Stack spacing={1} direction="row">
                <Iconify icon={info.icon} />
                <Stack spacing={1}>
                  <Typography fontWeight="bold">{info.label}</Typography>
                  <Typography>{info.value}</Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Card>
  );
};

export default AboutUser;
