import { useTheme } from '@mui/material';
import moment from 'moment';
import { useChart } from 'src/components/chart';
import { useHearingCouncilsAnalysisQuery, useSessionsAnalysisQuery } from 'src/graphql';
import { useLocales } from 'src/locales';

const useCouncilsStatistics = () => {
  const { t } = useLocales();

  const { data, isLoading: isLoadingSessions } = useHearingCouncilsAnalysisQuery({
    where: {
      _or: [
        {
          _and: [
            {
              startDate: {
                _gt: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                _lt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
              },
            },
            {
              expiryDate: { _gt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') },
            },
          ],
        },
        {
          _and: [
            {
              expiryDate: {
                _gt: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                _lt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
              },
            },
            {
              startDate: { _lt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') },
            },
          ],
        },
        {
          _and: [
            {
              startDate: {
                _gt: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
                _lt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
              },
            },
            {
              expiryDate: { _gt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') },
            },
          ],
        },
      ],
    },
  });

  const { data: todaySessions, isLoading: isLoadingTodaySessions } = useSessionsAnalysisQuery({
    where: {
      createdAt: {
        _gt: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        _lt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
      },
    },
  });

  const expiredSessions = data?.hearing_councils.filter((session) => session.status === 'Expired');
  const liveSessions = data?.hearing_councils.filter((session) => session.status === 'Live');
  const pendingSessions = data?.hearing_councils.filter((session) => session.status === 'Pending');

  const cardsToShow = [
    { data: expiredSessions, label: t('statistics.hearingCouncils.expiredCouncils') },
    { data: liveSessions, label: t('statistics.hearingCouncils.currentCouncils') },
    { data: pendingSessions, label: t('statistics.hearingCouncils.upcomingCouncils') },
    { data: todaySessions?.sessions, label: t('statistics.hearingCouncils.createdCouncils') },
  ];

  const theme = useTheme();

  const colors = [
    [theme.palette.primary.light, theme.palette.primary.main],
    [theme.palette.warning.light, theme.palette.warning.main],
  ];

  const chartOptionsCheckIn = useChart({
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0][0] },
          { offset: 100, color: colors[0][1] },
        ] as any,
      },
    },
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize as string,
          },
        },
      },
    },
  });

  return {
    cardsToShow,
    data,
    chartOptionsCheckIn,
    t,
    isLoadingSessions,
    isLoadingTodaySessions,
  };
};

export default useCouncilsStatistics;
