// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    statistics: `${ROOTS.DASHBOARD}/statistics`,
    roles: {
      root: `${ROOTS.DASHBOARD}/roles-and-users/roles`,
      addRole: `${ROOTS.DASHBOARD}/roles-and-users/roles/new-role`,
      editRole: (id: string) => `${ROOTS.DASHBOARD}/roles-and-users/roles/editRole/${id}`,
    },

    userAuthorization: {
      root: `${ROOTS.DASHBOARD}/user-authorization`,
      teachersRequests: `${ROOTS.DASHBOARD}/user-authorization/teachers-requests`,
      schoolsAccounts: `${ROOTS.DASHBOARD}/user-authorization/schools-accounts`,
    },

    contentManagement: {
      root: `${ROOTS.DASHBOARD}/content-management`,
      voiceRequests: `${ROOTS.DASHBOARD}/content-management/voice-requests`,
      scientificSessions: `${ROOTS.DASHBOARD}/content-management/scientific-sessions`,
      hearingCouncils: `${ROOTS.DASHBOARD}/content-management/hearing-councils`,
      hearingCouncilAnalysis: (hearingCouncilId: string) =>
        `${ROOTS.DASHBOARD}/content-management/hearing-councils/${hearingCouncilId}/analysis`,
      knowledgeBases: `${ROOTS.DASHBOARD}/content-management/knowledge-bases`,
      sessions: `${ROOTS.DASHBOARD}/content-management/sessions`,
      discussionsRoom: `${ROOTS.DASHBOARD}/content-management/discussion-room`,
      certificates: {
        root: `${ROOTS.DASHBOARD}/content-management/certificates`,
        builder: `${ROOTS.DASHBOARD}/content-management/certificates/builder`,
        table: `${ROOTS.DASHBOARD}/content-management/certificates`,
      },
      badges: {
        root: `${ROOTS.DASHBOARD}/content-management/badges`,
        customBadges: `${ROOTS.DASHBOARD}/content-management/badges/custom-badges`,
        generalBadges: `${ROOTS.DASHBOARD}/content-management/badges/general-badges`,
        new: {
          custom: `${ROOTS.DASHBOARD}/content-management/badges/new/custom-badges`,
          general: `${ROOTS.DASHBOARD}/content-management/badges/new/general-badges`,
        },
      },
      mutoon: {
        root: `${ROOTS.DASHBOARD}/content-management/mutoon`,
        matn: `${ROOTS.DASHBOARD}/content-management/mutoon/categories/matn`,
        categories: `${ROOTS.DASHBOARD}/content-management/mutoon/categories`,
        books: `${ROOTS.DASHBOARD}/content-management/mutoon/books`,
        exams: `${ROOTS.DASHBOARD}/content-management/mutoon/exams`,
        questions: `${ROOTS.DASHBOARD}/content-management/mutoon/questions`,
      },
    },

    appUsers: `${ROOTS.DASHBOARD}/content-management/app-users`,

    rolesAndUsers: {
      root: `${ROOTS.DASHBOARD}/roles-and-users`,
      roles: `${ROOTS.DASHBOARD}/roles-and-users/roles`,
      users: `${ROOTS.DASHBOARD}/roles-and-users/users`,
    },

    helpCenter: {
      root: `${ROOTS.DASHBOARD}/help-center`,
      faqs: `${ROOTS.DASHBOARD}/help-center/faqs`,
      contactUs: `${ROOTS.DASHBOARD}/help-center/contact-us`,
    },

    pages: {
      root: `${ROOTS.DASHBOARD}/pages`,
      aboutTheApp: `${ROOTS.DASHBOARD}/about-the-app`,
      faqs: `${ROOTS.DASHBOARD}/faqs`,
      privacyPolicy: `${ROOTS.DASHBOARD}/privacy-policy`,
      termsOfService: `${ROOTS.DASHBOARD}/terms-of-service`,
    },
  },
};
