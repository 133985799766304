import { Card, Stack, Typography } from '@mui/material';
import Chart from 'src/components/chart';
import { HearingCouncilAnalysisQuery } from 'src/graphql';
import useHearingCouncilsCharts from './use-hearing-councils-charts';

export type HearingCouncilsChartsPropsType = {
  data: HearingCouncilAnalysisQuery;
  isLoading: boolean;
};

const HearingCouncilsCharts = ({ data, isLoading }: HearingCouncilsChartsPropsType) => {
  const { CHARTS_INFO, CHART_SIZE, chartOptionsCheckIn, totalAttendance } =
    useHearingCouncilsCharts({ data, isLoading });

  return (
    <Stack direction="row" spacing={2} my={2}>
      {CHARTS_INFO.map((chart) => (
        <Card sx={{ p: 1 }}>
          <Stack direction="row" spacing={1}>
            <Chart
              type="radialBar"
              series={[+((chart.value! / totalAttendance!) * 100).toFixed(1) || 0]}
              options={chartOptionsCheckIn}
              {...CHART_SIZE}
            />
            <Stack justifyContent="space-evenly">
              <Typography fontWeight="bold">{chart.value!}</Typography>
              <Typography>{chart.label!}</Typography>
            </Stack>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default HearingCouncilsCharts;
