import { useQueryClient } from '@tanstack/react-query';
import { useTable } from 'src/components/table';
import {
  Order_By,
  useDeleteRefBookMutation,
  useReferenceBooksQuery
} from 'src/graphql';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

export type RefBooksFormType = {
  matnId: string;
  sortBy: string;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
  bookAuthor?: string;
  bookName?: string;
  createdAt?: string;
};

const useRefBooks = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<RefBooksFormType>();

  // #region Services
  const { data, isLoading } = useReferenceBooksQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _ilike: `%${query.search || ''}%` } : undefined,
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    matnId: { _eq: methods.watch().matnId },
  });

  const { mutate, isLoading: isDeleting } = useDeleteRefBookMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['ReferenceBooks'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteBook = (bookId: string) => {
    mutate({ bookId });
  };
  // #endregion Handlers

  return {
    isDeleting,
    t,
    methods,
    handleSearch,
    isLoading,
    table,
    data,
    handleDeleteBook,
    setQuery,
    query
  };
};

export default useRefBooks;
