import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { EXAMS_STATUS } from 'src/constants/exam-status';
import useAddNewExam from './use-add-new-exam';
import { Helmet } from 'react-helmet-async';

const AddNewExam = () => {
  const {
    isLoading,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    langsWithErrors,
    t,
    certificates,
    hasGotMtns,
    mutoonOpts,
    isEditing,
    examId,
    confirm,
    isAdding,
    isUpdating,
    mutate,
    currentLang,
  } = useAddNewExam();

  return (
    <Container>
      <Helmet>
        <title>{t('titles.addExam')}</title>
      </Helmet>

      <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={null} sx={{ height: 'auto' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TranslationTabs
                currentTabValue={currentTabValue}
                handleChangeTab={handleChangeTab}
                errors={langsWithErrors}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Block label={t('dialogs.addNewExam.title')}>
                <RHFTextField size="small" name={`title.${currentTabValue}`} />
              </Block>
            </Grid>

            <Grid item md={6} xs={12}>
              <Block label={t('dialogs.addNewExam.description')}>
                <RHFTextField size="small" name={`description.${currentTabValue}`} />
              </Block>
            </Grid>

            <Grid item md={6} xs={12}>
              <Block label={t('dialogs.addNewHearingCouncil.matn')}>
                <RHFAutocomplete
                  name="mutonName"
                  valueName="mutonId"
                  size="small"
                  disabled={!hasGotMtns}
                  options={mutoonOpts}
                  groupBy={(option) => option.category.name!?.[currentLang.value]}
                />
              </Block>
            </Grid>

            <Grid item md={6} xs={12}>
              <Block label={t('dialogs.addNewExam.certificate')}>
                <RHFSelect size="small" name="certificateexamId">
                  {certificates?.certificates.map((certificate) => (
                    <MenuItem key={certificate.certificateId} value={certificate.certificateId}>
                      {certificate.title}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Block>
            </Grid>

            <Grid item xs={12}>
              <Accordion
                sx={{
                  border: `1px solid #ddd`,
                  borderRadius: 2,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {t('dialogs.addNewExam.moreDetails')}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.gradeA')}>
                        <RHFTextField size="small" type="number" name="gradeA" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.gradeB')}>
                        <RHFTextField size="small" type="number" name="gradeB" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.gradeC')}>
                        <RHFTextField size="small" type="number" name="gradeC" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.gradeD')}>
                        <RHFTextField size="small" type="number" name="gradeD" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.gradeF')}>
                        <RHFTextField size="small" type="number" name="gradeF" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.minimumScore')}>
                        <RHFTextField size="small" type="number" name="minimumScore" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.maxAttempts')}>
                        <RHFTextField size="small" type="number" name="maxAttempts" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.limit')}>
                        <RHFTextField size="small" type="number" name="limit" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.duration')}>
                        <RHFTextField size="small" type="number" name="duration" />
                      </Block>
                    </Grid>

                    <Grid item md={12 / 5} xs={12}>
                      <Block required={false} label={t('dialogs.addNewExam.status')}>
                        <RHFSelect size="small" name="status">
                          {EXAMS_STATUS(t).map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      </Block>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <AppLoadingButton
                label={isEditing ? t('buttons.edit') : t('buttons.add')}
                isLoading={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </Container>
  );
};

export default AddNewExam;
