import { JitsiMeeting } from '@jitsi/react-sdk';
import { Card, Container, Stack } from '@mui/material';
import AppButton from 'src/components/common/AppButton';
import useSessionJoin from './use-session-join';

const SessionJoin = () => {
  const { domain, jwt, returnToPath, router, sessionId, handleApiReady, t } = useSessionJoin();
  console.log("DOMAIN", domain);
  console.log("JWT", jwt);
  console.log("SESSION ID", sessionId);

  return (
    <Container maxWidth="xl" sx={{ height: '100vh' }}>
      <Stack spacing={1} alignItems="end" justifyContent="space-between">
        <Card sx={{ height: '100vh', width: '100%' }} className="jitsi-card">
          <JitsiMeeting
            roomName={sessionId!}
            domain={domain}
            jwt={jwt!}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = '100%';
              iframeRef.classList.add('jitsi-iframe');
            }}
            onReadyToClose={() => {
              router(returnToPath);
            }}
            onApiReady={handleApiReady}
          />
        </Card>
      </Stack>
    </Container>
  );
};

export default SessionJoin;
