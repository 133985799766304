import { useQueryClient } from '@tanstack/react-query';
import { useTable } from 'src/components/table';
import { Order_By, useBadgesQuery, useDeleteBadgeMutation } from 'src/graphql';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

const useBadges = ({ isGeneral }: { isGeneral: boolean }) => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>();

  // #region Services
  const { data, isLoading } = useBadgesQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    where: {
      programName: query.search
        ? { _cast: { String: { _ilike: `%${query.search}%` } } }
        : undefined,
      generalType: { _is_null: isGeneral ? false : true },
    },
  });

  const { mutate, isLoading: isDeleting } = useDeleteBadgeMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Badges'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteBadge = (badgeId: string) => {
    mutate({ badgeId });
  };
  // #endregion Handlers

  return {
    isDeleting,
    isLoading,
    methods,
    handleDeleteBadge,
    handleSearch,
    query,
    setQuery,
    data,
    table,
    t,
  };
};

export default useBadges;
