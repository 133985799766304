import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Block } from 'src/components/Block';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import NewAppButton from 'src/components/common/NewAppButton';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom } from 'src/components/table';
import { PERMISSIONS_TABLE_HEAD } from 'src/constants/tables-heads';
import { useActionsQuery, useCreateRoleMutation } from 'src/graphql';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { ICreateRoleDto, IRoleActionsDto } from 'src/types/roles';
import { arrAssembly } from 'src/utils/rolesFunHelper';

export default function AddRoleForm() {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const { AddRoleSchema } = useSchemas();
  const [roleActions, setRoleActions] = useState<IRoleActionsDto[]>();

  const { data: permissions, isLoading: isLoadingPermissions, isSuccess } = useActionsQuery();

  const { mutate: createRole, isLoading: isLoadingCreateRole } = useCreateRoleMutation({
    onSuccess: () => {
      router.push(paths.dashboard.roles.root);
    },
  });

  // For display permissions in table shape
  const permissionsGroup = arrAssembly(permissions?.actions ?? []);

  // For handling checkboxes values and add it to array
  const handlePermissions = (
    event: React.ChangeEvent<HTMLInputElement>,
    permission: { id: string; name: string }
  ) => {
    const checked = event.target.checked;

    if (checked) {
      const permissionObj = permissions?.actions?.find((obj) => obj.id === permission?.id);
      setRoleActions((prevRoleActions: any) => [...prevRoleActions, permissionObj]);
    } else {
      setRoleActions((prevRoleActions: any) =>
        prevRoleActions.filter((obj: any) => obj.id !== permission?.id)
      );
    }
  };

  const methods = useForm<ICreateRoleDto>({
    resolver: yupResolver(AddRoleSchema),
  });

  const onSubmit = useCallback(
    async (data: ICreateRoleDto) => {
      const roleActionsIds: any = roleActions?.map((item: any) => ({
        actionId: item.id,
      }));

      createRole({ ...data, role_actions: { data: roleActionsIds } });
    },
    [roleActions]
  );

  useEffect(() => {
    if (isSuccess) setRoleActions(permissions?.actions);
  }, [isSuccess]);

  return (
    <AppLoadingAndErrorWrapper
      errorMessage={null}
      isLoading={isLoadingPermissions}
      sx={{ height: 'auto' }}
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container rowSpacing={3} alignItems="center">
          <Block label={t('dialogs.addNewRole.roleName')}>
            <RHFTextField name="name" label={t('dialogs.addNewRole.enterRoleName')} />
          </Block>
          {/* Permissions */}
          <Grid item xs={12} mt={4}>
            <Typography variant="h4" color="primary">
              {t('dialogs.addNewRole.permissions')}
            </Typography>
          </Grid>
          <Grid item container>
            <TableContainer>
              <Scrollbar>
                <Table>
                  <TableHeadCustom
                    headLabel={PERMISSIONS_TABLE_HEAD(t)}
                    sx={{
                      border: '1px solid rgba(60, 60, 66, 0.30)',
                      bgcolor: 'primary.main',
                    }}
                  />
                  <TableBody>
                    {permissionsGroup?.map((item, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            border: '1px solid rgba(60, 60, 66, 0.30)',
                            '&:last-of-type': {
                              '& .MuiTableCell-root': {
                                borderColor: 'rgba(60, 60, 66, 0.30)',
                              },
                            },
                          }}
                        >
                          <TableCell component="caption" scope="row">
                            {t(`dialogs.addNewRole.actionsTable.rows.${item?.systemCriteria}` as any)}
                          </TableCell>
                          {item?.permissions?.map((permission, permissionsIndex) => (
                            <TableCell
                              key={permissionsIndex}
                              align="center"
                              sx={{
                                border: '1px solid rgba(60, 60, 66, 0.30)',
                              }}
                            >
                              <Checkbox
                                color="primary"
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                defaultChecked
                                onChange={(event) => handlePermissions(event, permission)}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <NewAppButton
              label={t('buttons.add')}
              type="submit"
              color="primary"
              isLoading={isLoadingCreateRole}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </AppLoadingAndErrorWrapper>
  );
}
