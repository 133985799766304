import { Container, Stack } from '@mui/material';
import React from 'react';
import Users from './table/users';
import UsersAvatars from './users-avatars';

const UserManagementView = () => {
  return (
    <Container>
      <Stack spacing={3}>
        <UsersAvatars />
        <Users />
      </Stack>
    </Container>
  );
};

export default UserManagementView;
