import moment from 'moment';
import { useTable } from 'src/components/table';
import { AppTablePropsType } from 'src/components/table/app-table';
import { useLocales } from 'src/locales';
import { ConnectedUser } from 'src/types/hearing-council';

const useConnectedUsers = () => {
  // #region States and hooks
  const { t } = useLocales();
  const table = useTable({
    defaultRowsPerPage: Infinity,
    defaultCurrentPage: 0,
  });
  // #endregion

  const columns: AppTablePropsType<ConnectedUser>['columns'] = [
    {
      name: 'firstName',
    },
    {
      name: 'lastName',
    },
    {
      name: 'email',
    },
  ];

  return {
    t,
    table: table,
    columns: columns.filter((col) => col),
  };
};

export default useConnectedUsers;
