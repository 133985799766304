import { Card, CardHeader, Table, TableBody, TableContainer, Typography } from '@mui/material';
import moment from 'moment';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton, useTable } from 'src/components/table';
import { USERS_STATISTICS_TABLE_HEAD } from 'src/constants/tables-heads';
import { useUsersQuery } from 'src/graphql';
import { useLocales } from 'src/locales';
import UserRow from './user-row';

const UsersStatistics = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const { t } = useLocales();

  const { data: users, isLoading } = useUsersQuery({
    where: {
      createdAt: {
        _gt: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        _lt: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
      },
    },
  });

  return (
    <Card>
      <CardHeader sx={{ p: 2 }} title={t('statistics.users.lastJoiningUsers')} />
      <TableContainer>
        <Scrollbar>
          <Table>
            <TableHeadCustom headLabel={USERS_STATISTICS_TABLE_HEAD(t)} />
            <TableBody>
              {isLoading
                ? [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: 80 }} />
                  ))
                : users?.users.map((user) => <UserRow row={user!} />)}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      {users && (
        <TablePaginationCustom
          count={+users?.users_aggregate.aggregate?.count!}
          page={+table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
        />
      )}
    </Card>
  );
};

export default UsersStatistics;
