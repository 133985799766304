import React, { useRef } from 'react';
import ReactHlsPlayer from 'react-hls-player';

const PreviewAudio = ({ src }: { src: string }) => {
  const ref = useRef(null);
  const iSm3u8Type = src.slice(-4) === 'm3u8';
  return iSm3u8Type ? (
    <ReactHlsPlayer
      playerRef={ref}
      src={src}
      autoPlay={false}
      controls={true}
      width="100%"
      height="60px"
    />
  ) : (
    <audio controls>
      <source src={src} />
    </audio>
  );
};

export default PreviewAudio;
