import { Button, Card, Grid, Typography } from '@mui/material';
import React from 'react';
import Image from 'src/components/image/image';
import { useUsersQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';

const UsersAvatars = () => {
  const { data: users, isLoading: isLoadingUsers } = useUsersQuery({
    offset: null,
    limit: null,
  });
  const showAll = useBoolean();

  return (
    <Card sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {users?.users.map((user, index) => {
          if (!showAll.value) {
            return index < 11 ? (
              <Grid
                xs={3}
                sm={2}
                md={1}
                item
                textAlign={'-webkit-center' as any}
                justifyContent="center"
              >
                <Card
                  sx={{ bgcolor: 'background.neutral', borderRadius: '50%', height: 64, width: 64 }}
                >
                  <Image src={user.avatar} width={64} height={64} />
                </Card>
                <Typography fontSize={10} noWrap>{`${user.firstName} ${user.lastName}`}</Typography>
              </Grid>
            ) : index === 11 ? (
              <Grid
                xs={3}
                sm={2}
                md={1}
                item
                textAlign={'-webkit-center' as any}
                justifyContent="center"
              >
                <Button onClick={showAll.onTrue}>
                  <Card
                    sx={{
                      bgcolor: 'background.neutral',
                      borderRadius: '50%',
                      height: 64,
                      width: 64,
                      backgroundColor: 'text.contrastText',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography fontSize={20} noWrap>
                      {`+${users.users_aggregate.aggregate?.count! - 11}`}
                    </Typography>
                  </Card>
                </Button>
              </Grid>
            ) : (
              <></>
            );
          } else {
            return (
              <Grid
                xs={3}
                sm={2}
                md={1}
                item
                textAlign={'-webkit-center' as any}
                justifyContent="center"
              >
                <Card
                  sx={{ bgcolor: 'background.neutral', borderRadius: '50%', height: 64, width: 64 }}
                >
                  <Image src={user.avatar} width={64} height={64} />
                </Card>
                <Typography fontSize={10} noWrap>{`${user.firstName} ${user.lastName}`}</Typography>
              </Grid>
            );
          }
        })}
      </Grid>
    </Card>
  );
};

export default UsersAvatars;
