import { Dialog, Card, Stack } from '@mui/material';
import React from 'react';
import Image from 'src/components/image/image';
import { useBoolean } from 'src/hooks/use-boolean';
import QRCode from 'react-qr-code';
import { USER_DASHBOARD_DOMAIN } from 'src/config-global';
import AppButton from 'src/components/common/AppButton';
import { useLocales } from 'src/locales';
import { usePDF } from 'react-to-pdf';

export type HearingCouncilPropsType = {
  open: boolean;
  onClose: VoidFunction;
  hearingCouncilId: string;
};

const HearingCouncilQrDialog = ({ open, onClose, hearingCouncilId }: HearingCouncilPropsType) => {
  const confirm = useBoolean();
  const { t } = useLocales();
  const { toPDF, targetRef } = usePDF();

  const handleDownloadQrCode = () => {
    toPDF({ method: 'save' });
  };

  return (
    <Dialog
      open={open && !confirm.value}
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
    >
      <Stack spacing={2}>
        <Card ref={targetRef} sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          {/* <Image src={`/dashboard/hearing-councils/${hearingCouncilId}/onSite`} /> */}
          <QRCode
            value={`${USER_DASHBOARD_DOMAIN}/dashboard/hearing-councils/${hearingCouncilId}/onSite`}
          />
        </Card>
        <AppButton label={t('buttons.download')} onClick={handleDownloadQrCode} />
      </Stack>
    </Dialog>
  );
};

export default HearingCouncilQrDialog;
