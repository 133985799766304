import { Grid, MenuItem } from '@mui/material';
import { Block } from 'src/components/Block';
import { RHFTextField } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { useLocales } from 'src/locales';
import { AddQuestionMutationVariables } from 'src/types/questions';
import Answer from './Answer';
import { useAddQuestionsContext } from './context/questions-context';
import RHFAutocomplete from 'src/components/hook-form/rhf-autocomplete';

const Question = ({
  index,
  question,
}: {
  index: number;
  question: AddQuestionMutationVariables['questions'][0];
}) => {
  const { t, currentLang } = useLocales();
  const { currentTabValue, matnSections } = useAddQuestionsContext();

  return (
    <>
      <Grid item xs={12}>
        <Block label={t('dialogs.addNewQuestion.questionText')}>
          <RHFTextField
            size="small"
            name={`questions.${[index]}.questionText.${currentTabValue}`}
            variant="outlined"
            multiline
          />
        </Block>
      </Grid>

      <Answer index={index} type={question.type!} />

      <Grid item xs={12} md={6}>
        <Block required={false} label={t('dialogs.addNewQuestion.explanation')}>
          <RHFTextField size="small" name={`questions.${[index]}.explanation.${currentTabValue}`} />
        </Block>
      </Grid>

      <Grid item xs={12} md={6}>
        <Block required={false} label={t('dialogs.addNewQuestion.hint')}>
          <RHFTextField size="small" name={`questions.${[index]}.hint.${currentTabValue}`} />
        </Block>
      </Grid>

      <Grid item xs={12} md={6}>
        <Block required={false} label={t('dialogs.addNewQuestion.matnSection')}>
          {/* <RHFSelect size="small" name={`questions.${[index]}.mutoonSectionId`}>
            {matnSections?.mutoon_sections.map((matnSections) => (
              <MenuItem key={matnSections.mutoonSectionId} value={matnSections.mutoonSectionId}>
                {matnSections.name[currentLang.value]}
              </MenuItem>
            ))}
          </RHFSelect> */}
          <RHFAutocomplete
            name={`questions.${[index]}.mutoonSectionName`}
            valueName={`questions.${[index]}.mutoonSectionId`}
            size="small"
            options={
              matnSections?.mutoon_sections.map((section) => ({
                value: section.mutoonSectionId,
                label: section.name[currentLang.value],
              }))! || []
            }
          />
        </Block>
      </Grid>

      <Grid item xs={12} md={6}>
        <Block label={t('dialogs.addNewQuestion.points')}>
          <RHFTextField size="small" name={`questions.${[index]}.points`} type="number" />
        </Block>
      </Grid>
    </>
  );
};

export default Question;

// Written answer jsx
