import { Card, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import Image from 'src/components/image/image';
import { UsersQuery, useOneUserQuery } from 'src/graphql';
import { useLocales } from 'src/locales';

const LINKS = (t: any) => [
  {
    title: t('userDetails.tabs.aboutUser'),
    href: 'about-user',
  },
  {
    title: t('userDetails.tabs.sessions'),
    href: 'sessions',
  },
  {
    title: t('userDetails.tabs.hearingCouncils'),
    href: 'hearing-councils',
  },
];

type Props = {
  children: React.ReactNode;
};

const UserDetailsView = ({ children }: Props) => {
  // #region States and hooks
  const { state }: { state: UsersQuery['users'][0] | undefined } = useLocation();
  const { userId } = useParams();
  const { t } = useLocales();
  const currentTabFromUrl = window.location.href.slice(window.location.href.lastIndexOf('/') + 1);
  const [currentTabValue, setCurrentTabValue] = useState(currentTabFromUrl);
  // #endregion States and hooks

  // #region Services
  const { data, isFetching: isLoadingUser } = useOneUserQuery({ id: userId }, { enabled: !state });
  // #endregion Services

  const user = state || data?.users_by_pk;

  return (
    <AppLoadingAndErrorWrapper isLoading={isLoadingUser}>
      <Container>
        <Stack spacing={2}>
          {/* Header card */}
          <Card sx={{ p: 3 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Card sx={{ width: 74, height: 74, borderRadius: '50%', border: '1px solid' }}>
                <Image src={user?.avatar} width={74} height={74} />
              </Card>
              <Typography variant="h4">{`${user?.firstName} ${user?.lastName}`}</Typography>
            </Stack>
          </Card>
          {/* Tabs */}
          <Card>
            <Tabs
              value={currentTabValue}
              sx={{
                backgroundColor: 'background.neutral',
                px: 2,
                padding: 0.75,
                borderRadius: 2,
                '.MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              {LINKS(t).map((tab) => (
                <Link
                  to={tab.href}
                  state={user}
                  onClick={(event: any) => {
                    const matchHref = LINKS(t).filter(
                      (link) => link.title === event.target.innerHTML
                    )[0].href;
                    setCurrentTabValue(matchHref);
                  }}
                >
                  <Tab
                    key={tab.href}
                    value={tab.href}
                    label={tab.title}
                    sx={{
                      backgroundColor:
                        tab.href === currentTabValue ? 'background.default' : 'background.neutral',
                      borderRadius: 2,
                      fontWeight: 500,
                      px: 3,
                      py: 1,
                    }}
                  />
                </Link>
              ))}
            </Tabs>
          </Card>
          {children}
        </Stack>
      </Container>
    </AppLoadingAndErrorWrapper>
  );
};

export default UserDetailsView;
