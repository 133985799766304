import { String_Comparison_Exp } from 'src/graphql';

export const findParentId = (splat: string | undefined): String_Comparison_Exp => {
  const isOnTheRootCtgs = !splat;
  if (isOnTheRootCtgs) return { _is_null: true };
  // Remove last slash
  if (splat!?.[splat?.length - 1] === '/') splat = splat?.slice(0, splat.length - 1);
  // If it has no slashes now so it is one level ctg only (one id in the url)
  if (!splat?.includes('/')) {
    return { _eq: splat };
  } else {
    const lastParentIdSlashIndex = splat
      ?.slice(0, splat.length - 2)
      .lastIndexOf('/', splat.length - 3);

    const lastParentId = splat?.slice(lastParentIdSlashIndex + 1);

    const categoryCond = { _eq: lastParentId };

    return categoryCond;
  }
};
export const findParentIdString = (splat: string | undefined): string | null => {
  const isOnTheRootCtgs = !splat;
  if (isOnTheRootCtgs) return null;
  // Remove last slash
  if (splat!?.[splat?.length - 1] === '/') splat = splat?.slice(0, splat.length - 1);
  // If it has no slashes now so it is one level ctg only (one id in the url)
  if (!splat?.includes('/')) {
    return splat!;
  } else {
    const lastParentIdSlashIndex = splat
      ?.slice(0, splat.length - 2)
      .lastIndexOf('/', splat.length - 3);

    const lastParentId = splat?.slice(lastParentIdSlashIndex + 1);

    return lastParentId;
  }
};
