import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  useAddExamMutation,
  useCertificatesQuery,
  useMutoonCategoriesQuery,
  useOneExamQuery,
  useUpdateExamMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddExamMutationVariables, ExamDtoType } from 'src/types/exams';

const useAddNewExam = () => {
  // #region States and hooks
  const { AddExamSchema } = useSchemas();
  const confirm = useBoolean();
  const { t, allLangs } = useLocales();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { currentLang } = useLocales();
  const router = useNavigate();
  const { examId } = useParams();
  const isEditing = !!examId;
  const { state }: { state: ExamDtoType | undefined } = useLocation();
  const [dataToSend, setDataToSend] = useState<AddExamMutationVariables>();
  // #endregion

  // #region Form
  const defaultValues: AddExamMutationVariables = {
    certificateexamId: state?.certificateexamId || undefined,
    description: state?.description || {},
    duration: state?.duration || 10,
    gradeA: state?.gradeA || 90,
    gradeB: state?.gradeB || 80,
    gradeC: state?.gradeC || 70,
    gradeD: state?.gradeD || 60,
    gradeF: state?.gradeF || 49,
    minimumScore: state?.minimumScore || 50,
    limit: state?.limit || 20,
    maxAttempts: state?.maxAttempts || 3,
    mutonId: state?.mutonId!,
    mutonName: state?.mutoon!?.name!?.[currentLang.value] || '',
    status: state?.status || 'active',
    title: state?.title || {},
  };

  for (let i = 0; i < allLangs.length && !state; i++) {
    defaultValues.description[allLangs[i].value] = '';
    defaultValues.title[allLangs[i].value] = '';
  }

  const methods = useForm<AddExamMutationVariables>({
    defaultValues,
    resolver: yupResolver(AddExamSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddExamMutationVariables) => {
      const newData = { ...data };
      delete (newData as any).mutonName;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(methods.formState.errors);
  // #endregion form

  // #region Services
  const { mutate: addExam, isLoading: isAdding } = useAddExamMutation({
    onSuccess: (data) => {
      router(`/dashboard/mutoon/exams/questions/${data.insert_exams_one?.examId}`, {
        state: { exam: data!?.insert_exams_one },
      });
    },
  });

  const { mutate: updateExam, isLoading: isUpdating } = useUpdateExamMutation({
    onSuccess: () => {
      router('/dashboard/mutoon/exams');
    },
  });

  // Add or edit?
  const mutate = isEditing
    ? () => updateExam({ ...dataToSend!, examId: examId })
    : () => addExam(dataToSend!);

  // Get mutoon
  const {
    data: mutoon,
    isSuccess: hasGotMtns,
    isFetching: isLoadingMtns,
  } = useMutoonCategoriesQuery({
    categoryCond: {},
  });

  const mutoonOpts =
    mutoon?.mutoon_categories.map((mtn) => ({
      label: mtn.mutoon.name!?.[currentLang.value]!,
      value: mtn.mutoonId!,
      category: mtn.category.name!?.[currentLang.value],
    })) || [];

  const { data: certificates, isLoading: isLoadingCertificates } = useCertificatesQuery();

  const { isFetching: isExamLoading } = useOneExamQuery(
    {
      examId: examId,
    },
    {
      enabled: !!examId && !state,
      onSuccess: (data) => {
        methods.setValue('certificateexamId', data.exams_by_pk!?.certificateexamId!);
        methods.setValue('description', data.exams_by_pk!?.description);
        methods.setValue('duration', data.exams_by_pk!?.duration);
        methods.setValue('gradeA', data.exams_by_pk!?.gradeA);
        methods.setValue('gradeB', data.exams_by_pk!?.gradeB);
        methods.setValue('gradeC', data.exams_by_pk!?.gradeC);
        methods.setValue('gradeD', data.exams_by_pk!?.gradeD);
        methods.setValue('gradeF', data.exams_by_pk!?.gradeF);
        methods.setValue('minimumScore', data.exams_by_pk!?.minimumScore);
        methods.setValue('limit', data.exams_by_pk!?.limit);
        methods.setValue('maxAttempts', data.exams_by_pk!?.maxAttempts);
        methods.setValue('categoryId', data.exams_by_pk!?.mutoon.categoryId!);
        methods.setValue('levelId', data.exams_by_pk!?.mutoon.levelId!);
        methods.setValue('mutonId', data.exams_by_pk!?.mutonId);
        methods.setValue('status', data.exams_by_pk!?.status);
        methods.setValue('title', data.exams_by_pk!?.title);
      },
    }
  );

  const isLoading = isExamLoading || isLoadingCertificates;
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffects
  useEffect(() => {
    methods.setValue(`title.${currentTabValue}`, methods.watch().title[currentTabValue] || '');
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
  }, [methods, currentTabValue]);
  // #endregion useEffects

  const value = {
    isLoading,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    handleChangeTab,
    langsWithErrors,
    t,
    certificates,
    hasGotMtns,
    mutoonOpts,
    isEditing,
    examId,
    confirm,
    isAdding,
    isUpdating,
    mutate,
    currentLang,
  };

  return value;
};

export default useAddNewExam;
