import { Grid, IconButton, Radio, Stack, RadioGroup, MenuItem } from '@mui/material';
import { Block } from 'src/components/Block';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify/iconify';
import { useLocales } from 'src/locales';
import { useAddQuestionsContext } from './context/questions-context';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import { QUESTIONS_CHOICES_TYPE } from 'src/constants/questions-choices-type';
import AppButton from 'src/components/common/AppButton';
import { useFieldArray } from 'react-hook-form';

const Answer = ({ index, type }: { index: number; type: string }) => {
  const { t } = useLocales();
  const { currentTabValue, methods, handleCheckRadio } = useAddQuestionsContext();

  const {
    fields: choices,
    append: addChoice,
    remove: deleteChoice,
  } = useFieldArray({
    control: methods.control,
    name: `questions.${index}.choicesOptions` as any,
  });

  // Written answer
  const writtenAnswer = (
    <Grid item xs={12}>
      <Block label={t('dialogs.addNewQuestion.correctAnswer')}>
        <RHFTextField
          size="small"
          name={`questions.${index}.correctAnswerText.${currentTabValue}`}
        />
      </Block>
    </Grid>
  );

  const choicesAnswer = (choiceIndex: number) => {
    const isRadioChecked =
      methods.getValues(`questions.${index}.correctAnswerIndex`) === choiceIndex;
    const isTheLastChoice = choiceIndex === choices?.length! - 1;
    return (
      <Grid item xs={12} key={choiceIndex}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Radio onClick={() => handleCheckRadio(index, choiceIndex)} checked={isRadioChecked} />
          <RHFTextField
            size="small"
            name={`questions.${index}.choicesOptions.${choiceIndex}`}
            variant="standard"
          />
          <IconButton onClick={() => deleteChoice(choiceIndex)}>
            <Iconify icon="lucide:x" color="error.main" />
          </IconButton>
          {isTheLastChoice && (
            <IconButton onClick={() => addChoice('')}>
              <Iconify icon="carbon:add-filled" color="primary.main" />
            </IconButton>
          )}
        </Stack>
      </Grid>
    );
  };

  // Return the type that matches to the question type
  return type === 'choice' ? (
    <Grid item xs={12}>
      {/* Choices type */}
      <Block label={t('dialogs.addNewQuestion.chooseType')} sx={{ mb: 2 }}>
        <RHFSelect name={`questions.${index}.choicesType`} size="small">
          {QUESTIONS_CHOICES_TYPE(t).map((choice) => (
            <MenuItem value={choice.value} key={choice.value}>{choice.label}</MenuItem>
          ))}
        </RHFSelect>
      </Block>
      <Block label={t('dialogs.addNewQuestion.choices')}>
        <RadioGroup>
          {choices?.map((choice, choiceIndex) => {
            return choicesAnswer(choiceIndex);
          })}
        </RadioGroup>
        {!choices?.length && (
          <AppButton
            label={t('buttons.add')}
            onClick={() => addChoice('')}
            size="small"
            variant="outlined"
          />
        )}
      </Block>
    </Grid>
  ) : (
    writtenAnswer
  );
};

export default Answer;
