import { useTheme } from '@mui/material/styles';

import { useResponsive } from 'src/hooks/use-responsive';

import { Grid, Stack, Typography } from '@mui/material';
import Image from 'src/components/image/image';
import { useLocales } from 'src/locales';

import image1 from 'src/assets/illustrations/login/image2.png';
import image2 from '../../../public/assets/image1.svg';
import Logo from 'src/components/logo/logo';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const theme = useTheme();
  const { t } = useLocales();
  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Grid
      item
      md={6}
      sm={12}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 20 },
      }}
    >
      {children}
    </Grid>
  );

  const renderSection = (
    <Grid
      item
      md={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ background: '#428263', position: 'relative' }}
    >
      <Image src={image1} sx={{ opacity: 0.1, position: 'absolute' }} />
      <Image
        src={image2}
        sx={{
          position: 'absolute',
          height: '100%',
          padding: 1,
          width: '75%',
        }}
        objectFit="contain"
      />
      <Stack justifyContent="center" alignItems="center" textAlign="center" zIndex={1000}>
        <Logo
          disabledLink
          color="white"
          width={100}
          height={100}
          sx={{ opacity: 1, zIndex: 1000 }}
        />
        <Typography color="white" variant="h2">
          {t('login.mtoun')}
        </Typography>
        <Typography color="white" variant="body1">
          {t('login.idealChoice')}
        </Typography>
      </Stack>
    </Grid>
  );

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
      }}
    >
      {mdUp && renderSection}

      {renderContent}
    </Grid>
  );
}
