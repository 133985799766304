import { TableCell, Checkbox } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { TableProps, useTable } from 'src/components/table';
import { AppTablePropsType } from 'src/components/table/app-table';
import RowActions, { RowActionsPropsType } from 'src/components/table/table-row-actions';
import AppPermissions from 'src/constants/app-permissions';
import {
  Order_By,
  SchoolStudentsQuery,
  useCreateCouncilCertificateMutation,
  useSchoolStudentsQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { default as UseFormWithSearch, default as UseSearch } from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

const useStudents = ({ userId, newTable }: { newTable?: TableProps; userId: string }) => {
  // #region States and hooks
  const { t } = useLocales();
  const queryClient = useQueryClient();
  const openFilters = useBoolean();
  const router = useNavigate();
  const confirm = useBoolean();
  const changeStatusDialog = useBoolean();
  const addToPlaylistDialog = useBoolean();
  const [selectedRow, setSelectedRow] = useState<SchoolStudentsQuery['students'][0]>();
  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
  });
  const { hearingCouncilId } = useParams();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>({}, 'searchStudents');

  // This is used only in students attendance
  // #endregion

  // #region Services
  const { data: students, isLoading } = useSchoolStudentsQuery(
    {
      offset: newTable?.offset || table.offset,
      limit: newTable?.rowsPerPage || table.rowsPerPage,
      order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
      search: query.searchStudents ? { _ilike: `%${query.searchStudents}%` } : {},
      userId: userId,
      hearingCouncilId: hearingCouncilId!,
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  const { mutate: createCert, isLoading: isCreatingCert } = useCreateCouncilCertificateMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['HearingCouncilAnalysis'] });
      queryClient.invalidateQueries({ queryKey: ['SchoolStudents'] });
      if (!data.createUserHearingCouncilCertificate.length) {
        enqueueSnackbar({
          message: t('tables.finishedHearingCouncilsAnalysis.createCertResWarning'),
          variant: 'warning',
        });
      }
    },
  });
  // #endregion Services

  // #region Handlers
  const hasUserBeenAttended = (student: SchoolStudentsQuery['students'][0]) => {
    const studentUserHearingCouncil = student.user_hearing_councils.find(
      (user) => user.hearingCouncilId === hearingCouncilId
    );
    return studentUserHearingCouncil;
  };

  const handleExportCert = (row: SchoolStudentsQuery['students'][0]) => {
    createCert({
      createCertficateInput: {
        customName: row.firstName + ' ' + row.lastName,
        userHearingCouncilId: row.user_hearing_councils.find(
          (uhc) => uhc.hearingCouncilId === hearingCouncilId!
        )?.userHearingCouncilId,
      },
    });
  };
  // #endregion Handlers

  const generateMenuOption = (
    row: SchoolStudentsQuery['students'][0]
  ): RowActionsPropsType['options'] => {
    const userCertificate = row.user_certificates.find(
      (cert) => cert.userHearingCouncil?.hearingCouncil.hearingCouncilId === hearingCouncilId
    );

    return [
      {
        tooltip: t('dialogs.addNewExam.certificate'),
        icon: 'fluent:certificate-16-regular',
        onClick: () => {
          setSelectedRow(row);
          confirm.onTrue();
        },
      },
      {
        tooltip: t('dialogs.addNewExam.certificate'),
        icon: 'mdi:eye-circle',
        onClick: () => {
          window.open(userCertificate?.downloadPath!, '_target');
        },
        permission: !!userCertificate,
      },
    ].filter((option) => (option!?.permission === undefined ? option : option!?.permission));
  };

  const columns: AppTablePropsType<SchoolStudentsQuery['students'][0]>['columns'] = [
    newTable
      ? {
          name: 'firstName',
          PreviewComponent: (data, selectRow) => {
            return (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={!!hasUserBeenAttended(data) || table.selected.includes(data.id)}
                  disabled={!!hasUserBeenAttended(data)}
                  onClick={() => selectRow(data, table)}
                />
              </TableCell>
            );
          },
        }
      : null,
    {
      name: 'firstName',
    },
    {
      name: 'lastName',
    },
    {
      name: 'email',
    },
    {
      name: 'createdAt',
      PreviewComponent: (data) => moment(data.createdAt).format('YYYY-MM-DD'),
    },
    {
      name: 'createdAt',
      PreviewComponent: (data) => <RowActions options={generateMenuOption(data)} />,
    },
  ];

  const filters = [{}];

  return {
    t,
    table: table || newTable,
    isLoading,
    isCreatingCert,
    columns: columns.filter((col) => col),
    selectedRow,
    addToPlaylistDialog,
    changeStatusDialog,
    openFilters,
    filters,
    handleSearch,
    setQuery,
    query,
    methods,
    students,
    handleExportCert: () => handleExportCert(selectedRow!),
    confirm,
  };
};

export default useStudents;
