import React from 'react';
import { useParams } from 'react-router';
import { Stack, Typography } from '@mui/material';
import { useLocales } from 'src/locales';
import Sessions from 'src/pages/dashboard/sessions/table/Sessions';

const UserSessions = () => {
  const { userId } = useParams();
  const { t } = useLocales();

  const noSessionsView = (
    <Stack spacing={2} textAlign="center">
      <Typography variant="h5" fontWeight={600}>
        {t('userDetails.sessions.noSessions.title')}
      </Typography>
      <Typography variant="h6" fontWeight={300}>
        {t('userDetails.sessions.noSessions.subtitle')}
      </Typography>
    </Stack>
  );

  return <Sessions userId={userId} noSessionsView={noSessionsView} />;
};

export default UserSessions;
