import { useTheme } from '@mui/material';
import { groupBy } from 'lodash';
import React from 'react';
import { useCertificatesQuery } from 'src/graphql';
import { useLocales } from 'src/locales';
import BarChart from 'src/sections/charts/BarChart';

const CertificatesStatistics = () => {
  const { data } = useCertificatesQuery({ limit: null });
  const theme = useTheme();
  const { t } = useLocales();
  const groups = groupBy(data?.certificates, ({ createdAt }) => createdAt.replace(/T.+/, ''));

  return (
    <BarChart
      title={t('statistics.certificates.givenCertificatesByDate')}
      chart={{
        colors: [theme.palette.primary.main, theme.palette.warning.main],
        series: [
          {
            type: 'certificates',
            data: [
              {
                data: Object.values(groups).map((group) => +group.length),
                name: 'certificates',
              },
            ],
          },
        ],
        categories: Object.keys(groups),
      }}
    />
  );
};

export default CertificatesStatistics;
