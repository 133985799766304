export const API_URLS = {
  UPLOAD_REF_BOOK: (bookId: string) => `/upload/reference/book/${bookId}`,
  UPLOAD_BADGE_IMAGE: (badgeId: string) => `/upload/badge/upload-image/${badgeId}`,
  UPLOAD_SOUND: `/upload/sound/file`,
  UPLOAD_KNOWLEDGE_BASE_COVER: (knowledgeBaseId: string) =>
    `/upload/knowledgeBase/cover/${knowledgeBaseId}`,
  UPLOAD_CERTIFICATE: (id: string) => `/upload/cert/upload-image/${id}`,
};

// Define a type that represents the response type of each endpoint
export type ResDataType = {};
