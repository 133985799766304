// @mui
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import AppPermissions from 'src/constants/app-permissions';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { ExamDtoType } from 'src/types/exams';
import { QuestionsDtoType } from 'src/types/questions';

// ----------------------------------------------------------------------

type Props = {
  row: QuestionsDtoType;
  deleteHandler: (levelId: string) => void;
};

export default function QuestionsRow({ row, deleteHandler }: Props) {
  const { t, currentLang } = useLocales();
  const { themeMode } = useSettingsContext();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.questionText!?.[currentLang.value]}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.mutoon_section?.mutoon?.name!?.[currentLang.value]}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.mutoon_section?.name!?.[currentLang.value]}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.choicesOptions || '-'}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.choicesType ? t(`questionsChoicesType.${row.choicesType as 'count_errors'}`) : '-'}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <AppPermissionGate permissionsName={[AppPermissions.DELETE_QUESTIONS]}>
            <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.questionId)}>
                <Iconify
                  icon="material-symbols:delete-outline"
                  width="27"
                  height="27"
                  color="#DDB6FF"
                />
              </IconButton>
            </Tooltip>
          </AppPermissionGate>

          <AppPermissionGate permissionsName={[AppPermissions.EDIT_QUESTIONS]}>
            <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
              <IconButton LinkComponent={RouterLink}>
                <Link to={row.questionId!} state={row}>
                  <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
                </Link>
              </IconButton>
            </Tooltip>
          </AppPermissionGate>
        </TableCell>
      </TableRow>
    </>
  );
}
