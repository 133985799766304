export const EXAMS_STATUS = (t: any) => [
  {
    label: t('examsStatus.active'),
    value: 'active',
  },
  {
    label: t('examsStatus.inactive'),
    value: 'inactive',
  },
];
