import Box from '@mui/material/Box';

import Carousel, { CarouselArrows } from '..';
import useCarousel from '../use-carousel';
import { CarouselDots } from 'src/components/carousel';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useLocales } from 'src/locales';
import { createReadStream } from 'fs';
// ----------------------------------------------------------------------

type Props<T extends { id: string }> = {
  data: T[];
  CarouselItem: (props: T) => JSX.Element;
};

export default function CarouselCenterMode<T extends { id: string }>({
  data,
  CarouselItem,
}: Props<T>) {
  const { currentLang } = useLocales();
  const isArabic = currentLang.value === 'ar';
  const renderArr = isArabic ? [...data].reverse() : data;

  const carousel = useCarousel({
    slidesToShow: 2,
    rtl: currentLang.value === 'ar',
    ...CarouselDots({
      rounded: true,
      sx: { mt: 1 },
    }),
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, centerPadding: '20px' },
      },
    ],
  });

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <CarouselArrows
        filled
        icon="noto:rightwards-hand"
        onNext={isArabic ? carousel.onPrev : carousel.onNext}
        onPrev={isArabic ? carousel.onNext : carousel.onPrev}
      >
        <Carousel
          ref={carousel.carouselRef}
          {...carousel.carouselSettings}
          className="slider-container"
        >
          {renderArr.map((item) => (
            <Box key={item.id} sx={{ px: 1, display: 'flex', height: '100%' }}>
              {CarouselItem(item)}
            </Box>
          ))}
        </Carousel>
      </CarouselArrows>
    </Box>
  );
}
