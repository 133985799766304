import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import {
  useTable
} from 'src/components/table';
import { Order_By, useDeleteUserMutation, useUsersQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

const useUsers = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const [deleteId, setDeleteId] = useState<string>();
  const dialog = useBoolean();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
  }>({});

  // #region Services
  const { data, isLoading } = useUsersQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    order_by:
      methods.watch().sortBy && methods.watch().sortDirection
        ? orderBy(methods.watch().sortBy!, methods.watch().sortDirection as any)
        : undefined,
    where: query.search
      ? {
          _or: [
            { phoneNumber: { _ilike: `%${query.search}%` } },
            { lastName: { _ilike: `%${query.search}%` } },
            { firstName: { _ilike: `%${query.search}%` } },
            {
              _or: [
                { firstName: query.search ? { _ilike: `%${query.search}%` } : {} },
                { lastName: query.search ? { _ilike: `%${query.search}%` } : {} },
              ],
            },
          ],
        }
      : {},
  });

  const { mutate, isLoading: isDeleting } = useDeleteUserMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['Users'] });
      dialog.onFalse();
    },
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteUser = (id: string) => {
    setDeleteId(id);
    dialog.onTrue();
  };
  // #endregion Handlers

  return {
    isDeleting,
    methods,
    handleSearch,
    query,
    setQuery,
    isLoading,
    table,
    data,
    handleDeleteUser,
    deleteId,
    dialog,
    mutate,
    t
  };
};

export default useUsers;
