import { Card, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { useLocales } from 'src/locales';
import { AppTablePropsType } from './app-table';

const TableTabs = ({
  handleChangeTab,
  tabs,
}: {
  handleChangeTab: AppTablePropsType<any>['changeTabsHandler'];
  tabs: AppTablePropsType<any>['tabs'];
}) => {
  const [currentTabValue, setCurrentTabValue] = useState<string | null>(tabs?.currentTabValue!);

  const changeHandler = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    setCurrentTabValue(value);
    handleChangeTab && handleChangeTab(event, value);
  };

  return (
      <Tabs
        value={currentTabValue}
        onChange={changeHandler}
        sx={{
          px: 2,
          padding: 0.75,
          borderRadius: 0,
          '.MuiTabs-indicator': {
            display: 'none',
          },
          boxShadow: 'none',
          border: 'none',
        }}
      >
        {tabs!?.tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            sx={(theme) => {
              return {
                color:
                  tab.value === currentTabValue
                    ? theme.palette.primary.darker
                    : theme.palette.background.neutral,
                // backgroundColor:
                //   tab.value === currentTabValue ? 'primary.dark' : 'background.neutral',
                borderBottom: `1px solid ${
                  tab.value === currentTabValue
                    ? theme.palette.primary.darker
                    : theme.palette.background.neutral
                }`,
                boxShadow: 'none',
                fontWeight: 500,
                width: 125,
              };
            }}
          />
        ))}
      </Tabs>
  );
};

export default TableTabs;
