import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { BADGES_TABLE_HEAD } from 'src/constants/tables-heads';
import { Order_By } from 'src/graphql';
import { paths } from 'src/routes/paths';
import BadgeRow from './badge-row';
import useBadges from './use-badges';

export type BadgesFormType = {
  sortBy: string | undefined;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

const Badges = ({ isGeneral }: { isGeneral: boolean }) => {
  const {
    data,
    handleDeleteBadge,
    handleSearch,
    isDeleting,
    isLoading,
    methods,
    query,
    setQuery,
    table,
    t,
  } = useBadges({ isGeneral });

  return (
    <Container>
      <AppLoadingAndErrorWrapper isLoading={isDeleting} errorMessage={null}>
        <Card>
          <FormProvider methods={methods}>
            <AppTableToolbar
              handleSearch={handleSearch}
              search={query.search!}
              tableName=""
              buttons={[
                {
                  path: isGeneral
                    ? paths.dashboard.contentManagement.badges.new.general
                    : paths.dashboard.contentManagement.badges.new.custom,
                  permissionName: AppPermissions.BADGES_CREATE,
                },
              ]}
            />
            <TableContainer>
              <Scrollbar>
                <Table>
                  <TableHeadCustom
                    setQuery={setQuery}
                    headLabel={BADGES_TABLE_HEAD(t)}
                    methods={methods}
                  />
                  <TableBody>
                    {isLoading || isDeleting ? (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: 80 }} />
                      ))
                    ) : (
                      <>
                        {data?.badges?.map((row) => (
                          <BadgeRow key={row.badgeId} row={row} deleteHandler={handleDeleteBadge} />
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            {data && (
              <TablePaginationCustom
                count={+data.badges_aggregate.aggregate?.count!}
                page={+table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              />
            )}
          </FormProvider>
        </Card>
      </AppLoadingAndErrorWrapper>
    </Container>
  );
};

export default Badges;
