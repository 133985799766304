import React from 'react';
import FAQs from '../../faqs/table/FAQs';
import { useLocales } from 'src/locales';

const FaqsAnalysis = () => {
  const { t } = useLocales();
  return <FAQs title={t('statistics.faqs.title')} />;
};

export default FaqsAnalysis;
