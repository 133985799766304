// @mui
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
// components
import Iconify from 'src/components/iconify';
import PreviewAudio from 'src/components/upload/PreviewAudio';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import EditSoundDialog from 'src/sections/dialogs/edit/EditSoundDialog';
import { SoundDtoType } from 'src/types/sounds';

// ----------------------------------------------------------------------

type Props = {
  row: SoundDtoType;
  deleteHandler: (categoryId: string) => void;
};

export default function MatnSectionSoundsRow({ row, deleteHandler }: Props) {
  const { t, currentLang } = useLocales();
  const editSound = useBoolean();
  const soundName =
    row.name[currentLang.value] === undefined ? row.name : row.name!?.[currentLang.value];

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{soundName}</TableCell>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          {row.description!?.[currentLang.value]}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'wrap', textAlign: 'center' }}>
          <PreviewAudio src={row.fileUrl} />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
            <IconButton onClick={editSound.onTrue}>
              <Iconify icon="fluent-mdl2:page-edit" width="27" height="27" color="#FFAB8B" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
            <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.soundId)}>
              <Iconify
                icon="material-symbols:delete-outline"
                width="27"
                height="27"
                color="#DDB6FF"
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <EditSoundDialog
        sound={Object.assign(row, { description: row.description || '' })}
        open={editSound.value}
        onClose={editSound.onFalse}
      />
    </>
  );
}
