import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Block } from 'src/components/Block';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import NewAppButton from 'src/components/common/NewAppButton';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Scrollbar from 'src/components/scrollbar';
import { TableSkeleton } from 'src/components/table';
import {
  useActionsQuery,
  useCreateRoleActionMutation,
  useDeleteRoleActionMutation,
  useEditRoleMutation,
  useRoleActionsQuery,
  useRoleByIdQuery,
} from 'src/graphql';
import { useLocales } from 'src/locales';
import { useParams, useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { IEditRoleDto } from 'src/types/roles';
import { arrAssemblyWithCheck, arrCompared } from 'src/utils/rolesFunHelper';

export default function EditRoleForm() {
  const router = useRouter();
  const { roleId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { AddRoleSchema } = useSchemas();

  const { data: role, isLoading: isLoadingGetRole } = useRoleByIdQuery({
    id: roleId,
  });

  const { data: permissions, isLoading: isLoadingPermissions } = useActionsQuery();

  const { data: rolePermissions, isLoading: isLoadingGetRolePermissions } = useRoleActionsQuery({
    _eq: roleId,
  });

  const { mutate: editRole, isLoading: isLoadingEditRole } = useEditRoleMutation({
    onSuccess: () => {
      router.push(paths.dashboard.roles.root);
    },
  });

  const { mutate: addPermission, isLoading: isLoadingAddPermission } = useCreateRoleActionMutation({
    onSuccess: () => {
      queryClient.refetchQueries(['RoleActions']);
      queryClient.refetchQueries(['Actions']);
    },
  });

  const { mutate: deletePermission, isLoading: isLoadingDeletePermission } =
    useDeleteRoleActionMutation({
      onSuccess: () => {
        queryClient.refetchQueries(['Actions']);
        queryClient.refetchQueries(['RoleActions']);
      },
    });

  // Compare permissions with role permissions arrays
  const permissionsResult = arrCompared(
    permissions?.actions || [],
    (rolePermissions?.roles_actions || []).map((item) => ({ ...item.action }))
  );

  // For display permissions in table shape
  const permissionsGroup = arrAssemblyWithCheck(permissionsResult ?? []);

  // For handling checkboxes values and add it to array
  const handlePermissions = (event: React.ChangeEvent<HTMLInputElement>, permissionId: string) => {
    const checked = event.target.checked;

    if (checked) {
      addPermission({ roleId: roleId, actionId: permissionId });
    } else {
      deletePermission({ roleId: roleId, actionId: permissionId });
    }
  };

  const methods = useForm<IEditRoleDto>({
    resolver: yupResolver(AddRoleSchema),
  });

  const onSubmit = useCallback(async (data: IEditRoleDto) => {
    editRole(data);
  }, []);

  // For set default values
  useEffect(() => {
    if (!isLoadingGetRole && role?.roles_by_pk) {
      const defaultValues: IEditRoleDto = {
        id: role?.roles_by_pk?.id,
        name: role?.roles_by_pk?.name,
      };
      methods.reset(defaultValues);
    }
  }, [role, methods]);

  return (
    <AppLoadingAndErrorWrapper
      sx={{ height: 'auto' }}
      errorMessage={null}
      isLoading={isLoadingGetRole || isLoadingPermissions || isLoadingGetRolePermissions}
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container rowSpacing={3} alignItems="center">
          <Block label={t('dialogs.addNewRole.roleName')}>
            <RHFTextField name="name" label={t('dialogs.addNewRole.enterRoleName')} />
          </Block>
          {/* Permissions */}
          <Grid item xs={12} mt={4}>
            <Typography variant="h4" color="primary">
              {t('dialogs.addNewRole.permissions')}
            </Typography>
          </Grid>
          <Grid item container>
            <TableContainer>
              <Scrollbar>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        border: '1px solid rgba(60, 60, 66, 0.30)',
                      }}
                    >
                      <TableCell>{t('dialogs.addNewRole.actionsTable.systemCriteria')}</TableCell>
                      <TableCell align="center">
                        {t('dialogs.addNewRole.actionsTable.view')}
                      </TableCell>
                      <TableCell align="center">
                        {t('dialogs.addNewRole.actionsTable.create')}
                      </TableCell>
                      <TableCell align="center">
                        {t('dialogs.addNewRole.actionsTable.edit')}
                      </TableCell>
                      <TableCell align="center">
                        {t('dialogs.addNewRole.actionsTable.delete')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingDeletePermission || isLoadingAddPermission
                      ? [...Array(rolePermissions?.roles_actions?.length)].map((i, index) => (
                          <TableSkeleton key={index} sx={{ height: 80 }} />
                        ))
                      : permissionsGroup?.map((item, index) => {
                          return (
                            <TableRow
                              key={item.systemCriteria}
                              sx={{
                                border: '1px solid rgba(60, 60, 66, 0.30)',
                                '&:last-of-type': {
                                  '& .MuiTableCell-root': {
                                    borderColor: 'rgba(60, 60, 66, 0.30)',
                                  },
                                },
                              }}
                            >
                              <TableCell component="caption" scope="row">
                                {t(`dialogs.addNewRole.actionsTable.rows.${item?.systemCriteria}` as any)}
                              </TableCell>
                              {item?.permissions?.map((permission, permissionsIndex) => (
                                <TableCell
                                  key={permissionsIndex}
                                  align="center"
                                  sx={{
                                    border: '1px solid rgba(60, 60, 66, 0.30)',
                                  }}
                                >
                                  <Checkbox
                                    color="primary"
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                    checked={permission?.isExist}
                                    onChange={(event) => handlePermissions(event, permission?.id)}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <NewAppButton
              label={t('buttons.edit')}
              type="submit"
              color="primary"
              isLoading={isLoadingEditRole}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </AppLoadingAndErrorWrapper>
  );
}
