import { Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import Iconify from '../iconify/iconify';
import AppPermissionGate from '../common/AppPermissionGate';

const ITEM_HEIGHT = 48;

export type RowActionsPropsType = {
  options: {
    tooltip: string;
    onClick: () => void;
    icon: string;
    iconColor?: string;
    permissions?: string[];
  }[];
};

export default function RowActions({ options }: RowActionsPropsType) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      {options.map((option) => {
        const icon = (
          <Tooltip title={option.tooltip}>
            <IconButton onClick={option.onClick}>
              <Iconify
                icon={option.icon}
                color={option.iconColor || 'primary.main'}
                sx={{ borderRadius: 1, p: 0.4 }}
                width={27}
                height={27}
              />
            </IconButton>
          </Tooltip>
        );
        return (
          option.icon &&
          (option.permissions ? (
            <AppPermissionGate permissionsName={option.permissions}>{icon}</AppPermissionGate>
          ) : (
            icon
          ))
        );
      })}
    </Stack>
  );
}
