import { Box, Button, Card, Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify/iconify';
import { Upload } from 'src/components/upload';
import AudioPreview from 'src/components/upload/PreviewAudio';
import { useBoolean } from 'src/hooks/use-boolean';
import DeleteDialog from 'src/sections/dialogs/delete/DeleteDialog';
import EditSoundDialog from 'src/sections/dialogs/edit/EditSoundDialog';
import { EditSoundMutationVariablesType } from 'src/types/edit-sound';
import { AddMatnSectionSoundsPropsType } from 'src/types/matn-section-sections';

const AddMatnSectionSounds = ({
  currentTabValue,
  handleChangeTab,
  t,
  soundForm,
  handleDropSingleFile,
  soundsErrors,
  handleAddNewSound,
  handleDeleteSound,
  addSecSounds,
  mutateDelete,
  deleteSoundId,
  confirmDeleteSound,
  isDeletingSound,
}: AddMatnSectionSoundsPropsType) => {
  const editSound = useBoolean();
  const [sound, setSound] = useState<EditSoundMutationVariablesType>();
  const handleEditSound = (sound: EditSoundMutationVariablesType) => {
    setSound(sound);
    editSound.onTrue();
  };

  return (
    <>
      <Card sx={{ borderRadius: 20 }}>
        <Button
          onClick={addSecSounds.onToggle}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            p: { xs: 2, md: 3 },
            borderRadius: 20,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Iconify
              icon={!addSecSounds.value ? 'simple-line-icons:plus' : 'simple-line-icons:minus'}
              width={32}
            />
            <Typography textAlign="center" fontWeight={500} fontSize={18}>
              {t('dialogs.addNewMatnSection.addSounds')}
            </Typography>
          </Box>
        </Button>
      </Card>
      {addSecSounds.value && (
        <Stack spacing={2}>
          <TranslationTabs
            handleChangeTab={handleChangeTab}
            currentTabValue={currentTabValue}
            errors={soundsErrors}
          />
          <Stack spacing={2} sx={{ justifyContent: 'end' }}>
            <FormProvider methods={soundForm}>
              <Stack spacing={2}>
                {soundForm.watch().sounds.map((sound, index) => {
                  return (
                    <Card
                      sx={{
                        p: 3,
                      }}
                      key={sound.soundId}
                    >
                      <Grid
                        container
                        key={index}
                        rowSpacing={2}
                        columnSpacing={{
                          xs: 0,
                          md: 1,
                        }}
                      >
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row">
                              <IconButton
                                color="error"
                                onClick={() => handleDeleteSound(index, sound.soundId!)}
                              >
                                <Iconify width={24} icon="material-symbols:delete-outline" />
                              </IconButton>
                              {sound.soundId && (
                                <IconButton
                                  color="warning"
                                  onClick={() =>
                                    handleEditSound(
                                      Object.assign(sound, { soundId: sound.soundId || '' })
                                    )
                                  }
                                >
                                  <Iconify width={24} icon="tabler:edit" />
                                </IconButton>
                              )}
                            </Stack>
                            <Chip label={index + 1} />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Block label={t('dialogs.addNewSound.name')}>
                            <RHFTextField
                              disabled={!!sound.soundId}
                              name={`sounds.${[index]}.name.${currentTabValue}`}
                            />
                          </Block>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Block label={t('dialogs.addNewSound.description')}>
                            <RHFTextField
                              disabled={!!sound.soundId}
                              name={`sounds.${[index]}.description.${currentTabValue}`}
                            />
                          </Block>
                        </Grid>
                        <Grid item xs={12}>
                          <Upload
                            file={soundForm.watch().sounds[index].fileUrl}
                            disabled={!!sound.soundId}
                            onDrop={(e: any) => handleDropSingleFile(e, index)}
                            icon="solar:soundwave-bold"
                            uploadLabel={t('dialogs.addNewMatnSection.uploadSound')}
                            onDelete={() => {
                              soundForm.setValue(`sounds.${index}.fileUrl`, '');
                            }}
                            iframeStyle={{
                              height: '100%',
                            }}
                            previewComponent={
                              <AudioPreview
                                src={
                                  soundForm.watch().sounds[index].fileUrl.preview ||
                                  soundForm.watch().sounds[index].fileUrl
                                }
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </Stack>
            </FormProvider>
            <AppButton
              label={t('dialogs.addNewMatnSection.addAnotherSound')}
              size="small"
              onClick={handleAddNewSound}
              startIcon={<Iconify icon="eva:plus-fill" />}
              variant="outlined"
              fullWidth
            />
          </Stack>
        </Stack>
      )}
      <EditSoundDialog sound={sound!} open={editSound.value} onClose={editSound.onFalse} />
      <DeleteDialog
        mutate={mutateDelete}
        id={deleteSoundId}
        idWord="soundId"
        open={confirmDeleteSound.value}
        onClose={confirmDeleteSound.onFalse}
        isDeleting={isDeletingSound}
      />
    </>
  );
};

export default AddMatnSectionSounds;
