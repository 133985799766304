import React from 'react';

const Mutoons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8 2.5H16C17.6547 2.5 18.7543 2.97026 19.4461 3.71523C20.1441 4.4669 20.5 5.57665 20.5 7V17C20.5 18.4234 20.1441 19.5331 19.4461 20.2848C18.7543 21.0297 17.6547 21.5 16 21.5H8C6.3453 21.5 5.24565 21.0297 4.5539 20.2848C3.85591 19.5331 3.5 18.4234 3.5 17V7C3.5 5.57665 3.85591 4.4669 4.5539 3.71523C5.24565 2.97026 6.3453 2.5 8 2.5ZM12 11.75H8C7.31386 11.75 6.75 12.3139 6.75 13C6.75 13.6861 7.31386 14.25 8 14.25H12C12.6861 14.25 13.25 13.6861 13.25 13C13.25 12.3139 12.6861 11.75 12 11.75ZM8 18.25H16C16.6861 18.25 17.25 17.6861 17.25 17C17.25 16.3139 16.6861 15.75 16 15.75H8C7.31386 15.75 6.75 16.3139 6.75 17C6.75 17.6861 7.31386 18.25 8 18.25ZM16.5 9.75H18.5C19.1861 9.75 19.75 9.18614 19.75 8.5C19.75 7.81386 19.1861 7.25 18.5 7.25H16.5C16.0861 7.25 15.75 6.91386 15.75 6.5V4.5C15.75 3.81386 15.1861 3.25 14.5 3.25C13.8139 3.25 13.25 3.81386 13.25 4.5V6.5C13.25 8.29614 14.7039 9.75 16.5 9.75Z"
        fill="#2B3F4B"
        stroke="#2B3F4B"
      />
    </svg>
  );
};

export default Mutoons;
