import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { QUESTIONS_TABLE_HEAD } from 'src/constants/tables-heads';
import QuestionsRow from './questions-row';
import useQuestions from './hooks/use-questions';

const Questions = () => {
  const {
    methods,
    handleSearch,
    openFilters,
    isLoading,
    isDeleting,
    table,
    data,
    handleDeleteQuestion,
    t,
    filters,
    query,
    setQuery,
  } = useQuestions();

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.mutoon.questionsBank')}</title>
      </Helmet>
      <Card>
        <FormProvider methods={methods}>
          <AppTableToolbar
            handleSearch={handleSearch}
            search={query.search!}
            setQuery={setQuery}
            reset={() => methods.reset()}
            tableName=""
            buttons={[
              {
                path: 'new',
                permissionName: AppPermissions['CREATE_QUESTIONS'],
              },
            ]}
            openFilters={openFilters}
            filters={filters}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  setQuery={setQuery}
                  headLabel={QUESTIONS_TABLE_HEAD(t)}
                  methods={methods}
                />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.questions?.map((row) => (
                        <QuestionsRow
                          key={row.questionId}
                          row={row}
                          deleteHandler={handleDeleteQuestion}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.questions_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
      </Card>
    </Container>
  );
};

export default Questions;
