import { IconProps, SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useRouter } from 'src/routes/hooks';

interface IAppButton {
  label: string | undefined;
  onClick?: () => void;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: SxProps;
  startIcon?: React.ReactElement<IconProps>;
  fullWidth?: boolean;
  href?: string;
  size?: 'large' | 'medium' | 'small';
}

const AppButton: React.FC<IAppButton> = (props) => {
  const {
    label,
    onClick,
    variant = 'contained',
    color = 'primary',
    sx,
    startIcon,
    fullWidth = false,
    size,
    href,
  } = props;

  const router = useRouter();

  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      startIcon={startIcon}
      size={size || 'medium'}
      sx={{
        fontWeight: '700px',
        fontSize: { md: '16px', xs: '12px' },
        padding: { md: '16px 28px', xs: '10px' },
        borderRadius: '70px',
        lineHeight: '24px',
        textTransform: 'capitalize',
        ...sx,
      }}
      onClick={() => {
        onClick && onClick();
        href && router.push(href);
      }}
    >
      {label}
    </Button>
  );
};

export default AppButton;
