import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Card, CardContent, Container, Grid, MenuItem } from '@mui/material';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import TranslationTabs from 'src/components/common/TranslationTabs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { RHFSelect } from 'src/components/hook-form/rhf-select';
import Image from 'src/components/image/image';
import { Upload } from 'src/components/upload';
import { BADGES_GENERAL_TYPES } from 'src/constants/badges-types';
import { useLocales } from 'src/locales';
import useAddNewGeneralBadge from './use-add-new-general-badge';
import { Helmet } from 'react-helmet-async';

const AddGeneralNewBadge = () => {
  const {
    isLoadingBadge,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    mutate,
    handleChangeTab,
    langsWithErrors,
    file,
    setFile,
    handleDropSingleFile,
    isUploadingBadgeImg,
    badgeId,
    handleDeleteBadgeImg,
  } = useAddNewGeneralBadge();
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{t('titles.addBadge')}</title>
      </Helmet>

      <Container>
        <AppLoadingAndErrorWrapper
          isLoading={isLoadingBadge}
          errorMessage={null}
          sx={{ height: 'auto' }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item container xs={12}>
                <Grid item xs={12} display="flex" gap={4} flexDirection="column">
                  <TranslationTabs
                    currentTabValue={currentTabValue}
                    handleChangeTab={handleChangeTab}
                    errors={langsWithErrors}
                  />
                  <Block label={t('tables.badges.headLabels.badgeType')}>
                    <RHFSelect name="generalType">
                      {BADGES_GENERAL_TYPES(t).map((badge) => (
                        <MenuItem value={badge.value}>{badge.label}</MenuItem>
                      ))}
                    </RHFSelect>
                  </Block>

                  <Block label={t('tables.badges.headLabels.badgeName')}>
                    <RHFTextField name={`programName.${currentTabValue}`} />
                  </Block>

                  <Block label={t('tables.badges.headLabels.count')}>
                    <RHFTextField name={`count`} type="number" defaultValue={0} />
                  </Block>

                  <Block label={t('tables.badges.headLabels.badgeImage')}>
                    <Card sx={{ mt: 1 }}>
                      <CardContent>
                        <AppLoadingAndErrorWrapper
                          sx={{ height: 'auto' }}
                          isLoading={isUploadingBadgeImg}
                          errorMessage={null}
                        >
                          <Upload
                            file={file}
                            onDrop={handleDropSingleFile}
                            fileFieldName="badgeImageURL"
                            error={!!methods.formState.errors.badgeImageURL}
                            uploadLabel={t('badges.uploadImage')}
                            onDelete={() => {
                              setFile(null);
                              methods.setValue('badgeImageURL', '');
                              handleDeleteBadgeImg(badgeId!);
                            }}
                            previewComponent={<Image src={methods.watch().badgeImageURL!} />}
                          />
                        </AppLoadingAndErrorWrapper>
                      </CardContent>
                    </Card>
                  </Block>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              ></Grid>
              <Grid item xs={12}>
                <AppLoadingButton
                  label={isEditing ? t('buttons.edit') : t('buttons.add')}
                  isLoading={false}
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={mutate}
            />
          </>
        }
      />
    </>
  );
};

export default AddGeneralNewBadge;
