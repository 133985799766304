import { useContext, createContext } from 'react';

import { AddQuestionsContextProps } from 'src/types/questions';

// ----------------------------------------------------------------------

export const AddQuestionsContext = createContext({} as AddQuestionsContextProps);

export const useAddQuestionsContext = () => {
  const context = useContext(AddQuestionsContext);

  if (!context) throw new Error('useAddQuestionsContext must be use inside SettingsProvider');

  return context;
};
