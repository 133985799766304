import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://47b3904ba9989d9b723656defaaa0290@o4506388573257728.ingest.sentry.io/4506403219832832',
  environment: import.meta.env.VITE_SERVICE_BUILD_ENV,
  release: import.meta.env.VITE_SERVICE_VERSION,
  enabled:
    import.meta.env.VITE_SERVICE_BUILD_ENV === 'dev' ||
    import.meta.env.VITE_SERVICE_BUILD_ENV === 'staging' ||
    import.meta.env.VITE_SERVICE_BUILD_ENV === 'prod',

  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay(),
    new Sentry.Feedback({
      // Additional SDK configuration goes in here, for example:
      colorScheme: 'light',
    }),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.*eqrra\.com\//],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});
