import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const axiosApi: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_REST_HOST_API, // Base URL
});

axiosApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosApi.interceptors.response.use(
  (response: AxiosResponse) => {
    // Handle the successful response
    return response.data.data ? response.data.data : response.data;
  },
  (error: AxiosError) => {
    // If token is expired
    if (error.response?.status == 403) {
      window.location.reload();
    }
    // Handle the error response
    return Promise.reject(error.response?.data);
  }
);
