import { Grid, Typography } from '@mui/material';
import AppContainer from 'src/components/common/AppContainer';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import AddRoleForm from 'src/sections/roles/AddRoleForm';

export default function AddRolePage() {
  const { t } = useLocales();
  return (
    <AppContainer
      pageTitle={t('titles.addRole')}
      routeLinks={[
        { name: t('sidebar.roles'), href: paths.dashboard.roles.addRole },
        { name: t('titles.addRole') },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} mb={4}>
          <Typography variant="h4" color="primary">
            {t('dialogs.addNewRole.roleInfo')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AddRoleForm />
        </Grid>
      </Grid>
    </AppContainer>
  );
}
