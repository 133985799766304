import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import {
  AuthorizedUsersQuery,
  useAllCategoriesQuery,
  useApproveUserMutation,
  useAuthorizedUserByIdQuery,
  useRejectUserMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { TeacherRequestFormType } from 'src/types/teacher-request';

const useTeacherRequestDetails = () => {
  // #region States and hooks
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<TeacherRequestFormType>();
  const router = useRouter();
  const { t, currentLang } = useLocales();
  const { ChangeTeacherRequestSchema } = useSchemas();
  const { state }: { state: AuthorizedUsersQuery['authorized_users'][0] | undefined } =
    useLocation();
  console.log(state);
  const { teacherRequestId } = useParams();
  const [userData, setUserData] = useState<AuthorizedUsersQuery['authorized_users'][0] | undefined>(
    state!
  );

  const methods = useForm<TeacherRequestFormType>({
    defaultValues: {
      status: state!?.status,
      categoriesIds: state!?.user_categories.map((ctg) => ({
        value: ctg.categoryId,
        label: ctg.category.name[currentLang.value],
      })),
      adminMsg: state!?.adminMsg!,
    },
    resolver: yupResolver(ChangeTeacherRequestSchema),
  });
  // #endregion States and hooks

  console.log(methods.watch());

  // #region Services
  // Get users authorization requests
  const { isFetching } = useAuthorizedUserByIdQuery(
    { authorizedUserId: teacherRequestId },
    {
      enabled: !state,
      onSuccess: (data) => {
        methods.reset({
          status: data.authorized_users_by_pk?.status,
          categoriesIds: data.authorized_users_by_pk?.user_categories.map((ctg) => ({
            value: ctg.categoryId,
            label: ctg.category.name[currentLang.value],
          })),
          adminMsg: data.authorized_users_by_pk?.adminMsg!,
        });
        setUserData(data.authorized_users_by_pk!);
      },
    }
  );

  // Get mutoon categories
  const { data: categories, isLoading: isLoadingCategories } = useAllCategoriesQuery();

  // Reject request
  const { mutate: reject, isLoading: isRejecting } = useRejectUserMutation({
    onSuccess: () => {
      router.push(paths.dashboard.userAuthorization.teachersRequests);
    },
  });

  // Approve request
  const { mutate: approve, isLoading: isApproving } = useApproveUserMutation({
    onSuccess: () => {
      router.push(paths.dashboard.userAuthorization.teachersRequests);
    },
  });
  // #endregion Services

  // #region Handlers
  const onSubmit = useCallback(
    async (data: TeacherRequestFormType) => {
      const newData = { ...data };
      newData.categoriesIds = newData.categoriesIds!?.map((ctg) => ctg.value) as any;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const handleReject = () => {
    reject({
      authorizedUserId: teacherRequestId!,
      status: methods.watch().status,
      adminMsg: methods.watch().adminMsg,
    });
  };

  const handleApprove = () => {
    approve({
      authorizedUserId: teacherRequestId!,
      adminMsg: methods.watch().adminMsg,
      status: methods.watch().status,
      objects: methods
        .watch()
        .categoriesIds!?.filter(
          (category) =>
            !userData?.user_categories.find((userCtg) => userCtg.categoryId === category.value)
        )
        .map((ctg) => ({
          authorizedUserId: teacherRequestId!,
          categoryId: ctg.value,
          userId: userData?.userId,
        })),
    });
  };

  const handleChangeStatus = () => {
    methods.watch().status === 'approved'
      ? handleApprove()
      : methods.watch().status === 'rejected'
      ? handleReject()
      : alert('Change status please');
  };
  // #endregion Handlers

  // Data array
  const SHOW_DATA = [
    {
      label: t('tables.teachersRequests.headLabels.firstName'),
      value: userData!?.user.firstName,
    },
    {
      label: t('tables.teachersRequests.headLabels.lastName'),
      value: userData!?.user.lastName,
    },
    {
      label: t('tables.teachersRequests.headLabels.fieldOfStudy'),
      value: userData!?.fieldOfStudy,
    },
    {
      label: t('tables.teachersRequests.headLabels.createdAt'),
      value: moment(userData!?.createdAt).format('YYYY-MM-DD'),
    },
  ];

  return {
    methods,
    isLoading: isFetching,
    isLoadingCategories,
    onSubmit,
    SHOW_DATA,
    userData,
    categories,
    currentLang,
    confirm,
    t,
    handleChangeStatus,
    isRejecting,
    isApproving,
  };
};

export default useTeacherRequestDetails;
