import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { Block } from 'src/components/Block';
import TranslationTabs from 'src/components/common/TranslationTabs';
import Editor from 'src/components/editor/editor';
import { RHFTextField } from 'src/components/hook-form';
import { AddMatnSectionDetailsPropsType } from 'src/types/matn-section-sections';

const AddMatnSectionDetails = ({
  t,
  handleChangeTab,
  langsWithErrors,
  currentTabValue,
}: AddMatnSectionDetailsPropsType) => {
  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Typography variant="subtitle1">
          {t('dialogs.addNewMatnSection.matnSectionDetails')}
        </Typography>
        <Divider />
        <Grid container rowSpacing={2} columnSpacing={{ md: 2, xs: 0 }}>
          <Grid item xs={12}>
            <TranslationTabs
              handleChangeTab={handleChangeTab}
              currentTabValue={currentTabValue}
              errors={langsWithErrors}
            />
          </Grid>
          <Grid item xs={12}>
            <Block label={t('dialogs.addNewMatnSection.name')}>
              <RHFTextField name={`name.${currentTabValue}`} />
            </Block>
          </Grid>
          <Grid item xs={12}>
            <Block label={t('dialogs.addNewMatnSection.description')}>
              <RHFTextField name={`description.${currentTabValue}`} />
            </Block>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

export default AddMatnSectionDetails;
