// @mui
import TableRow from '@mui/material/TableRow';
// components
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import AppPermissionGate from 'src/components/common/AppPermissionGate';
import useLocales from 'src/locales/use-locales';
import { CertificateDto } from 'src/types/certificates';
// components
import moment from 'moment';
import Iconify from 'src/components/iconify';
import AppPermissions from 'src/constants/app-permissions';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

type Props = {
  row: CertificateDto;
  deleteHandler: (mutoonId: string) => void;
};

export default function CertificatesRow({ row, deleteHandler }: Props) {
  const { t } = useLocales();

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.title}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
        {moment(row.createdAt).format('YYYY-MM-DD')}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
        <AppPermissionGate permissionsName={[AppPermissions.CERTIFICATES_DELETE]}>
          <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
            <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.certificateId)}>
              <Iconify
                icon="material-symbols:delete-outline"
                width="27"
                height="27"
                color="#DDB6FF"
              />
            </IconButton>
          </Tooltip>
        </AppPermissionGate>

        <AppPermissionGate permissionsName={[AppPermissions.CERTIFICATES_VIEW]}>
          <Tooltip title={t('tableLabels.preview')} placement="bottom" arrow>
            <IconButton LinkComponent={RouterLink}>
              <Link to={row.image!} state={row} style={{ display: 'flex' }}>
                <Iconify icon="mdi:eye-circle" width="27" height="27" color="#FFAB8B" />
              </Link>
            </IconButton>
          </Tooltip>
        </AppPermissionGate>
      </TableCell>
    </TableRow>
  );
}
