import { Card, Stack, Typography } from '@mui/material';
import moment from 'moment';
import Chart from 'src/components/chart';
import useCouncilsStatistics from './use-councils-statitstics';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';

// startDate: { _gte: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') },
// expiryDate: { _lte: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') },

const CouncilsStatistics = () => {
  const { cardsToShow, chartOptionsCheckIn, data, t, isLoadingSessions, isLoadingTodaySessions } =
    useCouncilsStatistics();

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={2}>
        <Typography variant="h6">{t('statistics.hearingCouncils.title')}</Typography>
        <Typography>{moment().format('MMMM DD, YYYY')}</Typography>
        <AppLoadingAndErrorWrapper
          sx={{ height: 'auto' }}
          isLoading={isLoadingSessions || isLoadingTodaySessions}
        >
          <Stack direction="row" justifyContent="space-evenly">
            {data!?.hearing_councils_aggregate.aggregate?.count ? (
              cardsToShow.map((e) => (
                <Stack flexDirection="row" alignItems="center">
                  <Chart
                    type="radialBar"
                    options={chartOptionsCheckIn}
                    series={[
                      +(
                        (e?.data!?.length! / data!?.hearing_councils_aggregate.aggregate?.count!) *
                        100
                      ).toFixed(1),
                    ]}
                    width={106}
                    height={106}
                  />
                  <div>
                    <Typography variant="body2" sx={{ opacity: 0.72 }}>
                      {e.data?.length}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.72 }}>
                      {e.label}
                    </Typography>
                  </div>
                </Stack>
              ))
            ) : (
              <Typography>{t('statistics.hearingCouncils.noCouncilsToday')}</Typography>
            )}
          </Stack>
        </AppLoadingAndErrorWrapper>
      </Stack>
    </Card>
  );
};

export default CouncilsStatistics;
