import React, { useState } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { Order_By } from 'src/graphql';
import { useQueryParams } from 'use-query-params';

interface FormType {
  sortBy: string;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
}

const UseSearch = <T extends FormType>(filters?: DefaultValues<T>, searchKeyWord?: string) => {
  // Query
  const [query, setQuery] = useQueryParams<{ [key: string]: string }>({
    [searchKeyWord || 'search']: '',
    sortBy: '',
    sortDirection: Order_By.Asc,
  });
  // Search
  const [_, setSearch] = useState<string>(query.search!);
  // Methods
  const methods = useForm<T>({
    defaultValues: {
      sortBy: query.sortBy || '',
      sortDirection: query.sortDirection || Order_By.Asc,
      ...(filters && filters),
    } as DefaultValues<T>,
  });
  // Handle search
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setQuery({ [searchKeyWord || 'search']: e.target.value });
  };

  return {
    query,
    methods,
    handleSearch,
    setQuery,
  };
};

export default UseSearch;
