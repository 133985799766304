import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Grid } from '@mui/material';
import React from 'react';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider from 'src/components/hook-form';
import useAddNewMatnSection from '../../use-add-new-matn-section';
import AddMatnSectionDetails from '../sections/add-matn-section-details';
import AddSectionDiffWords from '../sections/add-matn-section-diff-words';
import AddMatnSectionSounds from '../sections/add-matn-section-sounds';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { paths } from 'src/routes/paths';
import { useLocation, useParams } from 'react-router';
import { MatnSectionDiffWordStateType } from 'src/types/matn-section-diff-words';

const AddNewMatnSection = () => {
  const {
    // Translations
    currentTabValue,
    handleChangeTab,
    allLangs,
    langsWithErrors,
    t,
    // Main form (details & definitions)
    methods,
    onSubmit,
    handleSubmit,
    isUpdatingSec,
    isAddingMatnSection,
    mutateSectionDetails,
    isEditing,
    // Sounds form
    soundForm,
    soundsErrors,
    handleDropSingleFile,
    // Words definitions
    addDiffWords,
    isAddingWords,
    isLoadingWordsDefs,
    deleteWordDefinition,
    isDeletingWord,
    handleAddNewWordDiff,
    handleDeleteWordDiff,
    confirmDeleteWord,
    // Sound variables
    soundDeleteId,
    addSecSounds,
    isLoadingSounds,
    isUploadingSound,
    deleteSound,
    isDeletingSound,
    handleAddNewSound,
    handleDeleteSound,
    confirmDeleteSound,
    // Others
    enqueueSnackbar,
    isLoadingSectionDetails,
    transDeleteId,
    confirm,
    currentLang,
    matnName,
    matnSectionName,
  } = useAddNewMatnSection();

  const { matnId } = useParams();

  return (
    <Container>
      <AppLoadingAndErrorWrapper
        isLoading={isLoadingSectionDetails || isLoadingSounds || isLoadingWordsDefs}
        errorMessage={null}
        sx={{ height: 'auto' }}
      >
        {/* <CustomBreadcrumbs
          links={[
            {
              name: t('breadcrumbs.mutoon'),
            },
            {
              name: t('breadcrumbs.categories'),
              href: paths.dashboard.mutoon.categories,
            },
            {
              name: categoryName,
              href: `${paths.dashboard.mutoon.categories}/${categoryId}`,
            },
            {
              name: levelName,
              href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}`,
            },
            {
              name: matnName,
              href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}/${matnId}`,
            },
            {
              name: matnSectionName,
            },
          ]}
        /> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={2}>
            {/* Matn section name and description */}
            <Grid item xs={12}>
              <AddMatnSectionDetails
                t={t}
                handleChangeTab={handleChangeTab}
                langsWithErrors={langsWithErrors}
                currentTabValue={currentTabValue}
              />
            </Grid>
            {/* Matn section difficult words */}

            <Grid item xs={12}>
              <AddSectionDiffWords
                t={t}
                methods={methods}
                allLangs={allLangs}
                addDiffWords={addDiffWords}
                handleAddNewWordDiff={handleAddNewWordDiff}
                handleDeleteWordDiff={handleDeleteWordDiff}
                mutateDelete={deleteWordDefinition}
                confirmDeleteWord={confirmDeleteWord}
                transDeleteId={transDeleteId!}
                isDeletingWord={isDeletingWord}
              />
            </Grid>
            {/* Matn section sounds */}

            <Grid item xs={12}>
              <AddMatnSectionSounds
                t={t}
                soundForm={soundForm}
                soundsErrors={soundsErrors}
                addSecSounds={addSecSounds}
                currentTabValue={currentTabValue}
                handleChangeTab={handleChangeTab}
                handleAddNewSound={handleAddNewSound}
                handleDeleteSound={handleDeleteSound}
                handleDropSingleFile={handleDropSingleFile}
                mutateDelete={deleteSound}
                deleteSoundId={soundDeleteId!}
                confirmDeleteSound={confirmDeleteSound}
                isDeletingSound={isDeletingSound}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppLoadingButton
                label={
                  isEditing ? t('buttons.editMatnSectionAndAddNewWordsAndSounds') : t('buttons.add')
                }
                isLoading={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAddingMatnSection || isUpdatingSec}
              onClick={mutateSectionDetails}
            />
          </>
        }
      />
    </Container>
  );
};

export default AddNewMatnSection;
