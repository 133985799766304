import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useTable } from 'src/components/table';
import { Order_By, useContactUsQuery, useDeleteContactMsgMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';
import { useQueryParams } from 'use-query-params';

const useContactUs = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const openFilter = useBoolean();

  const [filtersQuery] = useQueryParams({
    ['createdAt.value']: '',
    ['createdAt.operator']: '_gt',
    status: '',
  });

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>({
    status: filtersQuery.status!,
    createdAt: {
      operator: (filtersQuery['createdAt.operator'] as '_lt') || '_gt',
      value: filtersQuery['createdAt.value']
        ? moment(filtersQuery['createdAt.value']).format()
        : '',
    },
  });

  // #region Services
  const { data, isLoading } = useContactUsQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    where: {
      _or: [
        { message: query.search ? { _ilike: `%${query.search}%` } : undefined },
        { name: query.search ? { _ilike: `%${query.search}%` } : undefined },
        { email: query.search ? { _ilike: `%${query.search}%` } : undefined },
      ],
      status: methods.watch().status ? { _eq: methods.watch().status } : undefined,
      createdAt:
        methods.watch().createdAt.value && methods.watch().createdAt.operator
          ? { [methods.watch().createdAt.operator]: methods.watch().createdAt.value }
          : {},
    },
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
  });

  const { mutate, isLoading: isDeleting } = useDeleteContactMsgMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['ContactUs'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteContactMsg = (contactId: string) => {
    mutate({ contactId });
  };
  // #endregion Handlers

  return {
    isDeleting,
    t,
    methods,
    handleSearch,
    openFilter,
    setQuery,
    query,
    isLoading,
    table,
    data,
    handleDeleteContactMsg,
  };
};

export default useContactUs;
