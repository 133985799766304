// Radio group options
export const RADIO_OPTIONS = (t: any) => [
  {
    label: t('teacherRequestDetails.changeRequestStatus.approve'),
    value: 'approved',
  },
  {
    label: t('teacherRequestDetails.changeRequestStatus.reject'),
    value: 'rejected',
  },
  {
    label: t('teacherRequestDetails.changeRequestStatus.pending'),
    value: 'pending',
  },
];
