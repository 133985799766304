import { Card, Container, Stack, Tab, Tabs } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { BADGES_TABS } from 'src/constants/badges-types';
import { Order_By } from 'src/graphql';
import { useLocales } from 'src/locales';

export type BadgesFormType = {
  sortBy: string | undefined;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

const BadgesContainer = ({
  children,
  tabs,
}: {
  children: React.ReactNode;
  tabs?: (t: any) => {
    label: any;
    href: string;
    link: string;
  }[];
}) => {
  const currentTabFromUrl = window.location.href.slice(window.location.href.lastIndexOf('/') + 1);
  const [currentTabValue, setCurrentTabValue] = useState(currentTabFromUrl);
  const { t } = useLocales();
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const TABS = tabs || BADGES_TABS;

  useEffect(() => {
    setCurrentTabValue(window.location.href.slice(window.location.href.lastIndexOf('/') + 1));
  }, [window.location.href]);

  return (
    <Stack spacing={2}>
      <Helmet>
        <title>{t('sidebar.badges')}</title>
      </Helmet>
      <Container>
        <Card>
          <Tabs
            value={currentTabValue}
            onChange={handleChangeTab}
            sx={{
              backgroundColor: 'background.neutral',
              px: 2,
              padding: 0.75,
              borderRadius: 2,
              '.MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            {TABS(t)!?.map((tab) => (
              <Link
                to={tab.href}
                onClick={(event: any) => {
                  const matchHref = BADGES_TABS(t).filter(
                    (link) => link.label === event.target.innerHTML
                  )[0].link;
                  setCurrentTabValue(matchHref);
                }}
              >
                <Tab
                  key={tab.href}
                  value={tab.href}
                  label={tab.label}
                  sx={{
                    backgroundColor:
                      tab.link === currentTabValue ? 'background.default' : 'background.neutral',
                    borderRadius: 2,
                    fontWeight: 500,
                    px: 3,
                    py: 1,
                  }}
                />
              </Link>
            ))}
          </Tabs>
        </Card>
      </Container>
      {children}
    </Stack>
  );
};

export default BadgesContainer;
