import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { MATN_SECTIONS_TABLE_HEAD } from 'src/constants/tables-heads';
import { Order_By, useDeleteMatnSectionMutation, useMatnSectionsQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import AddNewMatnDialog from 'src/sections/dialogs/add/AddNewMatnDialog';
import { MatnSectionStateType } from 'src/types/matn-sections';
import { orderBy } from 'src/utils/order-by';
import MatnSectionsRow from './matn-sections-row';

export type MatnSectionsFormType = {
  sortBy: string;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

const MatnSections = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const addDialog = useBoolean();
  const { matnId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<MatnSectionsFormType>();

  // #region Services
  const { data, isLoading } = useMatnSectionsQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    mutoonMatnId: matnId,
    search: query.search ? { _ilike: `%${query.search}%` } : {},
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
  });

  const { mutate, isLoading: isDeleting } = useDeleteMatnSectionMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['MatnSections'] }),
  });

  // #endregion Services

  // #region Handlers
  const handleDeleteMatn = (mutoonSectionId: string) => {
    mutate({ mutoonSectionId });
  };
  // #endregion Handlers

  return (
    <Container>
      <Helmet>
        <title>{t('titles.mutoon')}</title>
      </Helmet>
      {/* <CustomBreadcrumbs
        links={[
          {
            name: t('breadcrumbs.mutoon'),
          },
          {
            name: t('breadcrumbs.categories'),
            href: paths.dashboard.mutoon.categories,
          },
          {
            name: categoryName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}`,
          },
          {
            name: levelName,
            href: `${paths.dashboard.mutoon.categories}/${categoryId}/${levelId}`,
          },
          {
            name: matnName,
          },
        ]}
      /> */}
      <Card>
        <FormProvider methods={methods}>
          <AppTableToolbar
            handleSearch={handleSearch}
            search={query.search!}
            setQuery={setQuery}
            tableName=""
            buttons={[
              {
                label: t('buttons.add'),
                path: 'new',
                permissionName: AppPermissions.CONTENT_CREATE,
              },
            ]}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  setQuery={setQuery}
                  methods={methods}
                  headLabel={MATN_SECTIONS_TABLE_HEAD(t)}
                />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.mutoon_sections?.map((row) => (
                        <MatnSectionsRow
                          key={row.mutoonSectionId}
                          row={row!}
                          deleteHandler={handleDeleteMatn}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.mutoon_sections_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
        <AddNewMatnDialog open={addDialog.value} onClose={addDialog.onFalse} />
      </Card>
    </Container>
  );
};

export default MatnSections;
