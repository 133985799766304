import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useJoinHearingCounciMutation, useSubscribeHearingCouncilMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { HearingCouncilDto } from 'src/types/hearing-council';
import { sessionButtonLabel } from 'src/utils/sessions/session-button-label';

const useHearingCouncilsCard = ({ hearingCouncil }: { hearingCouncil: HearingCouncilDto }) => {
  // #region States and hooks
  const queryClient = useQueryClient();
  const router = useNavigate();
  const qrDialog = useBoolean();
  const deleteDialog = useBoolean();
  const { currentLang, t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  let chipColor = (status: 'live' | 'expired' | 'pending'): 'success' | 'error' | 'warning' => {
    switch (status.toLowerCase()) {
      case 'live':
        return 'success';
      case 'expired':
        return 'error';
      default:
        return 'warning';
    }
  };

  const adminId = JSON.parse(localStorage.getItem('user')!)!?.id;
  // #region States and hooks

  // #region States check
  const isSubscribedToHearingCouncil =
    !!hearingCouncil.hearing_councils_user_hearing_councils.filter(
      (session) => session.admin?.id === adminId
    ).length;

  const isHearingCouncilExpired = moment(hearingCouncil.expiryDate).isBefore(moment());
  hearingCouncil.status = isHearingCouncilExpired ? 'Expired' : hearingCouncil.status;
  let isButtonEnabled: boolean = false;

  const canStartPendingSession = moment(hearingCouncil.startDate)
    .subtract('minutes', hearingCouncil.appointmentLimit)
    .isBefore(moment());

  if (hearingCouncil.status === 'Live' || canStartPendingSession) {
    isButtonEnabled = true;
  } else {
    isButtonEnabled = false;
  }
  // #endregion States check

  const buttonLabel = t(sessionButtonLabel(hearingCouncil.status! as any) as any);
  // #endregion States and hooks

  // #region Services
  const { mutate: join, isLoading: isJoining } = useJoinHearingCounciMutation({
    onSuccess: (data) =>
      router(hearingCouncil.hearingCouncilId, { state: data.joinHearingCouncil }),
  });

  const { mutate: subscribe, isLoading: isSubscribing } = useSubscribeHearingCouncilMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['HearingCouncils'] });
      enqueueSnackbar({
        variant: 'success',
        message: 'Subscribed successfully',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });
  // #endregion Services

  // #region useEffect
  // #endregion useEffect

  return {
    isButtonEnabled,
    isJoining,
    isSubscribing,
    isSubscribedToHearingCouncil,
    deleteDialog,
    qrDialog,
    buttonLabel,
    subscribe,
    t,
    currentLang,
    join,
    adminId,
    router,
    chipColor,
  };
};

export default useHearingCouncilsCard;
