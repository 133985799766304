import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Order_By,
  useAddQuestionsMutation,
  useAllMatnSectionsQuery,
  useDeleteQuestionMutation,
  useMutoonCategoriesQuery,
  useOneExamQuery,
  useOneQuestionsQuery,
  useQuestionsQuery,
  useUpdateQuestionsMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddQuestionMutationVariables, NewQuestionStateType } from 'src/types/questions';
import { AddQuestionsContext } from './questions-context';

const AddQuestionsProvider = ({ children }: { children: React.ReactNode }) => {
  // #region States and hooks
  const router = useNavigate();
  const [search, setSearch] = useState<string>();
  const { AddQuestionsSchema } = useSchemas();
  const queryClient = useQueryClient();
  const { questionsExamId, questionId } = useParams();
  const confirm = useBoolean();
  const confirmDeleteQuestion = useBoolean();
  const questionsBankDialog = useBoolean();
  const { t, allLangs, currentLang } = useLocales();
  const [questionDeleteId, setQuestionDeleteId] = useState<string>();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { state }: { state: NewQuestionStateType | undefined } = useLocation();
  const [dataToSend, setDataToSend] = useState<AddQuestionMutationVariables>();
  const LIMIT = 10;
  const [examTitle, setExamTitle] = useState<{ [key: string]: string }>({
    title: state?.exam?.title,
  });
  // If the module has been opened to edit a question or after adding a new exam, do not show a dropdown to choose the matn
  const matnId = state?.exam?.mutoon.id;
  const isInExamPage = !!questionsExamId;
  const isEditingQuestion = !!questionId;
  // #endregion States and hooks

  // #region Form
  const writtenQuestionDefaultValues = {
    correctAnswerText: state?.question!?.correctAnswerText || undefined,
  };

  const choicesQuestionDefaultValues = {
    correctAnswerIndex: state?.question?.correctAnswerIndex || 0,
    choicesType: state?.question!?.choicesType || '',
    choicesOptions: state?.question!?.choicesOptions! || [],
  };

  const defaultValuesToggler: (
    type: 'written' | 'choice'
  ) => AddQuestionMutationVariables['questions'][0] = (type: 'written' | 'choice') => {
    return {
      explanation: state?.question!?.explanation || {},
      hint: state?.question!?.hint || {},
      isQuiz: state?.question!?.isQuiz || questionsExamId ? false : true,
      mutoonSectionId: state?.question!?.mutoonSectionId || undefined,
      mutoonSectionName: state?.question!?.mutoon_section?.name!?.[currentLang.value] || '',
      points: state?.question!?.points || 1,
      // questionId: state?.question!?.questionId || '',
      questionText: state?.question!?.questionText || {},
      type: state?.question!?.type || 'written',
      examId: questionsExamId || undefined,
      questionId: state?.question?.questionId || undefined,
      ...(type === 'written' ? writtenQuestionDefaultValues : choicesQuestionDefaultValues),
    };
  };

  const defaultValues = defaultValuesToggler('written');

  for (let i = 0; i < allLangs.length; i++) {
    defaultValues.explanation && (defaultValues.explanation[allLangs[i].value] = '');
    defaultValues.correctAnswerText && (defaultValues.correctAnswerText[allLangs[i].value] = '');
    defaultValues.hint && (defaultValues.hint[allLangs[i].value] = '');
    defaultValues.questionText && (defaultValues.questionText[allLangs[i].value] = '');
  }

  const methods = useForm<AddQuestionMutationVariables>({
    defaultValues: {
      questions: [defaultValues],
      matnId: state?.exam?.mutoon!?.id || '',
      matnName: state?.exam?.mutoon!?.name!?.[currentLang.value] || '',
      pageValue: 1,
    },
    resolver: yupResolver<AddQuestionMutationVariables>(AddQuestionsSchema),
  });

  const {
    fields: questions,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control: methods.control,
    name: 'questions',
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddQuestionMutationVariables) => {
      const newData = { ...data };
      delete newData.matnId;
      delete newData.matnName;
      delete newData.pageValue;
      // Just send new questions in order not to duplicate
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(methods.formState.errors);
  // #endregion form

  // #region Services
  const {
    mutate: addNewQuestions,
    isLoading: isAdding,
    isSuccess: hasAdded,
  } = useAddQuestionsMutation();

  // Get one exam
  const { isFetching: isLoadingExam } = useOneExamQuery(
    {
      examId: questionsExamId,
    },
    {
      onSuccess: (data) => {
        setExamTitle(data.exams_by_pk?.title);
        methods.setValue('matnId', data.exams_by_pk?.mutonId);
      },
      enabled: !state?.exam?.title && isInExamPage,
    }
  );

  const { mutate: deleteQuestion, isLoading: isDeleting } = useDeleteQuestionMutation({
    onSuccess: (data) => {
      confirmDeleteQuestion.onFalse();
      queryClient.invalidateQueries({ queryKey: ['Questions'] });
    },
  });

  const {
    mutate: updateQuestions,
    isLoading: isUpdating,
    isSuccess: hasUpdated,
  } = useUpdateQuestionsMutation();

  // Get mutoon
  const {
    data: mutoon,
    isSuccess: hasGotMtns,
    isLoading: isLoadingMtns,
  } = useMutoonCategoriesQuery(
    {
      categoryCond: {},
    },
    {
      enabled: !isInExamPage,
    }
  );

  const mutoonOpts =
    mutoon?.mutoon_categories.map((mtn) => ({
      label: mtn.mutoon.name!?.[currentLang.value]!,
      value: mtn.mutoonId!,
      category: mtn.category.name!?.[currentLang.value],
    })) || [];

  // Get matn sections
  const {
    data: matnSections,
    isSuccess: hasGotMatnSections,
    isLoading: isLoadingSections,
  } = useAllMatnSectionsQuery(
    {
      matnId: matnId || methods.watch().matnId,
    },
    { enabled: !!matnId || !!methods.watch().matnId }
  );

  // Get questions by examId
  const { isFetching: isLoadingQuestions, data: questionsData } = useQuestionsQuery(
    {
      examId: { _eq: questionsExamId },
      limit: LIMIT,
      order_by: { createdAt: Order_By.Asc },
      offset: (methods.watch().pageValue! - 1) * LIMIT,
      search: search ? { _cast: { String: { _ilike: `%${search}%` } } } : undefined,
      // matnSectionId: { _is_null: true },
    },
    {
      enabled: isInExamPage,
      onSuccess: (data) => {
        methods.setValue(
          'questions',
          data.questions.map((question) => {
            return {
              ...question,
              mutoonSectionName: question.mutoon_section?.name!?.[currentLang.value],
            };
          })
        );
      },
    }
  );

  const { isFetching: isLoadingQuestion } = useOneQuestionsQuery(
    {
      questionId,
    },
    {
      enabled: isEditingQuestion,
      onSuccess: (data) => {
        methods.setValue('questions', [data.questions_by_pk!]);
      },
    }
  );
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);

  const handleAddNewQuestion = () => {
    appendQuestion(defaultValues);
  };

  const handleDeleteQuestion = (questionIndex: number, questionId: string) => {
    questionId && confirmDeleteQuestion.onTrue();
    questionId && setQuestionDeleteId(questionId!);
    !questionId && removeQuestion(questionIndex);
  };

  const handleUpdateAndAddQuestions = () => {
    // Make new data object in order not to edit the existing one
    const newData = dataToSend!;
    const newQuestions = dataToSend?.questions
      .filter((question) => !question.questionId)
      .map((question) => {
        delete question.mutoonSectionName;
        return { ...question };
      });
    newData.questions = newQuestions!;
    // Add new questions
    newData.questions.length && addNewQuestions(newData!);
    // Check if there is any updated questions
    if (methods.formState.touchedFields.questions?.length) {
      updateQuestions({
        updates: methods.formState.touchedFields.questions
          ?.map((question, index) => {
            const questionData = methods.watch().questions[index];
            // If the question is new just skip it and return undefined
            const isNewQuestion = !questionData.questionId;
            if (isNewQuestion) return undefined as any;
            // Delete props that you don't want to update or shouldn't
            if ('mutoon_section' in questionData) delete questionData.mutoon_section;
            if ('updatedAt' in questionData) delete questionData.updatedAt;
            if ('exam' in questionData) delete questionData.exam;
            delete questionData.mutoonSectionName;
            // Refactor questions to match the backend structure for updating objects
            return {
              where: { questionId: { _eq: questionData.questionId } },
              _set: { ...questionData },
            };
          })
          .filter((question) => question),
      });
    }
    confirm.onFalse();
  };

  const handleSwitch = (e: any, index: number) => {
    const isSwitchingToChoice = e.target.value === 'choice';
    const newValue = isSwitchingToChoice ? 'written' : 'choice';
    methods.setValue(`questions.${index}`, defaultValuesToggler(newValue));
    methods.setValue(`questions.${index}.type`, newValue);
  };

  const handleCheckRadio = (index: number, choiceIndex: number) =>
    methods.setValue(`questions.${index}.correctAnswerIndex`, choiceIndex);
  // #endregion handlers

  // #region useEffects
  useEffect(() => {
    // Sound form
    for (let i = 0; i < methods.watch().questions?.length; i++) {
      for (let j = 0; j < allLangs.length; j++) {
        methods.setValue(
          `questions.${i}.explanation.${allLangs[j].value}`,
          methods.watch().questions[i].explanation!?.[allLangs[j].value] || ''
        );
        methods.setValue(
          `questions.${i}.hint.${allLangs[j].value}`,
          methods.watch().questions[i].hint!?.[allLangs[j].value] || ''
        );
        methods.setValue(
          `questions.${i}.correctAnswerText.${allLangs[j].value}`,
          methods.watch().questions[i].correctAnswerText!?.[allLangs[j].value] || ''
        );
        methods.setValue(
          `questions.${i}.questionText.${allLangs[j].value}`,
          methods.watch().questions[i].questionText!?.[allLangs[j].value] || ''
        );
      }
    }
  }, [JSON.stringify(methods.watch()), currentTabValue]);
  // #endregion useEffects

  const memoizedValue = useMemo(
    () => ({
      isLoadingExam,
      isLoadingQuestion,
      isLoadingQuestions,
      methods,
      onSubmit,
      isInExamPage,
      isEditingQuestion,
      mutoonOpts,
      examTitle,
      currentTabValue,
      handleChangeTab,
      langsWithErrors,
      handleDeleteQuestion,
      handleSwitch,
      handleAddNewQuestion,
      confirm,
      mutate: addNewQuestions,
      dataToSend,
      isAdding,
      deleteQuestion,
      questionDeleteId,
      confirmDeleteQuestion,
      isDeleting,
      matnSections,
      handleCheckRadio,
      questions,
      questionsBankDialog,
      questionsData,
      questionsExamId,
      LIMIT,
      handleUpdateAndAddQuestions,
      isUpdating,
      search,
      setSearch,
    }),
    [
      isLoadingExam,
      isLoadingQuestion,
      isLoadingQuestions,
      methods,
      onSubmit,
      isInExamPage,
      isEditingQuestion,
      mutoonOpts,
      examTitle,
      currentTabValue,
      handleChangeTab,
      langsWithErrors,
      handleDeleteQuestion,
      handleSwitch,
      handleAddNewQuestion,
      confirm,
      addNewQuestions,
      dataToSend,
      isAdding,
      deleteQuestion,
      questionDeleteId,
      confirmDeleteQuestion,
      isDeleting,
      matnSections,
      handleCheckRadio,
      questions,
      questionsBankDialog,
      questionsData,
      questionsExamId,
      LIMIT,
      handleUpdateAndAddQuestions,
      search,
      setSearch,
    ]
  );

  return (
    <AddQuestionsContext.Provider value={memoizedValue}>{children}</AddQuestionsContext.Provider>
  );
};

export default AddQuestionsProvider;
