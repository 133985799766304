import { paths } from 'src/routes/paths';

export const BADGES_TABS = (t: any) => [
  {
    label: t('badges.tabs.general'),
    href: paths.dashboard.contentManagement.badges.generalBadges,
    link: 'general-badges',
  },
  {
    label: t('badges.tabs.custom'),
    href: paths.dashboard.contentManagement.badges.customBadges,
    link: 'custom-badges',
  },
];

export const NEW_BADGES_TABS = (t: any) => [
  {
    label: t('badges.tabs.general'),
    href: paths.dashboard.contentManagement.badges.generalBadges,
    link: 'new/general-badges',
  },
  {
    label: t('badges.tabs.custom'),
    href: paths.dashboard.contentManagement.badges.customBadges,
    link: 'new/custom-badges',
  },
];

export const BADGES_CUSTOM_TYPES = (t: any) => [
  {
    label: t('badges.types.hearingCouncils'),
    value: 'hearingCouncil',
  },
  {
    label: t('badges.types.category'),
    value: 'category',
  },
  {
    label: t('badges.types.matn'),
    value: 'matn',
  },
];

export const BADGES_GENERAL_TYPES = (t: any) => [
  {
    label: t('badges.generalTypes.mutoonRecordContribution'),
    value: 'mutoonRecordContribution',
  },
  {
    label: t('badges.generalTypes.streakDays'),
    value: 'streakDays',
  },
  {
    label: t('badges.generalTypes.sessionParticipation'),
    value: 'sessionParticipation',
  },
  {
    label: t('badges.generalTypes.hearingCouncilParticipation'),
    value: 'hearingCouncilParticipation',
  },
  {
    label: t('badges.generalTypes.userSharesCount'),
    value: 'userSharesCount',
  },
];
