import { Card, Container, Divider, Grid, Skeleton, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { TablePaginationCustom } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import SessionCard from '../card/session-card';
import useSessions from './use-sessions';
import FormProvider from 'src/components/hook-form/form-provider';

const Sessions = ({
  userId,
  noSessionsView,
}: {
  userId?: string;
  noSessionsView?: JSX.Element;
}) => {
  const {
    deleteSession,
    handleSearch,
    isDeleting,
    isInUserDetails,
    isLoadingSessions,
    pagination,
    sessions,
    setQuery,
    t,
    query,
    FILTERS,
    SORT_OPTIONS,
    handleResetFilters,
    handleResetSort,
    openFilters,
    openSort,
    methods,
  } = useSessions({ userId });

  if (
    !isLoadingSessions &&
    sessions?.sessions_aggregate.aggregate?.count === 0 &&
    isInUserDetails
  ) {
    return <Card sx={{ py: 2, px: 2 }}>{noSessionsView}</Card>;
  }

  const renderSessions = (
    <FormProvider methods={methods}>
      <Helmet>
        <title>{t('sidebar.sessions.root')}</title>
      </Helmet>
      <Card sx={{ py: 2, px: 2 }}>
        <Stack spacing={2}>
          <AppTableToolbar
            handleSearch={handleSearch}
            tableName=""
            setQuery={setQuery}
            search={query.search!}
            openFilters={openFilters}
            openSort={openSort}
            reset={handleResetFilters}
            resetSort={handleResetSort}
            filters={FILTERS}
            sortByOptions={SORT_OPTIONS}
            buttons={
              !isInUserDetails
                ? [
                    {
                      permissionName: AppPermissions.SESSIONS_CREATE,
                      path: 'new',
                    },
                  ]
                : [{}]
            }
          />
          <Divider />
          <Grid container columnSpacing={{ md: 2, xs: 0 }} rowSpacing={2}>
            {!isLoadingSessions
              ? sessions?.sessions.map((session) => (
                  <Grid key={session.id} item md={4} xs={12} display="flex" width="100%">
                    <SessionCard
                      session={session!}
                      mutateDelete={deleteSession}
                      isDeleting={isDeleting}
                    />
                  </Grid>
                ))
              : [...Array(pagination.rowsPerPage)].map((i, index) => (
                  <Grid key={index} item md={6} xs={12} display="flex" width="100%">
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: '100%',
                        height: 118,
                        borderRadius: 2,
                      }}
                    />
                  </Grid>
                ))}
          </Grid>
        </Stack>
      </Card>
      {sessions?.sessions.length ? (
        <TablePaginationCustom
          count={sessions?.sessions_aggregate.aggregate?.count!}
          page={pagination.page}
          rowsPerPage={pagination.rowsPerPage}
          onPageChange={pagination.onChangePage}
          onRowsPerPageChange={pagination.onChangeRowsPerPage}
          rowsPerPageOptions={[6, 12]}
        />
      ) : (
        <></>
      )}
    </FormProvider>
  );

  return isInUserDetails ? renderSessions : <Container>{renderSessions}</Container>;
};

export default Sessions;
