export const QUESTIONS_CHOICES_TYPE = (t: any) => [
  {
    label: t('questionsChoicesType.only_choose'),
    value: 'only_choose',
  },
  {
    label: t('questionsChoicesType.is_there_error'),
    value: 'is_there_error',
  },
  {
    label: t('questionsChoicesType.count_errors'),
    value: 'count_errors',
  },
  {
    label: t('questionsChoicesType.where_the_error'),
    value: 'where_the_error',
  },
];
