import { Grid, Typography } from '@mui/material';
import AppContainer from 'src/components/common/AppContainer';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import EditRoleForm from 'src/sections/roles/EditRoleForm';

export default function EditRolePage() {
  const { t } = useLocales();

  return (
    <AppContainer pageTitle="Edit Role">
      <Grid container spacing={2}>
        <Grid item xs={12} mb={4}>
          <Typography variant="h4" color="primary">
            {t('dialogs.addNewRole.roleInfo')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <EditRoleForm />
        </Grid>
      </Grid>
    </AppContainer>
  );
}
