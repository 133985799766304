import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useLocales } from 'src/locales';
import HearingCouncils from '../../hearing-councils/view/table/hearing-councils';

const UserHearingCouncils = () => {
  const { userId } = useParams();
  const { t } = useLocales();

  const noSessionsView = (
    <Stack spacing={2} textAlign="center">
      <Typography variant="h5" fontWeight={600}>
        {t('userDetails.hearingCouncils.noHearingCouncils.title')}
      </Typography>
      <Typography variant="h6" fontWeight={300}>
        {t('userDetails.hearingCouncils.noHearingCouncils.subtitle')}
      </Typography>
    </Stack>
  );

  return <HearingCouncils userId={userId} noCouncilsView={noSessionsView} />;
};

export default UserHearingCouncils;
