import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import { API_URLS } from 'src/api/apiUrls';
import useApiServices from 'src/api/useApiServices';
import {
  useAddKnowledgeBaseMutation,
  useOneKnowledgeBaseQuery,
  useUpdateKnowledgeBaseMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddKnowledgeBasesVariablesType, KnowledgeBasesDto } from 'src/types/knowledge-bases';

const useAddNewKnowledgeBase = () => {
  // #region States
  const confirm = useBoolean();
  const { AddKnowledgeBaseSchema } = useSchemas();
  const { knowledgeBaseId: knowledgeBaseIdPrm } = useParams();
  const queryClient = useQueryClient();
  const { usePostApi } = useApiServices();
  const { allLangs } = useLocales();
  const router = useRouter();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const [file, setFile] = useState<File | string | null>(null);
  const [knowledgeBaseId, setKnowledgeBaseId] = useState<string | undefined>(knowledgeBaseIdPrm);
  const { state }: { state: { knowledgeBase: KnowledgeBasesDto | undefined } | undefined } =
    useLocation();
  const isEditing = !!knowledgeBaseIdPrm;
  const [dataToSend, setDataToSend] = useState<AddKnowledgeBasesVariablesType>();
  // #endregion States

  let defaultValues: AddKnowledgeBasesVariablesType = {
    title: state?.knowledgeBase?.title || {},
    content: state?.knowledgeBase?.content || {},
    cover_url: state?.knowledgeBase?.cover_url || '',
  };

  // #region form
  for (let i = 0; i < allLangs.length && !state?.knowledgeBase; i++) {
    defaultValues.title[allLangs[i].value] = '';
    defaultValues.content[allLangs[i].value] = '';
  }

  const methods = useForm<AddKnowledgeBasesVariablesType>({
    defaultValues,
    resolver: yupResolver(AddKnowledgeBaseSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddKnowledgeBasesVariablesType) => {
      const newData = { ...data };
      if (!isEditing) delete (newData as any).coverUrl;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  const { isFetching: isLoadingKnowledgeBase } = useOneKnowledgeBaseQuery(
    {
      knowledgeBaseId,
    },
    {
      enabled: !state?.knowledgeBase && !!knowledgeBaseIdPrm,
      onSuccess: (data) => {
        {
          methods.setValue('title', data.knowledge_bases_by_pk!?.title);
          methods.setValue('content', data.knowledge_bases_by_pk?.content);
          methods.setValue('cover_url', data.knowledge_bases_by_pk?.cover_url!);
        }
      },
    }
  );

  const {
    mutate: uploadImage,
    isLoading: isUploadingImage,
    data,
  } = usePostApi({
    url: API_URLS.UPLOAD_KNOWLEDGE_BASE_COVER(knowledgeBaseId ?? ''),
    withFormData: true,
    onSuccess: (data: any) => {
      methods.setValue('cover_url', data?.avatar);
      router.push(paths.dashboard.contentManagement.knowledgeBases);
    },
  });

  const {
    mutate: addKnowledgeBase,
    isLoading: isAdding,
    isSuccess: hasAddedBase,
  } = useAddKnowledgeBaseMutation({
    onSuccess: (data) => {
      setKnowledgeBaseId(data.insert_knowledge_bases_one?.knowledgeBaseId);
      confirm.onFalse();
    },
  });

  const { mutate: editKnowledgeBase, isLoading: isUpdating } = useUpdateKnowledgeBaseMutation({
    onSuccess: (data) => {
      confirm.onFalse();
      queryClient.invalidateQueries({ queryKey: ['knowledgeBases'] });
      // If the user was just removing the book do not take him out
    },
  });

  const mutate = isEditing
    ? () =>
        editKnowledgeBase({
          knowledgeBaseId,
          ...dataToSend,
        })
    : () =>
        addKnowledgeBase({
          ...dataToSend,
        });
  // #endregion Services

  // #region handlers
  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
      methods.setValue('cover_url', URL.createObjectURL(newFile));
    }
  }, []);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`title.${currentTabValue}`, methods.watch().title[currentTabValue] || '');
    methods.setValue(`content.${currentTabValue}`, methods.watch().content[currentTabValue] || '');
  }, [methods, currentTabValue]);

  useEffect(() => {
    if (file && (hasAddedBase || state?.knowledgeBase)) uploadImage({ file: file });
  }, [file, hasAddedBase]);
  // #endregion useEffect

  return {
    isLoadingKnowledgeBase,
    methods,
    handleSubmit,
    onSubmit,
    currentTabValue,
    isUploadingImage,
    file,
    handleDropSingleFile,
    setFile,
    editKnowledgeBase,
    knowledgeBaseId: knowledgeBaseIdPrm,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    mutate,
    handleChangeTab,
    langsWithErrors
  };
};

export default useAddNewKnowledgeBase;
