import { SxProps } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import React, { ReactNode } from 'react';

interface IAppTableCellProps {
  value: string | ReactNode;
  sx?: SxProps;
}

const AppTableCell: React.FC<IAppTableCellProps> = (props) => {
  const { value, sx } = props;

  return (
    <TableCell
      sx={{
        textAlign: 'center',
        ...sx,
      }}
    >
      {value}
    </TableCell>
  );
};

export default AppTableCell;
