import { useQueryClient } from '@tanstack/react-query';
import { useTable } from 'src/components/table';
import { Order_By, useDeleteFaqMutation, useFaQsQuery } from 'src/graphql';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

const useFaqs = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>();

  // #region Services
  const { data, isLoading } = useFaQsQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
    where: {
      _or: [
        {
          _or: [
            {
              answer: query.search
                ? { _cast: { String: { _ilike: `%${query.search}%` } } }
                : undefined,
            },
            {
              question: query.search
                ? { _cast: { String: { _ilike: `%${query.search}%` } } }
                : undefined,
            },
          ],
        },
      ],
    },
  });

  const { mutate, isLoading: isDeleting } = useDeleteFaqMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['FAQs'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteFAQ = (id: string) => {
    mutate({ id });
  };
  // #endregion Handlers
  return {
    isDeleting,
    methods,
    t,
    handleDeleteFAQ,
    handleSearch,
    query,
    setQuery,
    isLoading,
    data,
    table,
  };
};

export default useFaqs;
