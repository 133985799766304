import React from 'react';

const VoiceRequests = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 9.86035V14.1504V9.86035Z" fill="white" />
      <path
        d="M6 9.86035V14.1504"
        stroke="#2B3F4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 8.42969V15.5697V8.42969Z" fill="white" />
      <path
        d="M9 8.42969V15.5697"
        stroke="#2B3F4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 7V17V7Z" fill="white" />
      <path
        d="M12 7V17"
        stroke="#2B3F4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 8.42969V15.5697V8.42969Z" fill="white" />
      <path
        d="M15 8.42969V15.5697"
        stroke="#2B3F4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9.86035V14.1504"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VoiceRequests;
