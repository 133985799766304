export const sessionButtonLabel = (status: 'Live' | 'Expired' | 'Pending' | '') => {
  switch (status) {
    case 'Live':
      return 'sessions.card.join';
    case 'Pending':
      return 'sessions.card.startNow';
    case 'Expired':
      return '';
    default:
      return '';
  }
};
