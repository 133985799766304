import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, IconButton, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
// assets
import Iconify from '../iconify';
import { useFormContext } from 'react-hook-form';
import RejectionFiles from './errors-rejection-files';
import { UploadProps } from './types';
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

export default function Upload({
  multiple = false,
  disabled,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  icon,
  uploadLabel,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  iframeStyle,
  previewComponent,
  fileFieldName,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const { t } = useLocales();
  const hasError = isDragReject || !!error;
  const methods = useFormContext();
  const hasFile =
    (!!file && !multiple) ||
    methods.watch().pdfUrl ||
    (fileFieldName && methods.watch()[fileFieldName]);

  const renderSinglePreview = previewComponent ? (
    previewComponent
  ) : (
    <iframe
      src={methods.watch().pdfUrl || (file as any)?.preview}
      style={{
        borderRadius: '16px',
        border: 'none',
        width: '100%',
        height: '50vh',
        ...iframeStyle,
      }}
    />
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: (theme) => alpha(theme.palette.common.white, 0.8),
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.72),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.error.main, 1),
        },
      }}
    >
      <Iconify icon="mingcute:close-line" width={18} />
    </IconButton>
  );

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      {!disabled ? (
        <Box
          {...getRootProps()}
          sx={{
            p: 1,
            outline: 'none',
            borderRadius: 1,
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            transition: (theme) => theme.transitions.create(['opacity', 'padding']),
            '&:hover': {
              opacity: 0.72,
            },
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(hasError && {
              color: 'white',
              bgcolor: 'error.lighter',
              borderColor: 'error.light',
            }),
          }}
        >
          <input {...getInputProps()} />

          {hasFile ? (
            renderSinglePreview
          ) : (
            <Stack spacing={2} alignItems="center">
              <Iconify icon={icon || 'eva:cloud-upload-fill'} width={64} color="text.disabled" />
              <Typography>{t(uploadLabel as any || 'dialogs.addNewBook.uploadPdf')}</Typography>
            </Stack>
          )}
        </Box>
      ) : (
        <Stack justifyContent="center" direction="row">
          {renderSinglePreview}
        </Stack>
      )}

      {!disabled && removeSinglePreview}

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />
    </Box>
  );
}
