import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  useAddAdminMutation,
  useAllRolesQuery,
  useOneAdminQuery,
  useUpdateAdminMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddAdminMutationVariables, AdminDtoType } from 'src/types/admins';

const useAddNewUser = () => {
  // #region States and hooks
  const { AddAdminSchema } = useSchemas();
  const { userId } = useParams();
  const confirm = useBoolean();
  const { state }: { state: AdminDtoType | undefined } = useLocation();
  const { t } = useLocales();
  const router = useNavigate();
  const isEditing = !!userId;
  const [dataToSend, setDataToSend] = useState<AddAdminMutationVariables>();
  // #endregion

  // #region Form
  const methods = useForm<AddAdminMutationVariables>({
    defaultValues: {
      email: state?.email || '',
      username: state?.username || '',
      roleId: state?.roleId || '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(AddAdminSchema(isEditing)),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddAdminMutationVariables) => {
      const newData: any = { ...data };
      delete newData.confirmPassword;
      if (isEditing) delete newData.password;
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion form

  // #region Services
  const { mutate: addAdmin, isLoading: isAdding } = useAddAdminMutation({
    onSuccess: () => {
      router('/dashboard/roles-and-users/users');
    },
  });

  const { mutate: updateAdmin, isLoading: isUpdating } = useUpdateAdminMutation({
    onSuccess: () => {
      router('/dashboard/roles-and-users/users');
    },
  });

  const mutate = isEditing
    ? () => {
        updateAdmin({
          ...dataToSend,
          id: userId,
        });
      }
    : () => {
        addAdmin({
          ...dataToSend,
        });
      };

  const { data: roles, isLoading: isLoadingRoles } = useAllRolesQuery();

  const { data: adminData, isLoading: isLoadingAdmin } = useOneAdminQuery(
    {
      id: userId,
    },
    {
      onSuccess: (data) => {
        methods.setValue('username', data.admins_by_pk?.username!);
        methods.setValue('email', data.admins_by_pk?.email!);
        methods.setValue('roleId', data.admins_by_pk?.roleId!);
      },
    }
  );
  // #endregion Services

  return {
    isLoadingAdmin,
    handleSubmit,
    onSubmit,
    methods,
    roles,
    t,
    isEditing,
    confirm,
    isAdding,
    isUpdating,
    mutate,
  };
};

export default useAddNewUser;
