import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import ExamsRow from './exams-row';
import useExams from './use-exams';

const TABLE_HEAD = (t: any) => [
  { id: '1', label: t('tables.exams.headLabels.title'), orderName: 'title' },
  { id: '2', label: t('tables.exams.headLabels.description'), orderName: 'description' },
  { id: '3', label: t('tables.exams.headLabels.matn'), orderName: 'mutoon.name' },
  { id: '4', label: t('tables.exams.headLabels.actions') },
];

const Exams = () => {
  const {
    methods,
    handleSearch,
    query,
    setQuery,
    isLoading,
    isDeleting,
    t,
    table,
    data,
    handleDeleteExam,
  } = useExams();

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.mutoon.exams')}</title>
      </Helmet>
      <Card>
        <FormProvider methods={methods}>
          <AppTableToolbar
            tableName=""
            buttons={[
              {
                path: 'new',
                permissionName: AppPermissions['EXAMS_CREATE'],
              },
            ]}
            handleSearch={handleSearch}
            search={query.search!}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom setQuery={setQuery} headLabel={TABLE_HEAD(t)} methods={methods} />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.exams?.map((row) => (
                        <ExamsRow key={row.examId} row={row} deleteHandler={handleDeleteExam} />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.exams_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
      </Card>
    </Container>
  );
};

export default Exams;
