import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import {
  SCHOOLS_REQUESTS_TABLE_HEAD,
  TEACHERS_REQUESTS_TABLE_HEAD,
  USER_RECORDS_TABLE_HEAD,
} from 'src/constants/tables-heads';
import AddNewCategoryDialog from 'src/sections/dialogs/add/AddNewCategoryDialog';
import useSchoolsRequest from './use-schools-request';
import SchoolsRequestRow from './schools-request-row';

const SchoolsRequests = () => {
  const { addDialog, data, isLoading, methods, query, setQuery, t, table, handleSearch } =
    useSchoolsRequest();

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.userAuthorization.schoolRequests')}</title>
      </Helmet>
      <Card>
        <FormProvider methods={methods}>
          <AppTableToolbar
            handleSearch={handleSearch}
            tableName=""
            reset={methods.reset}
            search={query.search!}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  setQuery={setQuery}
                  headLabel={SCHOOLS_REQUESTS_TABLE_HEAD(t)}
                  methods={methods}
                />
                <TableBody>
                  {isLoading ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.school_users?.map((row) => (
                        <SchoolsRequestRow key={row.schoolUserId} row={row} />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.school_users_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
        <AddNewCategoryDialog open={addDialog.value} onClose={addDialog.onFalse} />
      </Card>
    </Container>
  );
};

export default SchoolsRequests;
