import { useQueryClient } from '@tanstack/react-query';
import { useTable } from 'src/components/table';
import { Order_By, useDeleteExamMutation, useExamsQuery } from 'src/graphql';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

const useExams = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>();

  // #region Services
  const { data, isLoading } = useExamsQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _ilike: `%${query.search || ''}%` } : {},
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
  });

  const { mutate, isLoading: isDeleting } = useDeleteExamMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Exams'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteExam = (examId: string) => {
    mutate({ examId });
  };
  // #endregion Handlers

  return {
    methods,
    handleSearch,
    query,
    setQuery,
    isLoading,
    isDeleting,
    t,
    table,
    data,
    handleDeleteExam
  };
};

export default useExams;
