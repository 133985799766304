import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { SyntheticEvent } from 'react';

// ----------------------------------------------------------------------

export type OptionType = {
  value: string;
  label: string;
};

export interface AutocompleteOption {
  label: string;
  value: string;
}

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  valueName?: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  size?: 'small' | 'medium';
}

export default function RHFAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  name,
  label,
  placeholder,
  helperText,
  valueName,
  size,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue } = useFormContext();

  const changeHandler = (event: SyntheticEvent<Element, Event>, newValue: any) => {
    if (!other.multiple) {
      name && setValue(name, newValue.label, { shouldValidate: true });
      valueName && setValue(valueName, newValue.value, { shouldValidate: true });
    } else {
      name && setValue(name, newValue, { shouldValidate: true });
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            size={size!}
            renderInput={(params) => (
              <TextField
                label={label}
                placeholder={placeholder}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...params}
                size={size!}
                sx={{
                  '& .MuiAutocomplete-inputRoot': {
                    borderRadius: '50px',
                  },
                }}
              />
            )}
            {...other}
            onChange={(e, value) => {
              changeHandler(e, value);
              other.onChange && other.onChange(e, value, 'selectOption');
            }}
          />
        );
      }}
    />
  );
}
