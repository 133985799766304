import { Box, Button, Card, Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import DeleteDialog from 'src/sections/dialogs/delete/DeleteDialog';
import EditDiffWordDialog from 'src/sections/dialogs/edit/EditDiffWordDialog';
import { EditDiffWordMutationVariablesType } from 'src/types/edit-diff-words';
import { AddSectionDiffWordsPropsType } from 'src/types/matn-section-sections';
import { AddWordsDefinitionsMutationVariables } from 'src/types/words-definitions';

const AddSectionDiffWords = ({
  addDiffWords,
  t,
  allLangs,
  handleAddNewWordDiff,
  handleDeleteWordDiff,
  methods,
  confirmDeleteWord,
  mutateDelete,
  transDeleteId,
  isDeletingWord,
}: AddSectionDiffWordsPropsType) => {
  const confirmEditWord = useBoolean();
  const [diffWord, setDiffWord] = useState<EditDiffWordMutationVariablesType>();
  const handleEditDiffWord = (word: EditDiffWordMutationVariablesType) => {
    setDiffWord(word);
    confirmEditWord.onTrue();
  };

  return (
    <>
      <Card sx={{ borderRadius: 20 }}>
        <Button
          onClick={addDiffWords.onToggle}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            p: { xs: 2, md: 3 },
            borderRadius: 20,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Iconify
              icon={!addDiffWords.value ? 'simple-line-icons:plus' : 'simple-line-icons:minus'}
              width={32}
            />
            <Typography textAlign="center" fontWeight={500} fontSize={18}>
              {t('dialogs.addNewMatnSection.addDifficultWordsAndTheirMeanings')}
            </Typography>
          </Box>
        </Button>
      </Card>
      {addDiffWords.value && (
        <>
          <Stack spacing={2}>
            <Grid container rowSpacing={2} columnSpacing={{ md: 2, xs: 0 }}>
              {methods.watch().wordsDefinitions?.map((word, index) => {
                return (
                  <Grid item xs={12} key={word.translationId!}>
                    <Card sx={{ p: 3 }}>
                      <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <Stack direction="row">
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteWordDiff(index, word.translationId!)}
                            >
                              <Iconify width={24} icon="material-symbols:delete-outline" />
                            </IconButton>
                            {word.translationId && (
                              <IconButton color="warning" onClick={() => handleEditDiffWord(word)}>
                                <Iconify width={24} icon="tabler:edit" />
                              </IconButton>
                            )}
                          </Stack>
                          <Chip label={index + 1} />
                        </Stack>
                        <Grid container rowSpacing={2} columnSpacing={{ md: 2, xs: 0 }}>
                          {allLangs.map((lang) => (
                            <Grid key={lang.value} item md={4} xs={12}>
                              <Block
                                label={t('dialogs.addNewMatnSection.word') + ': ' + lang.value}
                              >
                                <RHFTextField
                                  disabled={!!word.translationId}
                                  name={`wordsDefinitions.${index}.word.${lang.value}`}
                                />
                              </Block>
                            </Grid>
                          ))}
                          {allLangs.map((lang) => (
                            <Grid item md={4} xs={12} key={lang.value}>
                              <Block
                                label={
                                  t('dialogs.addNewMatnSection.itsMeaning') + ': ' + lang.value
                                }
                              >
                                <RHFTextField
                                  disabled={!!word.translationId}
                                  name={`wordsDefinitions.${index}.translatedText.${lang.value}`}
                                />
                              </Block>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
          <AppButton
            label={t('dialogs.addNewMatnSection.addAnotherWord')}
            size="small"
            onClick={handleAddNewWordDiff}
            startIcon={<Iconify icon="eva:plus-fill" />}
            variant="outlined"
            fullWidth
          />
        </>
      )}
      <DeleteDialog
        mutate={mutateDelete}
        id={transDeleteId}
        open={confirmDeleteWord.value}
        onClose={confirmDeleteWord.onFalse}
        idWord="translationId"
        isDeleting={isDeletingWord}
      />
      <EditDiffWordDialog
        open={confirmEditWord.value}
        onClose={confirmEditWord.onFalse}
        diffWord={diffWord!}
      />
    </>
  );
};

export default AddSectionDiffWords;
