// Make them as label and value object
export const CONTACT_US_STATUS = (t: any) => [
  {
    label: t('tables.contactUs.status.active'),
    value: 'active',
  },
  {
    label: t('tables.contactUs.status.inactive'),
    value: 'inactive',
  },
]
