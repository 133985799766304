import { IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';
import { RouterLink } from 'src/routes/components';
import { handleDebouncedSearch } from 'src/utils/helper';
// import AppPermissionGate from 'src/features/ui/components/common/AppPermissionGate';
import { DATE_FILTER_OPERATORS } from 'src/constants/date-filter-opertars';
import { useLocales } from 'src/locales';
import { Block } from '../Block';
import AppButton from '../common/AppButton';
import { RHFTextField } from '../hook-form';
import RHFAutocomplete from '../hook-form/rhf-autocomplete';
import RHFDatePicker from '../hook-form/rhf-date-picker';
import { RHFSelect } from '../hook-form/rhf-select';
import RHFSwitch from '../hook-form/rhf-switch';
import Iconify from '../iconify/iconify';
import Scrollbar from '../scrollbar';
import { IAppTableToolbarProps } from './types';
import NewAppButton from '../common/NewAppButton';
import { SORT_DIRECTION } from 'src/constants/sort-direction';

function AppTableToolbar<T>(props: IAppTableToolbarProps<T>) {
  const {
    tableName,
    handleSearch,
    openFilters,
    filters,
    buttons,
    reset,
    data,
    isLoading,
    pagination,
    search,
    setQuery,
    setSearch,
    openSort,
    resetSort,
    sortByOptions,
  } = props;
  const { t } = useLocales();

  const handleOpenDrawer = () => {
    openFilters && openFilters.onTrue();
    pagination && pagination.onResetPage();
  };

  const handleOpenSortDrawer = () => {
    openSort && openSort.onTrue();
    pagination && pagination.onResetPage();
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('filterDrawer.filters')}
      </Typography>

      {openFilters! && (
        <IconButton onClick={openFilters.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}

      {openSort! && (
        <IconButton onClick={openSort.onFalse}>
          <Iconify icon="ep:sort" />
        </IconButton>
      )}
    </Stack>
  );

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent={handleSearch || setSearch ? 'space-between' : 'flex-end'}
      p={3}
    >
      {(handleSearch || setSearch) && (
        <TextField
          size="small"
          defaultValue={search}
          placeholder={`${t('tables.search')} ${tableName}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#c6ddb4' }} />
              </InputAdornment>
            ),
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleDebouncedSearch(() => {
              handleSearch && handleSearch(event);
              setSearch && setSearch(event.target.value);
              pagination && pagination.onResetPage();
            });
          }}
        />
      )}
      <Stack direction="row" spacing={2}>
        {buttons?.length &&
          buttons!?.map((button) =>
            !button.buttonComponent ? (
              <NewAppButton
                label={button.label! || `${t('buttons.add')} ${tableName}`}
                onClick={button.onClick}
                buttonIcon={<Iconify icon="material-symbols:add" />}
                linkCompoennt={RouterLink}
                href={button.path}
                size="small"
              />
            ) : (
              button.buttonComponent
            )
          )}

        {openFilters! && (
          <Tooltip title="Filters" placement="bottom" arrow>
            <IconButton onClick={handleOpenDrawer}>
              <Iconify icon="ri:filter-line" width="50" height="50" />
            </IconButton>
          </Tooltip>
        )}

        {openSort! && (
          <Tooltip title="Sort" placement="bottom" arrow>
            <IconButton onClick={handleOpenSortDrawer}>
              <Iconify icon="ep:sort" width="50" height="50" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {/* Filters */}
      <Drawer
        anchor="right"
        open={openFilters?.value}
        onClose={openFilters?.onFalse}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        {renderHead}
        <Divider />
        <Scrollbar sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3}>
            {filters?.map((filter) => {
              if (filter.type === 'text') {
                return (
                  <Block key={filter.name} label={filter.title}>
                    <RHFTextField
                      onChange={(e) => {
                        setQuery && setQuery({ [filter.name]: e.target.value });
                      }}
                      size="small"
                      debounce
                      name={`${filter.name}`}
                    />
                  </Block>
                );
              } else if (filter.type === 'select') {
                return (
                  <Block key={filter.name} label={filter.title}>
                    <RHFSelect
                      onChange={(e) => {
                        setQuery && setQuery({ [filter.name]: e.target.value });
                      }}
                      size="small"
                      name={`${filter.name}`}
                    >
                      <MenuItem value={undefined}>{t('constants.all')}</MenuItem>
                      {filter.options?.map((option, index) => (
                        <MenuItem key={option.id!} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Block>
                );
              } else if (filter.type === 'boolean') {
                return (
                  <Block
                    key={filter.name}
                    label={filter.title}
                    sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}
                  >
                    <RHFSwitch
                      onClick={(e) => {
                        setQuery &&
                          setQuery({
                            [filter.name]: (e.target as any).value === 'false' ? true : false,
                          });
                      }}
                      name={filter.name}
                      label
                    />
                  </Block>
                );
              } else if (filter.type === 'date') {
                return (
                  <Block key={filter.name} label={filter.title}>
                    <Stack direction="column" spacing={1}>
                      <RHFSelect
                        onChange={(e) => {
                          setQuery && setQuery({ [filter.opertaorFieldName]: e.target.value });
                        }}
                        size="small"
                        name={filter.opertaorFieldName}
                      >
                        {DATE_FILTER_OPERATORS(t).map((operator) => (
                          <MenuItem value={operator.value}>{operator.label}</MenuItem>
                        ))}
                      </RHFSelect>
                      <RHFDatePicker
                        onChange={(e) => {
                          setQuery && setQuery({ [filter.name]: e });
                        }}
                        size="small"
                        dateAndTime
                        name={filter.name}
                      />
                    </Stack>
                  </Block>
                );
              } else if (filter.type === 'autocomplete') {
                return (
                  <Block key={filter.name} label={filter.title}>
                    <RHFAutocomplete
                      size="small"
                      name={filter.name}
                      valueName={filter.valueName}
                      options={filter.options}
                      onChange={(e, newValue: any) => {
                        setQuery && setQuery({ [filter.name]: newValue!?.label });
                        setQuery && setQuery({ [filter.valueName]: newValue!?.value });
                      }}
                    />
                  </Block>
                );
              }
            })}
            {reset && (
              <AppButton
                onClick={() => {
                  reset();
                  openFilters?.onFalse();
                }}
                label={t('filterDrawer.resetButton')}
              />
            )}
          </Stack>
        </Scrollbar>
      </Drawer>

      {/* Sort drawer */}
      <Drawer
        anchor="right"
        open={openSort?.value}
        onClose={openSort?.onFalse}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        <Scrollbar sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3}>
            <Block label={t('tables.sort.sortBy')}>
              <RHFSelect
                name="sortBy"
                size="small"
                onChange={(e) => {
                  setQuery && setQuery({ sortBy: e.target.value });
                }}
              >
                {sortByOptions?.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label={t('tables.sort.sortDirection')}>
              <RHFSelect
                onChange={(e) => {
                  setQuery && setQuery({ sortDirection: e.target.value });
                }}
                name="sortDirection"
                size="small"
              >
                {SORT_DIRECTION(t).map((direction) => (
                  <MenuItem value={direction.value}>{direction.label}</MenuItem>
                ))}
              </RHFSelect>
            </Block>
            {resetSort && (
              <AppButton
                onClick={() => {
                  resetSort();
                  openSort?.onFalse();
                }}
                size="small"
                sx={{ fontSize: 14 }}
                label={t('buttons.reset')}
              />
            )}
          </Stack>
        </Scrollbar>
      </Drawer>
    </Stack>
  );
}

export default AppTableToolbar;
