// @mui
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import useLocales from 'src/locales/use-locales';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { AdminDtoType } from 'src/types/admins';

// ----------------------------------------------------------------------

type Props = {
  row: AdminDtoType;
  deleteHandler: (levelId: string) => void;
};

export default function DashboardUsersRow({ row, deleteHandler }: Props) {
  const { t } = useLocales();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === 'light';

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.username}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.role.name}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{row.email}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
          <Tooltip title={t('tableLabels.edit')} placement="bottom" arrow>
            <IconButton>
              <Link
                to={`${paths.dashboard.rolesAndUsers.users}/${row.id}`}
                state={row}
                style={{ display: 'flex' }}
              >
                <Iconify icon="tabler:edit" width="27" height="27" color="#FFAB8B" />
              </Link>
            </IconButton>
          </Tooltip>

          <Tooltip title={t('tableLabels.delete')} placement="bottom" arrow>
            <IconButton LinkComponent={RouterLink} onClick={() => deleteHandler(row.id)}>
              <Iconify
                icon="material-symbols:delete-outline"
                width="27"
                height="27"
                color="#DDB6FF"
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
}
