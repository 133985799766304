import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { IAppTableToolbarProps, useTable } from 'src/components/table';
import { SESSIONS_STATUS } from 'src/constants/sessions-status';
import { Order_By, useDeleteSessionMutation, useSessionsQuery } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { HearingCouncilsFormType } from 'src/types/hearing-council';
import { orderBy } from 'src/utils/order-by';
import { useQueryParams } from 'use-query-params';

const useSessions = ({ userId }: { userId?: string }) => {
  // #region Hooks and states
  const { t } = useLocales();
  const queryClient = useQueryClient();
  const pagination = useTable({ defaultRowsPerPage: 6, defaultCurrentPage: 0 });
  const [filtersQuery] = useQueryParams({
    ['startDate.operator']: '',
    ['startDate.value']: '',
    ['expiryDate.operator']: '',
    ['expiryDate.value']: '',
    status: '',
    sortBy: '',
    sortDirection: '',
  });
  const openSort = useBoolean();
  const openFilters = useBoolean();

  const { methods, query, handleSearch, setQuery } = UseSearch<HearingCouncilsFormType>({
    status: filtersQuery.status || undefined,
    startDate: {
      operator: (filtersQuery['startDate.operator'] as '_gt') || '_gt',
      value: filtersQuery['startDate.value']
        ? moment(filtersQuery['startDate.value']).format()
        : '',
    },
    expiryDate: {
      operator: (filtersQuery['expiryDate.operator'] as '_gt') || '_lt',
      value: filtersQuery['expiryDate.value']
        ? moment(filtersQuery['expiryDate.value']).format()
        : '',
    },
    sortBy: filtersQuery['sortBy']!,
    sortDirection: filtersQuery['sortDirection'] as Order_By.Asc,
  });
  // #endregion Hooks and states

  const isInUserDetails = userId;

  // #region Services
  const { data: sessions, isLoading: isLoadingSessions } = useSessionsQuery({
    offset: pagination.offset,
    limit: pagination.rowsPerPage,
    search: query.search!,
    userId: isInUserDetails ? { _eq: userId } : undefined,
    status: methods.watch().status ? { _eq: methods.watch().status } : {},
    startDate:
      methods.watch().startDate.value && methods.watch().startDate.operator
        ? { [methods.watch().startDate.operator]: methods.watch().startDate.value }
        : {},
    expiryDate:
      methods.watch().expiryDate.value && methods.watch().expiryDate.operator
        ? { [methods.watch().expiryDate.operator]: methods.watch().expiryDate.value }
        : {},
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
  });

  const { mutate: deleteSession, isLoading: isDeleting } = useDeleteSessionMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['Sessions'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleResetFilters = () => {
    methods.reset({
      ...methods.watch(),
      status: undefined,
      expiryDate: { operator: undefined, value: undefined },
      startDate: { operator: undefined, value: undefined },
    });
    setQuery({
      status: undefined,
      'startDate.value': undefined,
      'startDate.operator': undefined,
      'expiryDate.value': undefined,
      'expiryDate.operator': undefined,
    });
  };

  const handleResetSort = () => {
    methods.reset({
      ...methods.watch(),
      sortBy: '',
      sortDirection: '' as Order_By.Asc,
    });
    setQuery({
      sortBy: undefined,
      sortDirection: undefined,
    });
  };
  // #endregion Handlers

  // Table Filters
  const FILTERS: IAppTableToolbarProps<any>['filters'] = [
    {
      name: 'status',
      type: 'select',
      options: SESSIONS_STATUS(t),
      title: t('hearingCouncils.filters.status'),
    },
    {
      type: 'date',
      name: 'startDate.value',
      opertaorFieldName: 'startDate.operator',
      title: t('hearingCouncils.filters.startDate'),
    },
    {
      type: 'date',
      name: 'expiryDate.value',
      opertaorFieldName: 'expiryDate.operator',
      title: t('hearingCouncils.filters.expiryDate'),
    },
  ];

  // Table sort options
  const SORT_OPTIONS: IAppTableToolbarProps<any>['sortByOptions'] = [
    {
      label: t('dialogs.addNewHearingCouncil.sessionName'),
      value: 'sessionName',
    },
    {
      label: t('dialogs.addNewHearingCouncil.description'),
      value: 'description',
    },
    {
      label: t('dialogs.addNewHearingCouncil.sheikhName'),
      value: 'sheikhName',
    },
    {
      label: t('dialogs.addNewHearingCouncil.startDate'),
      value: 'startDate',
    },
    {
      label: t('dialogs.addNewHearingCouncil.expiryDate'),
      value: 'expiryDate',
    },
    {
      label: t('hearingCouncils.filters.status'),
      value: 'status',
    },
  ];
  return {
    isLoadingSessions,
    sessions,
    isInUserDetails,
    t,
    setQuery,
    handleSearch,
    isDeleting,
    deleteSession,
    pagination,
    query,
    FILTERS,
    SORT_OPTIONS,
    handleResetFilters,
    handleResetSort,
    openSort,
    openFilters,
    methods,
  };
};

export default useSessions;
