import { Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import FormProvider from 'src/components/hook-form/form-provider';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TablePaginationCustom, TableSkeleton } from 'src/components/table';
import AppTableToolbar from 'src/components/table/AppTableToolbar';
import AppPermissions from 'src/constants/app-permissions';
import { DASHBOARD_USERS_TABLE_HEAD } from 'src/constants/tables-heads';
import { Order_By } from 'src/graphql';
import { paths } from 'src/routes/paths';
import DashboardUsersRow from './dashboard-users-row';
import useDashboardUsers from './use-dashboard-users';

type variablesType = {
  sortBy: string;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
};

const DashboardUsers = () => {
  const {
    t,
    methods,
    handleSearch,
    query,
    setQuery,
    isLoading,
    isDeleting,
    handleDeleteAdmin,
    data,
    table,
  } = useDashboardUsers();

  return (
    <Container>
      <Helmet>
        <title>{t('sidebar.users')}</title>
      </Helmet>
      <Card>
        <FormProvider methods={methods}>
          {/* <DashboardUsersToolbar search={search} setSearch={setSearch} /> */}
          <AppTableToolbar
            handleSearch={handleSearch}
            search={query.search!}
            tableName=""
            buttons={[
              {
                permissionName: AppPermissions.CREATE_ADMINS,
                path: 'new',
              },
            ]}
          />
          <TableContainer>
            <Scrollbar>
              <Table>
                <TableHeadCustom
                  methods={methods}
                  setQuery={setQuery}
                  headLabel={DASHBOARD_USERS_TABLE_HEAD(t)}
                />
                <TableBody>
                  {isLoading || isDeleting ? (
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: 80 }} />
                    ))
                  ) : (
                    <>
                      {data?.admins?.map((row) => (
                        <DashboardUsersRow
                          key={row.id}
                          row={row}
                          deleteHandler={handleDeleteAdmin}
                        />
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {data && (
            <TablePaginationCustom
              count={+data.admins_aggregate.aggregate?.count!}
              page={+table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          )}
        </FormProvider>
      </Card>
    </Container>
  );
};

export default DashboardUsers;
