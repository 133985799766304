import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router';
import {
  SchoolUsersQuery,
  useApproveSchoolUserMutation,
  useRejectSchoolUserMutation,
  useSchoolUserByIdQuery,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { SchoolsRequestFormType } from 'src/types/schools-requests';

const useSchoolRequestDetails = () => {
  // #region States and hooks
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<SchoolsRequestFormType>();
  const router = useRouter();
  const { t, currentLang } = useLocales();
  const { ChangeSchoolRequestSchema } = useSchemas();
  const { state }: { state: SchoolUsersQuery['school_users'][0] | undefined } = useLocation();
  const { schoolRequestId } = useParams();
  const [schoolData, setSchoolData] = useState<SchoolUsersQuery['school_users'][0] | undefined>(
    state!
  );
  const methods = useForm<SchoolsRequestFormType>({
    defaultValues: {
      status: state!?.status || '',
    },
    resolver: yupResolver(ChangeSchoolRequestSchema),
  });
  // #endregion States and hooks

  // #region Services
  // Get users authorization requests
  const { isLoading } = useSchoolUserByIdQuery(
    { schoolUserId: schoolRequestId },
    {
      enabled: !state,
      onSuccess: (data) => {
        methods.reset({
          status: data.school_users_by_pk?.status,
        });
        setSchoolData(data.school_users_by_pk!);
      },
    }
  );

  // Reject request
  const { mutate: reject, isLoading: isRejecting } = useRejectSchoolUserMutation({
    onSuccess: () => {
      router.push(paths.dashboard.userAuthorization.schoolsAccounts);
    },
  });

  // Approve request
  const { mutate: approve, isLoading: isApproving } = useApproveSchoolUserMutation({
    onSuccess: () => {
      router.push(paths.dashboard.userAuthorization.schoolsAccounts);
    },
  });
  // #endregion Services

  // #region Handlers
  const onSubmit = useCallback(
    async (data: SchoolsRequestFormType) => {
      const newData = { ...data };
      setDataToSend(newData);
      confirm.onTrue();
    },
    [confirm]
  );

  const handleReject = () => {
    reject({
      schoolUserId: schoolRequestId!,
    });
  };

  const handleApprove = () => {
    approve({
      schoolUserId: schoolRequestId!,
      userId: schoolData?.user.id!,
    });
  };

  const handleChangeStatus = () => {
    methods.watch().status === 'approved'
      ? handleApprove()
      : methods.watch().status === 'rejected'
      ? handleReject()
      : alert('Change status please');
  };
  // #endregion Handlers

  // Data array
  const SHOW_DATA = [
    {
      label: t('tables.schoolRequests.headLabels.schoolName'),
      value: schoolData!?.schoolName,
    },
    {
      label: t('tables.schoolRequests.headLabels.schoolAddress'),
      value: schoolData!?.schoolAddress,
    },
    {
      label: t('tables.schoolRequests.headLabels.country'),
      value: schoolData?.country,
    },
    {
      label: t('tables.schoolRequests.headLabels.city'),
      value: schoolData?.city,
    },
    {
      label: t('tables.schoolRequests.headLabels.createdAt'),
      value: moment(schoolData!?.createdAt).format('YYYY-MM-DD'),
    },
  ];

  return {
    methods,
    isLoading,
    onSubmit,
    SHOW_DATA,
    schoolData,
    currentLang,
    confirm,
    t,
    handleChangeStatus,
    isRejecting,
    isApproving,
  };
};

export default useSchoolRequestDetails;
