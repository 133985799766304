import { useQueryClient } from '@tanstack/react-query';
import { useTable } from 'src/components/table';
import { Order_By, useDeleteKnowledgeBaseMutation, useKnowledgeBasesQuery } from 'src/graphql';
import UseSearch from 'src/hooks/use-search';
import { useLocales } from 'src/locales';
import { orderBy } from 'src/utils/order-by';

export type RefBooksFormType = {
  matnId: string;
  sortBy: string | undefined;
  sortDirection: Order_By.Asc | Order_By.Desc | undefined;
  bookAuthor?: string;
  bookName?: string;
  createdAt?: string;
};

const useKnowledgeBases = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const queryClient = useQueryClient();
  const { t } = useLocales();

  const { methods, query, handleSearch, setQuery } = UseSearch<{
    sortBy: string;
    sortDirection: Order_By.Asc | Order_By.Desc | undefined;
    status: string | undefined;
    createdAt: {
      operator: '_lt' | '_gt';
      value: string;
    };
  }>();

  // #region Services
  const { data, isLoading } = useKnowledgeBasesQuery({
    offset: table.offset,
    limit: table.rowsPerPage,
    search: query.search ? { _ilike: `%${query.search || ''}%` } : undefined,
    order_by: orderBy(methods.watch().sortBy, methods.watch().sortDirection),
  });

  const { mutate, isLoading: isDeleting } = useDeleteKnowledgeBaseMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['knowledgeBases'] }),
  });
  // #endregion Services

  // #region Handlers
  const handleDeleteKnowledgeBase = (knowledgeBaseId: string) => {
    mutate({ knowledgeBaseId });
  };
  // #endregion Handlers
  return {
    isDeleting,
    methods,
    handleSearch,
    query,
    setQuery,
    isLoading,
    table,
    data,
    handleDeleteKnowledgeBase,
    t,
  };
};

export default useKnowledgeBases;
