import React, { ReactNode } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { IRoleActionsDto } from 'src/types/roles';

interface IAppPermissionGateProps {
  permissionsName: string[];
  children: ReactNode;
}

const AppPermissionGate: React.FC<IAppPermissionGateProps> = (props) => {
  const { permissionsName, children } = props;
  const { user } = useAuthContext();

  const userPermissions: IRoleActionsDto[] = user?.role?.permissions;

  const customUserPermissions: string[] = userPermissions?.map(
    (item) => `${item?.systemCriteria?.replace(/\s/g, '')}.${item?.name}`
  );

  if (
    customUserPermissions &&
    !customUserPermissions.some((element) => permissionsName?.includes(element))
  ) {
    return null;
  }

  return <>{children}</>;
};

export default AppPermissionGate;
