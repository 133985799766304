import React from 'react';
import { TableRow, TableCell, Checkbox } from '@mui/material';
import { AppTablePropsType } from './app-table';
import { TableProps } from './types';

export type Paths<T> = T extends object
  ? T extends Array<any>
    ? never
    : { [K in keyof T]: `${Exclude<K, symbol>}${'' | `.${Paths<T[K]>}`}` }[keyof T]
  : never;

// Define a generic type for the props

export type SelectRowType<TRow> = {
  id: string;
  table: TableProps;
};
interface Props<TRow> {
  columns: AppTablePropsType<TRow>['columns']; // Ensure columns contain keys of TRow
  data: TRow;
  handleSelectRow?: (row: TRow, table: TableProps) => void;
  index: number;
}

// Define the component
const AppTableRow = <TRow,>({ columns, data, handleSelectRow, index }: Props<TRow>) => {
  const columnsView = columns
    .filter((col) => !col?.collapsible)
    .map((column) => {
      if (!column) {
        return <TableCell></TableCell>;
      }
      const PreviewComponent = column.PreviewComponent; // Specify type as React component
      return (
        <TableCell
          key={column.name}
          sx={{ whiteSpace: 'nowrap', textAlign: 'center', ...column.cellSx }}
        >
          {PreviewComponent
            ? PreviewComponent(data, handleSelectRow!, index)
            : nestedData(data, column.name)}
          {/* Access data using the column key */}
        </TableCell>
      );
    });

  const collapsibleColumns = columns
    .filter((col) => col?.collapsible)
    .map((column) => {
      if (!column) {
        return <TableCell></TableCell>;
      }
      const PreviewComponent = column.PreviewComponent; // Specify type as React component
      return PreviewComponent
        ? PreviewComponent(data, handleSelectRow!, index)
        : nestedData(data, column.name);
    });

  return (
    <>
      <TableRow hover>{columnsView}</TableRow>
      {collapsibleColumns}
    </>
  );
};

// Function to access nested data
function nestedData(obj: any, path: string) {
  const keys = path.split('.');
  return keys.reduce((acc, key) => acc && acc[key], obj);
}

export default AppTableRow;
