import React from 'react';
import AddQuestionsProvider from '../context/add-questions-provider';
import AddNewQuestions from '../AddNewQuestions';

const index = () => {
  return (
    <AddQuestionsProvider>
      <AddNewQuestions />
    </AddQuestionsProvider>
  );
};

export default index;
