import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useLocales } from 'src/locales';

function TimeCounter({
  eventTime,
  endTime,
  canStartBefore,
}: {
  eventTime: string;
  endTime: string;
  canStartBefore?: number;
}) {
  const isEventTimeBeforeNow = moment(eventTime).isBefore(moment());
  const isEndTimeAfterNow = moment(endTime).isAfter(moment());
  const { t } = useLocales();
  const momentEvTime = moment(eventTime);
  const momentEvEndTime = moment(endTime);
  const momentEvCanStartTime = moment(eventTime).subtract('minutes', canStartBefore!);

  return (
    <Stack spacing={1}>
      <Typography variant='caption'>
        {!isEventTimeBeforeNow
          ? `${t('sessions.card.startsIn')} ${momentEvTime.format('DD-MM-YYYY [ ] HH:mm')}`
          : `${t('sessions.card.startedSince')} ${momentEvTime.format('DD-MM-YYYY [ ] HH:mm')}`}
      </Typography>
      <Typography variant='caption'>
        {isEndTimeAfterNow
          ? `${t('sessions.card.endsIn')} ${momentEvEndTime.format('DD-MM-YYYY [ ] HH:mm')}`
          : `${t('sessions.card.endedSince')} ${momentEvEndTime.format('DD-MM-YYYY [ ] HH:mm')}`}
      </Typography>
      {!isEventTimeBeforeNow && canStartBefore ? (
        <Typography variant='caption'>
          {t('sessions.card.canStartIn')} {momentEvCanStartTime.format('DD-MM-YYYY [ ] HH:mm')}
        </Typography>
      ) : (
        <></>
      )}
    </Stack>
  );
}

export default TimeCounter;
