import { yupResolver } from '@hookform/resolvers/yup';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Container, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useRejectUserRecordMutation } from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useSchemas from 'src/schemas/hooks/useSchemas';
import {
  RejectRecordDialogPropsType,
  RejectRecordMutationVariablesType,
} from 'src/types/users-records';

const RejectRecordDialog = ({ open, onClose, sound }: RejectRecordDialogPropsType) => {
  if (!open) return <></>;
  // #region States
  const queryClient = useQueryClient();
  const { t } = useLocales();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<RejectRecordMutationVariablesType>();
  const { RejectRecordSchema } = useSchemas();
  // #endregion States

  // #region form
  let defaultValues: RejectRecordMutationVariablesType = {
    adminMessage: sound.adminMessage,
    recordingId: sound.recordingId,
  };

  const methods = useForm<RejectRecordMutationVariablesType>({
    defaultValues,
    resolver: yupResolver(RejectRecordSchema),
  });
  // #endregion form

  // #region Services
  const { mutate: reject, isLoading: isRejecting } = useRejectUserRecordMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['UserRecords'] });
      confirm.onFalse();
      onClose();
    },
  });
  // #endregion Services

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: RejectRecordMutationVariablesType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.rejectRecord.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 0, md: 2 }}>
                <Grid item xs={12}>
                  <Block label={t('dialogs.rejectRecord.adminMessage')}>
                    <RHFTextField name="adminMessage" />
                  </Block>
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    label={t('buttons.reject')}
                    fullWidth
                    onClick={() => onSubmit(methods.watch())}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AppButton
                    onClick={onClose}
                    label={t('buttons.cancel')}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </FormProvider>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: t('buttons.edit').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isRejecting}
              onClick={() => reject(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default RejectRecordDialog;
