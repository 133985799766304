// Make them as label and value object
export const HEARING_COUNCILS_STATUS = (t: any) => [
  {
    label: t('hearingCouncils.status.Pending'),
    value: 'Pending',
  },
  {
    label: t('hearingCouncils.status.Live'),
    value: 'Live',
  },
  {
    label: t('hearingCouncils.status.Expired'),
    value: 'Expired',
  },
]