import { HOST_API } from 'src/config-global';
import { enqueueSnackbar } from 'notistack';

export const fetchData =
  <TData, TVariables>(
    query: string,
    variables?: TVariables,
    options?: RequestInit['headers']
  ): (() => Promise<TData>) =>
  async () => {
    const token = localStorage.getItem('accessToken');
    const isLogin = query.includes('LoginAdmin')

    const headers = {
      'Content-Type': 'application/json',
      // 'x-hasura-admin-secret': 'pretext-stalled-aggie',
      ...(token && !isLogin && { Authorization: `Bearer ${token}` }),
      ...options,
    };

    const res = await fetch(HOST_API, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const showMessageIf = ['insert', 'update', 'delete'];

    const json = await res.json().then((res: any | undefined) => {
      if (res.data) {
        const showSucessMsg = Object.keys(res.data).some((key) =>
          showMessageIf.some((word) => key.includes(word))
        );
        if (showSucessMsg) enqueueSnackbar({ variant: 'success', message: 'Succeeded' });
      }
      return res;
    });

    if (json.errors) {
      const { message } = json.errors[0] || {};

      // Handling all errors
      enqueueSnackbar({ variant: 'error', message });

      // Handling all errors

      // Handle if token is expired
      if (message == 'Could not verify JWT: JWTExpired') {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        window.location.reload();
      }

      throw new Error(message || 'Error…');
    }

    return json.data;
  };
