import { yupResolver } from '@hookform/resolvers/yup';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Tab,
  Tabs,
  alpha,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Block } from 'src/components/Block';
import AppButton from 'src/components/common/AppButton';
import AppLoadingAndErrorWrapper from 'src/components/common/AppLoadingAndErrorWrapper';
import AppLoadingButton from 'src/components/common/AppLoadingButton';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import {
  useAddCategoryMutation,
  useCategoryByIdQuery,
  useUpdateCategoryMutation,
} from 'src/graphql';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import useLangsWithErrors from 'src/routes/hooks/use-langs-with-errors';
import useSchemas from 'src/schemas/hooks/useSchemas';
import { AddCategoryMutationVariables, NewCategoryDialogPropsType } from 'src/types/categories';
import { findParentId, findParentIdString } from 'src/utils/mutoon-categories/find-last-id';

const AddNewCategoryDialog = ({ open, onClose, category }: NewCategoryDialogPropsType) => {
  if (!open) return <></>;
  // #region States
  const { t, allLangs } = useLocales();
  const { '*': splat } = useParams();
  const queryClient = useQueryClient();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddCategoryMutationVariables>();
  const [currentTabValue, setCurrentTabValue] = useState(allLangs[0].value);
  const { categorySchema } = useSchemas();
  const isEditing = !!category;
  // #endregion States

  let defaultValues: AddCategoryMutationVariables = {
    description: category?.description || {},
    name: category?.name || {},
    categoryId: category?.categoryId!,
    parentId: findParentIdString(splat),
  };

  // #region form
  for (let i = 0; i < allLangs.length && !category; i++) {
    defaultValues.description[allLangs[i].value] = '';
    defaultValues.name[allLangs[i].value] = '';
  }

  const methods = useForm<AddCategoryMutationVariables>({
    defaultValues,
    resolver: yupResolver(categorySchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddCategoryMutationVariables) => {
      isEditing && delete data.parentId;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const langsWithErrors = useLangsWithErrors(errors);
  // #endregion form

  // #region Services
  const { mutate: addCategory, isLoading: isAdding } = useAddCategoryMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['MutoonCategories'] });
      onClose();
    },
  });

  const { mutate: editCategory, isLoading: isUpdating } = useUpdateCategoryMutation({
    onSuccess: () => {
      onClose();
      confirm.onFalse();
      queryClient.refetchQueries({ queryKey: ['MutoonCategories'] });
    },
  });

  const mutate: any = isEditing ? editCategory : addCategory;
  // #endregion Services

  // #region handlers
  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTabValue(newValue);
  }, []);
  // #endregion handlers
  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `description.${currentTabValue}`,
      methods.watch().description[currentTabValue] || ''
    );
    methods.setValue(`name.${currentTabValue}`, methods.watch().name[currentTabValue] || '');
  }, [methods, currentTabValue]);
  // #endregion useEffect
  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: '16px', padding: 4 } }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          {t('dialogs.addNewCategory.header')}
        </DialogTitle>
        <DialogContent>
          <Container>
            <AppLoadingAndErrorWrapper
              isLoading={false}
              errorMessage={null}
              sx={{ height: 'auto' }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Tabs
                      value={currentTabValue}
                      onChange={handleChangeTab}
                      sx={{
                        px: 3,
                        boxShadow: (theme) =>
                          `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                      }}
                    >
                      {allLangs!?.map((tab) => (
                        <Tab
                          key={tab.value}
                          value={tab.value}
                          label={tab.label}
                          sx={{
                            color: langsWithErrors.includes(tab.value)
                              ? 'red !important'
                              : 'text.primary',
                          }}
                        />
                      ))}
                    </Tabs>
                    <Stack py={5} spacing={3}>
                      <Block label={t('dialogs.addNewCategory.name')}>
                        <RHFTextField name={`name.${currentTabValue}`} />
                      </Block>
                      <Block label={t('dialogs.addNewCategory.description')}>
                        <RHFTextField name={`description.${currentTabValue}`} />
                      </Block>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppLoadingButton
                      label={isEditing ? t('buttons.edit') : t('buttons.add')}
                      isLoading={false}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AppButton
                      onClick={onClose}
                      label={t('buttons.cancel')}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Container>
        </DialogContent>
      </Dialog>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(event: React.SyntheticEvent<Element, Event>, reason: string) => {
          if (reason && reason === 'backdropClick') {
            confirm.onFalse();
            onClose();
          }
        }}
        content={t('dialogs.confirmMessage', {
          editOrAdd: isEditing ? t('buttons.edit').toLowerCase() : t('buttons.add').toLowerCase(),
        })}
        icon={<BorderColorOutlinedIcon fontSize="large" sx={{ mx: 'auto' }} />}
        action={
          <>
            <AppButton
              fullWidth
              label={t('publicWords.no')}
              variant="outlined"
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label={t('publicWords.yes')}
              isLoading={isAdding || isUpdating}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AddNewCategoryDialog;
