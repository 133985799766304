import { Order_By } from 'src/graphql';

type OrderBy = {
  [key: string]: OrderBy | Order_By.Asc | Order_By.Desc;
};

export const orderBy = (
  sortBy: string | undefined,
  sortDirection: Order_By.Asc | Order_By.Desc | undefined
): OrderBy => {
  if (!sortBy || !sortDirection) return {};

  const parts = sortBy.split('.');

  if (parts.length === 1) {
    return { [sortBy]: sortDirection! };
  } else {
    const [currentPart, ...remainingParts] = parts;
    return {
      [currentPart]: orderBy(remainingParts.join('.'), sortDirection),
    };
  }
};

// Examples
