import { Stack } from '@mui/material';
import CertificatesStatistics from './certificates/view';
import ContactUsView from './contact-us/view';
import FaqsAnalysis from './faqs/view';
import CouncilsStatistics from './hearing-councils/view';
import SessionsStatistics from './sessions/view';
import UsersStatistics from './users/view';
import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';

const Statistics = () => {
  const { t } = useLocales();
  return (
    <Stack spacing={3}>
      <Helmet>
        <title>{t('sidebar.statistics')}</title>
      </Helmet>
      <CouncilsStatistics />
      <SessionsStatistics />
      <UsersStatistics />
      <CertificatesStatistics />
      <FaqsAnalysis />
      <ContactUsView />
    </Stack>
  );
};

export default Statistics;
